import { computed, ComputedRef, isRef, ref, Ref } from 'vue'

export function usePageNumbersToDisplay(currentPage: number | Ref<number>, pageCount: number | Ref<number>, pagesPerSide: number | Ref<number>): { pagesToDisplay: ComputedRef<number[]> } {
	const page: Ref<number> = isRef(currentPage) ? currentPage : ref(currentPage)
	const count: Ref<number> = isRef(pageCount) ? pageCount : ref(pageCount)
	const perSide: Ref<number> = isRef(pagesPerSide) ? pagesPerSide : ref(pagesPerSide)

	const pagesToDisplay = computed(() => {
		if (count.value < perSide.value * 2 + 1) {
			// Less pages than allowed to display
			return [...Array(count.value).keys()].map(i => i + 1)
		}
		else if (page.value <= perSide.value + 1) {
			// Pages doesn't have perSide number of pages to the left
			return [...Array(perSide.value * 2 + 1).keys()].map(i => i + 1)
		}
		else if (page.value >= count.value - perSide.value - 1) {
			// Pages doesn't have perSide number of pages to the right
			return [...Array(perSide.value * 2 + 1).keys()].map(i => i + count.value - (perSide.value * 2))
		}
		else {
			// Current page is somewhere in the middle
			return [...Array(perSide.value * 2 + 1).keys()].map(i => i + page.value - perSide.value)
		}
	})

	return {
		pagesToDisplay
	}
}