<template>
	<div role="heading" aria-level="2" class="v-filter-page-title">{{ pageTitle }}</div>
	<div class="v-filter-page">
		<template v-if="!loaded">
			<div class="loader">
				<div class="circle2"></div>
			</div>
		</template>
		<template v-if="loaded">
			<template v-if="TwoColumn">
				<div class="left-column">
					<div class="filter">
						<slot name="filter"></slot>
					</div>
					<slot name="leftcolumn"></slot>
				</div>
			</template>
			<div :class="['right-column', { single: TwoColumn === false }]">
				<div class="mobile-buttons">
					<slot name="mobile"></slot>
				</div>
				<div class="tags" role="region" aria-label="Applied Filters">
					<slot name="tags"></slot>
				</div>
				<div class="result-view" role="region" aria-label="Search Results">
					<slot></slot>
				</div>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'VFilterPage',
		emits: ['click'],
		props: {
			TwoColumn: {
				type: Boolean,
				required: false,
				default: true
			},
			pageTitle: {
				type: String,
				required: false,
				default: ''
			},
			loaded: {
				type: Boolean,
				required: true
			}
		}
	})
</script>

<style lang="scss">
	.v-filter-page-title {
		display: flex;
		width: 100%;
		font-size: 39.06px;
		line-height: 49px;
		padding: 40px 0 32px 0;
		font-weight: 300;
		color: $Gray100;

		&:empty {
			display: none;
		}

		@include sm {
			margin-left: 10px;
		}
	}

	.margin-top-40 {
		margin-top: 40px;
	}

	.weatherstationlistspage .v-filter-page-title {
		display: none; //to hide the title space in weatherstationlist page.
	}

	.weatherstationlistspage .v-filter-page .left-column .filter {
		margin-right: 0px;
		padding: 10px;
	}

	.weatherstationlistspage .v-filter-page .right-column .tags {
		padding-left: 15px;
	}

	.v-filter-page {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 0px;
		position: static;
		width: 954px;
		left: 0px;
		top: 40px;
		flex: none;
		order: 1;
		flex-grow: 0;
		margin: 0px 0px;
		min-height: 50px;

		@include sm {
			width: 100%;
		}

		.loader {
			position: relative;
			width: 100%;
			min-height: 100px !important;
			height: 100%;
		}

		.left-column {
			display: flex;
			flex-direction: column;
			padding: 0px;
			width: 298px;
			flex-grow: 0;
			flex-shrink: 0;

			@include sm {
				visibility: hidden;
				width: 0;
				height: 0;
				padding: 0;
				margin: 0;
			}

			.filter {
				padding: 16px;
				background: $White;
				border: 1px solid $Gray10;
				margin-right: 30px;
				box-sizing: border-box;
				margin-bottom: 20px;

				.v-modal {
					@include sm {
						position: fixed;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						display: none;
						z-index: 9999;
					}

					&.active {
						display: block;
						visibility: visible;
						background-color: $White;
						padding: 16px;
					}

					.modal-close {
						display: none;

						@include sm {
							display: block;
							position: fixed;
							top: 10px;
							right: 10px;
						}
					}

					.filter-header {
						display: flex;
						flex-direction: row;
						margin-bottom: 16px;
						justify-content: space-between;
						width: 100%;

						@include sm {
							margin-top: 40px;
						}

						.form-header {
							display: flex;
							font-size: 16px;
							line-height: 20px;
							color: $Gray100;
							font-weight: 700;
							flex: none;
						}

						.filter-panel-clearall {
							display: flex;
							line-height: 16px;
							font-size: 12.8px;
							border: 1px solid $PrimaryBrand;
							border-radius: 4px;
							font-weight: 500;
							flex: none;
							padding: 2px 4px;
							box-sizing: border-box;
						}
					}

					.filter-footer {
						margin-top: 16px;
					}

					.filter-group {
						border-top: 1px solid rgb(210, 211, 213);
						margin-top: 16px;
						display: flex;
						flex-direction: column;
						font-size: 14px;
						line-height: 20px;

						&:last-child {
							margin-bottom: 16px;
						}

						p {
							font-size: 14.4px;
							line-height: 22px;
						}

						fieldset {
							display: flex;
							flex-direction: column;

							legend {
								display: none;
							}
						}

						.form-category-header {
							font-weight: 700;
							color: $Gray100;
							font-size: 12.8px;
							margin-top: 10px;
							margin-bottom: 10px;
							line-height: 16PX;
						}
					}

					&.legend {
						padding: 16px;
						margin-right: 30px;
						box-sizing: border-box;
					}
				}
			}
		}

		.right-column {
			display: flex;
			flex-direction: column;
			padding: 0px;
			width: 626px;
			flex-grow: 0;
			flex-shrink: 0;

			@include sm {
				width: 100%;
			}

			&.single {
				width: 100%;
				align-items: flex-start;
				justify-content: center;
				display: flex;

				.result-view {
					display: flex;
					width: 100%;
					justify-content: center;
					align-items: center;
					margin: 0 auto;

					.result-header {
						align-self: flex-start;
						width: 100%;
					}
				}
			}

			.mobile-buttons {
				display: none;

				@include sm {
					display: flex;
					align-items: center;
					flex-flow: column;
					justify-items: center;
				}

				.v-button {
					margin: 8px auto;
				}
			}

			.tags {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				padding: 0px;
				margin-bottom: 16px;
				flex-grow: 1;
				gap: 8px;

				&:empty {
					display: none;
				}

				@include sm {
					margin: 20px 10px;
				}

				.v-tag.tag-list-clearall {
					background-color: rgba($Error, 0.1);
					color: $Error;
					font-weight: 500;
					order: 2;
				}
			}

			.result-view {
				display: flex;
				flex-wrap: wrap;
				padding: 0px;
				flex-direction: column;

				.result-header {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: end;
					flex-grow: 1;
					border-bottom: 1px solid $Gray20;
					padding-bottom: 16px;
					margin-bottom: 8px;

					@include sm {
						width: calc(100% - 20px);
						margin: 8px 10px;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
					}

					.result-count {
						display: flex;
						font-size: 25px;
						line-height: 31px;
					}

					.result-sort {
						display: flex;
						align-self: end;
						margin-left: auto;
						align-items: center;
						justify-items: space-between;

						@include sm {
							margin: 10px 0 0 0;
							align-self: flex-start;
						}

						span {
							flex: none;
							display: flex;
							flex-grow: 1;
							margin-right: 8px;
						}

						.sort-button {
							display: flex;
							flex: none;
							flex-grow: 0;
							border-radius: 4px;
							background-color: $White;
							border: 1px solid $PrimaryBrand;
							color: $PrimaryBrand;
							cursor: pointer;
							height: 34px;
							font-size: 12px;
							line-height: 20px;
							padding: 6px 12px;
							justify-content: center;
							margin-left: 4px;
						}
					}
				}
			}
		}
	}

	.v-checkbox {
		display: flex;
		flex-direction: row;

		input[type="checkbox"] {
			position: absolute;
			opacity: 0;

			+ .v-checkbox-label {
				color: $Gray100;
				fill: $PrimaryBrand;
				font-size: 14.4px;
				line-height: 22px;
				font-weight: normal;

				&:before {
					content: '';
					background: $White;
					border-radius: 4px;
					border: 1px solid $Gray20;
					display: inline-block;
					width: 22px;
					height: 22px;
					position: static;
					left: 0px;
					top: 2px;
					vertical-align: top;
					cursor: pointer;
					text-align: center;
					transition: all 250ms ease;
					margin-right: 0.5em;
				}

				&:empty {
					&:before {
						margin-right: 0;
					}
				}
			}

			&:checked {
				+ .v-checkbox-label {
					&:before {
						background-color: $PrimaryBrand;
						background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.75078 12.1502L3.60078 9.0002L2.55078 10.0502L6.75078 14.2502L15.7508 5.2502L14.7008 4.2002L6.75078 12.1502Z' fill='white' /%3E%3C/svg%3E");
						background-repeat: no-repeat;
						background-position: center;
						fill: $White;
						box-shadow: inset 0 0 0 0 $White;
						color: white;
					}
				}
			}

			&:focus {
				+ .v-checkbox-label {
					&:before {
						outline: none;
						border-color: $PrimaryBrand;
					}
				}
			}

			&:disabled {
				+ .v-checkbox-label {
					color: #A5A8AB;

					&:before {
						box-shadow: inset 0 0 0 4px $Gray20;
						border-color: $Gray20;
						background: $Gray20;
						cursor: default;
					}
				}
			}
		}
	}
</style>
