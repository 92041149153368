<template>
	<nav :class="{ 'v-tabs': displayStyle === 'tabs' }" role="navigation">
		<slot></slot>
	</nav>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'VButtonTabs',
		props: {
			displayStyle: {
				type: String,
				required: false,
				default: 'tabs',
				validator: (value: string) => {
					return ['buttons', 'tabs'].includes(value)
				}
			}
		}
	})
</script>

<style lang="scss">
	.v-tabs {
		border-bottom: 1px solid $Gray10;

		@include sm {
			border: none;
			display: flex;
			justify-content: space-around;
			flex-flow: row wrap;
			margin: 0 10px;
		}

		.v-button {
			border-bottom-width: 0px;
			margin-bottom: -1px;

			@include sm {
				margin: 5px;
				border: none;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 4px 8px;
				border-radius: 4px;
				flex-grow: 0;
				font-size: 12.8px;
				line-height: 16px;
				text-align: center;
				height: 26px;
				background-color: $White;
				color: $PrimaryBrand;
				font-weight: bold;
			}

			&.active {
				background-color: $Gray5;
				color: $PrimaryBrand;
				fill: $PrimaryBrand;
				border-bottom-width: 0;
				border-top: 1px solid $Gray10;

				&::before, &::after {
					border-color: $Gray10;

					@include sm {
						display: none;
					}
				}

				@include sm {
					border: none;
					color: $Gray100;
					background-color: $Gray20;
					display: inline-flex;
				}
			}

			&::before, &::after {
				@include sm {
					display: none;
				}
			}

			&.v-button-primary {
				@include sm {
					display: inline-flex;
				}
				&:before {
					border-bottom: 0px;
					border-bottom-left-radius: 0;
				}

				&:after {
					border-bottom: 0px;
					border-bottom-right-radius: 0;
				}
			}

			&:not(.active):hover:before, &:not(.active):hover:after {
				@include lg {
					border-width: 2px;
					border-color: $PrimaryBrand;
					top: -2px;
					bottom: -2px;
					border-bottom-width: 2px;
				}
			}
		}

		.v-separator {
			display: inline-block;
			margin: 0 10px 0 5px;
			font-weight: 700;
		}
	}
</style>
