<template>
	<svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M12.8741 2H11.7267V2.31936H8.15678V3.51062H11.7267V4.08485C11.2102 4.22687 10.7639 4.55825 10.6022 5.07309C10.3968 5.72668 10.1934 6.51603 10.0516 7.46814L3.71965 8.23882C2.76478 8.2547 1.99447 8.68233 2.00003 9.19272L2.02588 11.6084C2.03144 12.118 2.80994 12.5188 3.76546 12.5017L9.96121 11.9626L10.6708 17.1582L8.66397 17.4826C8.24173 17.4911 7.90227 17.7463 7.9059 18.0515L7.91824 19.4977C7.92078 19.8039 8.26605 20.0459 8.68684 20.0363L11.0274 19.7694L11.0767 20.1301C11.1709 20.8195 11.7597 21.3333 12.4554 21.3333H12.4773H12.5002C13.1921 21.3333 13.7732 20.8127 13.8488 20.1249L13.8953 19.7019L16.3536 19.8837C16.7758 19.8747 17.1156 19.6205 17.1131 19.3143L17.0982 17.8665C17.0964 17.5613 16.7515 17.3183 16.3296 17.3284L14.1847 17.0692L14.7566 11.8673L21.1377 12.1978C22.0933 12.1807 22.8626 11.7539 22.8573 11.2435L22.8315 8.8278C22.8249 8.31741 22.0481 7.91737 21.0926 7.93451L14.5377 7.36916C14.3694 6.42359 14.1446 5.64189 13.9223 4.99646C13.7599 4.52464 13.3498 4.21719 12.8741 4.08249V3.51062H16.4441V2.31936H12.8741V2Z" fill="#1D252D" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'AircraftIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
