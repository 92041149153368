<template>
	<svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M13.4142 1.41422C12.6332 0.633168 11.3668 0.633168 10.5858 1.41422L1.41422 10.5858C0.633168 11.3668 0.633168 12.6332 1.41422 13.4142L10.5858 22.5858C11.3668 23.3668 12.6332 23.3668 13.4142 22.5858L22.5858 13.4142C23.3668 12.6332 23.3668 11.3668 22.5858 10.5858L13.4142 1.41422ZM7.05921 16.3259C12.9962 15.0547 17.0897 14.1782 5.32448 12.7205C0.914755 12.1742 1.5 9 19 8.5L20 9.5C18.1944 9.711 16.5945 9.87892 15.2407 10.021C8.76709 10.7005 7.9186 10.7896 17.0928 12.1742C23.5625 13.1506 15.448 18.1058 9.00001 19.5L6.03166 16.5466C6.37842 16.4716 6.72179 16.3981 7.05921 16.3259Z" fill="#1D252D" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'RoadRestrictonIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
