import { inject, reactive, Ref, toRefs, onBeforeUnmount } from 'vue'
import { useStorage } from 'vue-composable'
import { Api, CameraListModel } from '@/api/travelServiceApi'
import { ICameraList } from '@/interfaces/ICameraList'

const state = reactive({
	cameras: [],
	loaded: false
} as ICameraList)

const internalState = {
	lastUpdated: null as Date | null,
	promise: null as Promise<void> | null,
	polling: undefined as number | undefined
}

export default function useCameras(): {
	cameras: Ref<CameraListModel[]>
	loaded: Ref<boolean>
	fetchCameras: () => Promise<void>
} {
	const MAX_CACHE_MIN = 10
	const TRAFFIC_API = inject('$TRAFFIC_API') as string
	const travelService = new Api({ baseUrl: TRAFFIC_API })
	const key = '__wsdot_cameras'
	const { supported, storage, remove } = useStorage(key, { lastUpdated: new Date(), version: import.meta.env.VITE_BUILD_VERSION, cameras: [] as CameraListModel[] }, true)

	if (supported && storage.value.lastUpdated === null) {
		remove()
		storage.value.version = import.meta.env.VITE_BUILD_VERSION
		storage.value.cameras = []
		storage.value.lastUpdated = new Date()
	}

	// Poll for changes every 10 minutes
	if (!internalState.polling) {
		internalState.polling = window.setInterval(() => {
			checkForUpdates()
		}, 60000 * MAX_CACHE_MIN)
	}

	const setFromStorage = (): boolean => {
		if (supported) {
			if (storage.value.version === import.meta.env.VITE_BUILD_VERSION) {
				if (
					storage.value.cameras.length > 0
					&& Math.abs(new Date().getTime() - new Date(storage.value.lastUpdated).getTime()) / 60000 <= MAX_CACHE_MIN
				) {
					state.cameras = storage.value.cameras
					internalState.lastUpdated = storage.value.lastUpdated
					return true
				}
			}
			remove()
		}
		return false
	}

	const fetchCameras = async (force = false) => {
		if (internalState.promise) {
			return internalState.promise
		}
		if (force || !setFromStorage()) {
			state.loaded = false
			internalState.promise = travelService.api.cameraList().then((response) => {
				state.cameras = response.data
				internalState.lastUpdated = new Date()
				if (supported) {
					storage.value.version = import.meta.env.VITE_BUILD_VERSION
					storage.value.cameras = state.cameras
					storage.value.lastUpdated = internalState.lastUpdated
				}
				state.loaded = true
			})
			.finally(() => {
				internalState.promise = null
			})
			return internalState.promise
		}
	}

	const checkForUpdates = async () => {
		const lastUpdateResp = await travelService.api.cameraGetLastUpdateList()
		const newDate = new Date(lastUpdateResp.data)
		if (!internalState.lastUpdated || internalState.lastUpdated < newDate) {
			refreshCameras()
		}
	}

	const refreshCameras = async () => {
		fetchCameras(true)
	}

	if (state.cameras.length === 0) {
		fetchCameras()
	}

	onBeforeUnmount(() => {
		if (internalState.polling) {
			clearInterval(internalState.polling)
		}
	})

	return {
		...toRefs(state),
		fetchCameras
	}
}