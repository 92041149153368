<template>
	<base-button @keyup.enter="onClick" @click="onClick" :aria-label="buttonLabel">
		<span>{{ buttonLabel }}</span>
	</base-button>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'
	import BaseButton from '@/components/UI/BaseButton.vue'

	export default defineComponent({
		name: 'VButton',
		components: {
			BaseButton
		},
		props: {
			buttonLabel: {
				type: String,
				required: true
			}
		},
		emits: ['click'],
		setup(props, { emit }) {
			const onClick = () => {
				emit('click')
			}

			return {
				onClick
			}
		}
	})
</script>

<style lang="scss">
</style>
