export interface ILocationData {
	name: string,
	startMp: number,
	endMp: number | null,
	direction: string,
	minValue: number,
	maxValue: number
}

export const useLocation = (name: string, startMp: number, endMp: number | null, direction: string): ILocationData => {
	const state = {
		name: name,
		startMp: startMp,
		endMp: endMp,
		direction: direction,
		minValue: (endMp ? (startMp > endMp ? endMp : startMp) : startMp),
		maxValue: (endMp ? (startMp > endMp ? startMp : endMp) : startMp)
	} as ILocationData

	return {
		...state
	}
}