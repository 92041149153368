<template>
	<svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M12.2157 18.312H12.2397H12.2637C12.5997 18.312 12.9117 18.12 13.1037 17.832C14.1357 16.032 17.6637 9.69602 17.6637 7.08002C17.6637 3.98402 15.3117 1.84802 12.4317 1.84802C12.3597 1.84802 12.3117 1.84802 12.2397 1.84802C12.1677 1.84802 12.1197 1.84802 12.0477 1.84802C9.16767 1.84802 6.81567 3.98402 6.81567 7.08002C6.81567 9.69602 10.3437 16.032 11.3757 17.832C11.5437 18.12 11.8557 18.312 12.2157 18.312ZM9.71967 7.17602C9.71967 5.78402 10.8477 4.65602 12.2397 4.65602C13.6317 4.65602 14.7597 5.78402 14.7597 7.17602C14.7597 8.56802 13.6317 9.69602 12.2397 9.69602C10.8477 9.69602 9.71967 8.56802 9.71967 7.17602Z" />
		<path d="M21.4557 21.768L19.4397 14.328C19.3677 14.064 19.1277 13.896 18.8637 13.896H15.7677C15.5757 14.28 15.3837 14.688 15.1677 15.096H18.4317L20.1117 21.336H4.39171L6.07171 15.096H9.33571C9.11971 14.688 8.90371 14.28 8.73571 13.896H5.61571C5.35171 13.896 5.11171 14.088 5.03971 14.328L3.02371 21.768C2.97571 21.96 3.02371 22.152 3.11971 22.296C3.23971 22.44 3.40771 22.536 3.59971 22.536H20.8797C21.0717 22.536 21.2397 22.44 21.3597 22.296C21.4797 22.152 21.5037 21.96 21.4557 21.768Z" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'MapIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
