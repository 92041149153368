<template>
	<div class="main-container container">
		<div class="full-width" style="margin-bottom: 23px;">
			<section>
				<a id="main-content"></a>
				<div id="pagesummary" class="region region-content" v-if="ShowSummary" style="margin-bottom: 23px;">
					<h1 class="page-header"><span property="schema:name">Real-time travel data</span></h1>
					<p>
						View current travel conditions on an interactive map or search by route to get a list of travel alerts, cameras, truck restrictions and weather.
					</p>
					<v-icon-button @click="navigateToMap" class="v-button-primary v-button-large" iconName="map" buttonLabel="View on map" tabindex="0" :iconWidth="24" :iconHeight="24" />
				</div>
				<div :class="{ 'container-border-bottom': ShowSummary }">
					<div role="search" class="main-container container">
						<div class="full-width">
							<div class="routepicker">
								<template v-if="!searchActive">
									<div class="form-header" role="heading" aria-level="2">Select a road or ferry route</div>
									<div class="routepicker-body">
										<div class="routepicker-body-group routepicker-body-group-full">
											<label for="vehicleroute" class="label-h3" role="heading" aria-level="3">Road</label>
											<select v-model="vehicleRoute" class="form-control" id="vehicleroute" :disabled="!!ferryRoute">
												<option v-for="(route, index) in stateRoutes" :key="index" :value="route.stateRouteId">{{ route.srCommonName }}</option>
											</select>
										</div>
										<div class="routepicker-body-group">
											<label for="startLocation">Between</label>
											<select v-model="startLocation" id="startLocation" class="form-control" :disabled="!vehicleRoute">
												<option v-for="(city, index) in startCities" :key="index" :value="city">{{ city.name }}</option>
											</select>
										</div>
										<div class="routepicker-body-group">
											<label for="endLocation">And</label>
											<select v-model="endLocation" id="endLocation" class="form-control" :disabled="!vehicleRoute || !startLocation">
												<option v-for="(city, index) in endCities" :key="index" :value="city">{{ city.name }}</option>
											</select>
										</div>
									</div>
									<div class="routepicker-body">
										<div class="routepicker-body-group">
											<label for="ferryRoute" class="label-h3" role="heading" aria-level="3">Ferry route</label>
											<select v-model="ferryRoute" id="ferryRoute" class="form-control" :disabled="!!vehicleRoute">
												<option v-for="(route, index) in ferryRoutes" :key="index" :value="route.routeId">{{ route.routeName }}</option>
											</select>
										</div>
									</div>
									<div v-if="!searchActive" class="routepicker-body">
										<div class="routepicker-body-group routepicker-body-group-full">
											<v-button @click="onSearchClick" class="v-button-primary v-button-large" buttonLabel="Search" tabindex="0" />
											<a class="form-clear" @keyup.enter="onClearClick" @click="onClearClick" role="button" tabindex="0">Clear</a>
										</div>
									</div>
								</template>
								<template v-else>
									<div style="margin-bottom: 14px;" role="heading" aria-level="1">
										<div class="search-text" v-if="locationNameHeader">{{ locationNameHeader }}</div>
										<div class="search-text-route">{{ pageHeaderSimple }}</div>
									</div>
									<div class="routepicker-body-group">
										<v-button @click="onEditClick" class="v-button-primary v-button-large" buttonLabel="Edit" tabindex="0" />
										<a class="form-clear" @keyup.enter="onClearClick" @click="onClearClick" role="button" tabindex="0">Clear</a>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<template v-if="ShowSummary">
		<div class="gray-background">
			<div class="main-container container" aria-live="polite">
				<div class="full-width">
					<div class="content-width" role="region" aria-label="Search Result Summary">
						<div v-if="stateWideAlerts.length > 0" class="emergency-alerts">
							<emergency-alert v-for="alert in stateWideAlerts" :key="alert.alertId" :travelAlert="alert" />
						</div>
						<route-summary-card :loaded="alerts.loaded.value" :count="alerts.baseAlertCount.value" @click="showAlerts" resultType="alerts" description="Traffic Management Centers and Washington State Ferries are reporting on road and bridge closures, collisions, construction, reduced ferry service, low tides, and anything else that goes down."></route-summary-card>
						<route-summary-card :loaded="cameras.loaded.value" :count="cameras.baseCameraCount.value" @click="showCameras" resultType="cameras" description="We operate still-image cameras on roads throughout the state to monitor traffic and weather conditions."></route-summary-card>
						<route-summary-card :loaded="restrictions.loaded.value" :count="restrictions.baseRestrictionCount.value" @click="showRestrictions" resultType="truck restrictions" description="Special size, weight and cargo limitations for commercial vehicles on roads, bridges and in tunnels."></route-summary-card>
						<route-summary-card :loaded="reports.loaded.value" :count="reports.baseReportsCount.value" @click="showPassReports" resultType="mountain pass reports" description="Current reports on weather and driving conditions so you can travel safely through Washington's many beautiful mountains."></route-summary-card>
						<div class="section">
							<router-link to="/weather" class="section-link">Weather stations</router-link>
							<span>Our weather stations provide current and forecast weather conditions at strategic points along the road.</span>
						</div>
						<div class="section">
							<router-link to="/travel-times" class="section-link">Travel times</router-link>
							<span>Prepare for your travels with travel times on specific routes in Washington.</span>
						</div>
						<div class="section">
							<router-link to="/border-crossings" class="section-link">Border crossing wait times</router-link>
							<span>Prepare for your travels with border crossing times on specific routes in Washington.</span>
						</div>
						<div class="pullquote">
							<span>Traffic detectors called induction loops are low-voltage coils buried in the roadway used to operate traffic signals, ramp meters, travel time signs and send data from the roadside to the Traffic Management Centers. This data provides traffic conditions to the website.</span>
						</div>
						<div class="line">
						</div>
						<h3 class="section-heading">Related travel information</h3>
						<div class="section-link-container"><a href="https://wsdot.wa.gov/travel/roads-bridges/rest-areas" class="section-link">Rest areas</a></div>
						<div class="section-link-container"><a href="https://wsdot.wa.gov/travel/roads-bridges/park-and-rides" class="section-link">Park &amp; rides</a></div>
						<div class="section-link-container"><a href="https://wsdot.wa.gov/travel/roads-bridges/toll-roads-bridges-tunnels" class="section-link">Toll roads, bridges &amp; tunnels</a></div>
						<div class="section-link-container"><a href="https://wsdot.wa.gov/travel/holiday-weekend-travel" class="section-link">Best times to travel</a></div>
						<div class="section-link-container"><a href="https://wsdot.wa.gov/traffic/api/" class="section-link">Traveler Information API</a></div>
					</div>
				</div>
			</div>
		</div>
	</template>
	<template v-if="!ShowSummary">
		<div class="tab-container" aria-live="polite" role="region" aria-label="Search Result Details">
			<v-button-tabs displayStyle="tabs">
				<div class="full-width">
					<div role="tablist" aria-orientation="horizontal" aria-label="Filterable list of alerts, cameras, truck restrictions, and mountain pass reports">
						<v-button id="TravelAlerts"
							:class="['v-button-primary', 'tab-bold', { active: currentTab === 'TravelAlerts' }]"
							:buttonLabel="alerts.filteredAlertsCount.value === 1 ? `${alerts.filteredAlertsCount.value} Alert` : `${alerts.filteredAlertsCount.value.toLocaleString()} Alerts`"
							@keydown.end="activateTab('PassReports')"
							@keyup.right="activateTab('Cameras')"
							@keyup.enter="activateTab('TravelAlerts')"
							@click="activateTab('TravelAlerts')"
							:aria-selected="currentTab === 'TravelAlerts'"
							role="tab"
							tabindex="0" />
						<v-button id="Cameras"
							:class="['v-button-primary', 'tab-bold', { active: currentTab === 'Cameras' }]"
							:buttonLabel="cameras.filteredCamerasCount.value === 1 ? `${cameras.filteredCamerasCount.value} Camera` : `${cameras.filteredCamerasCount.value.toLocaleString()} Cameras`"
							@keydown.home="activateTab('TravelAlerts')"
							@keydown.end="activateTab('PassReports')"
							@keyup.left="activateTab('TravelAlerts')"
							@keyup.right="activateTab('TruckRestrictions')"
							@keyup.enter="activateTab('Cameras')"
							@click="activateTab('Cameras')"
							:aria-selected="currentTab === 'Cameras'"
							role="tab"
							tabindex="0" />
						<v-button id="TruckRestrictions"
							:class="['v-button-primary', 'tab-bold', { active: currentTab === 'TruckRestrictions' }]"
							:buttonLabel="restrictions.filteredRestrictionsCount.value === 1 ? `${restrictions.filteredRestrictionsCount.value} Truck restriction` : `${restrictions.filteredRestrictionsCount.value.toLocaleString()} Truck restrictions`"
							@keydown.home="activateTab('TravelAlerts')"
							@keydown.end="activateTab('PassReports')"
							@keyup.left="activateTab('Cameras')"
							@keyup.right="activateTab('PassReports')"
							@keyup.enter="activateTab('TruckRestrictions')"
							@click="activateTab('TruckRestrictions')"
							:aria-selected="currentTab === 'TruckRestrictions'"
							role="tab"
							tabindex="0" />
						<v-button id="PassReports"
							:class="['v-button-primary', 'tab-bold', { active: currentTab === 'PassReports' }]"
							:buttonLabel="reports.filteredReportsCount.value === 1 ? `${reports.filteredReportsCount.value} Mountain pass report` : `${reports.filteredReportsCount.value.toLocaleString()} Mountain pass reports`"
							@keydown.home="activateTab('TravelAlerts')"
							@keyup.left="activateTab('TruckRestrictions')"
							@keyup.enter="activateTab('PassReports')"
							@click="activateTab('PassReports')"
							:aria-selected="currentTab === 'PassReports'"
							role="tab"
							tabindex="0" />
					</div>
				</div>
			</v-button-tabs>
			<div class="gray-background">
				<div class="full-width">
					<div role="tabpanel" tabindex="0" :class="[{ hidden: !alerts.loaded.value || !restrictions.loaded.value || !reports.loaded.value || !cameras.loaded.value }]">
						<keep-alive>
							<component :is="currentTab" :loaded="currentTabReady" />
						</keep-alive>
					</div>
					<div :class="['circle2', {hidden: !!alerts.loaded.value && !!restrictions.loaded.value && !!reports.loaded.value && !!cameras.loaded.value}]"></div>
				</div>
			</div>
		</div>
	</template>
</template>

<script lang="ts">
	import { defineComponent, ref, onMounted, inject, watch, computed } from 'vue'
	import { useRoute, useRouter } from 'vue-router'

	import RouteSummaryCard from '@/components/RouteSummaryCard.vue'
	import Cameras from '@/components/Cameras/Cameras.vue'
	import TruckRestrictions from '@/components/TruckRestrictions/TruckRestrictions.vue'
	import TravelAlerts from '@/components/TravelAlerts/TravelAlerts.vue'
	import EmergencyAlert from '@/components/TravelAlerts/EmergencyAlert.vue'
	import PassReports from '@/components/MountainPasses/PassReports.vue'

	import VButton from '@/components/UI/VButton.vue'
	import VButtonTabs from '@/components/UI/VButtonTabs.vue'
	import VIconButton from '@/components/UI/VIconButton.vue'
	import { useAlertFilters } from '@/composables/alertFilters'
	import { useCameraFilters } from '@/composables/cameraFilters'
	import { useRestrictionFilter } from '@/composables/restrictionFilters'
	import { usePassReportFilters } from '@/composables/passReportFilters'
	import { useNavigation } from '@/composables/navigation'
	import { useRoutePicker } from '@/store/routePicker'

	import useFerryRoutes from '@/store/ferryRoutes'
	import useStateRoutes from '@/store/stateRoutes'
	import useTravelAlerts from '@/store/travelAlerts'

	export default defineComponent({
		name: 'Index',
		components: {
			VButtonTabs,
			VButton,
			VIconButton,
			RouteSummaryCard,
			Cameras,
			TravelAlerts,
			TruckRestrictions,
			PassReports,
			EmergencyAlert,
		},
		setup() {
			const route = useRoute()
			const router = useRouter()

			const MAP_URL = inject('$MAP_URL') as string

			const { documentTitle, pageTitle, breadcrumbs, description, keywords } = useNavigation()
			const { stateRoutes, fetchStateRoutes } = useStateRoutes()
			const { ferryRoutes, fetchFerryRoutes } = useFerryRoutes()

			const routePicker = useRoutePicker()
			const cameras = useCameraFilters()
			const alerts = useAlertFilters()
			const { alerts: statewideAlerts } = useTravelAlerts()
			const restrictions = useRestrictionFilter()
			const reports = usePassReportFilters()
			const currentTab = ref('TravelAlerts')
			const ShowSummary = ref(true)

			// Spinner
			const currentTabReady = computed(() => {
				switch (currentTab.value) {
					case 'TravelAlerts':
						return alerts.loaded.value
					case 'Cameras':
						return cameras.loaded.value
					case 'TruckRestrictions':
						return restrictions.loaded.value
					case 'PassReports':
						return reports.loaded.value
				}
			})

			// RoutePicker items
			const selectedRouteName = computed(() => {
				if (routePicker.routeType.value === 'road' && routePicker.vehicleRoute.value) {
					let srId = routePicker.vehicleRoute.value
					if (srId !== '') {
						return stateRoutes?.value?.find(item => item.stateRouteId === srId)?.srCommonName
					}
				}
				return ''
			})

			const locationNameHeader = computed((): string => {
				if (routePicker.searchActive) {
					if (routePicker.routeType.value === 'road') {
						if (routePicker.vehicleRoute.value) {
							return 'Viewing results by road for '
						} else {
							return 'Viewing statewide results by road'
						}
					}
					if (routePicker.routeType.value === 'ferry') {
						if (routePicker.ferryRoute.value) {
							return 'Viewing results for '
						} else {
							return 'Viewing statewide results for ferries'
						}
					}
				}
				return ''
			})

			const pageTitleSimple = (): string => {
				if (routePicker.searchActive.value) {
					if (routePicker.routeType.value === 'road') {
						let ret = selectedRouteName.value ?? ''
						if (routePicker.cities.value && routePicker.cities.value.length > 1) {
							if (routePicker.startLocation.value) {
								ret += ` between ${routePicker.startLocation.value?.name}`
							} else {
								let lowCity = routePicker.cities.value?.sort((a, b) => {
									return a.startMp > b.startMp ? 1 : -1
								})[0]
								ret += ` between ${lowCity?.name}`
							}
							if (routePicker.endLocation.value) {
								ret += ` and ${routePicker.endLocation.value?.name}`
							} else {
								let highCity = routePicker.cities.value?.sort((a, b) => {
									return a.startMp > b.startMp ? -1 : 1
								})[0]
								ret += ` and ${highCity?.name}`
							}
						} else if (routePicker.cities.value && routePicker.cities.value.length === 1) {
							if (routePicker.startLocation.value) {
								ret += ` in ${routePicker.startLocation.value.name}`
							} else {
								ret += ` in ${routePicker.cities.value[0].name}`
							}
						}
						return ret
					} else if (routePicker.routeType.value === 'ferry') {
						const ferryRoute = ferryRoutes.value.find(r => r.routeId === routePicker.ferryRoute.value)
						return ferryRoute?.routeName ?? ''
					}
				}

					return ''
			}

			const pageHeaderSimple = computed(() => {
				const simple = pageTitleSimple()
				if (simple) {
					return simple
				}
				return 'Statewide results'
			})

			const pageTitleViewing = (): string => {
				const simple = pageTitleSimple()
				if (simple) {
					if (routePicker.routeType.value) {
						if (routePicker.routeType.value === 'road') {
							return `Viewing results by road for ${simple}`
						} else if (routePicker.routeType.value === 'ferry') {
							return `Viewing results for Ferry route ${simple}`
						}
					}
				} else {
					if (routePicker.routeType.value) {
						if (routePicker.routeType.value === 'road') {
							return 'Viewing statewide results by road'
						} else if (routePicker.routeType.value === 'ferry') {
							return 'Viewing statewide results for ferries'
						}
					}
				}
				return 'Viewing statewide results'
			}
	
			const onSearchClick = (tabname = 'alerts') => {
				routePicker.searchActive.value = true
				ShowSummary.value = false
				// Send to route
				if (routePicker.vehicleRoute.value) {
					routePicker.routeType.value = 'road'
					if (routePicker.startLocation.value) {
						if (routePicker.endLocation.value) {
							router.push({
								name: 'searchresults',
								params: {
									tabname: tabname,
									routeType: 'road',
									routeId: routePicker.vehicleRoute.value,
									startCity: routePicker.startLocation.value.name,
									endCity: routePicker.endLocation.value.name
								}
							})
						} else {
							router.push({
								name: 'searchresults',
								params: {
									tabname: tabname,
									routeType: 'road',
									routeId: routePicker.vehicleRoute.value,
									startCity: routePicker.startLocation.value.name
								}
							})
						}
					} else {
						router.push({
							name: 'searchresults',
							params: {
								tabname: tabname,
								routeType: 'road',
								routeId: routePicker.vehicleRoute.value
							}
						})
					}
				} else if (routePicker.ferryRoute.value) {
					routePicker.routeType.value = 'ferry'
					router.push({
						name: 'searchresults',
						params: {
							tabname: tabname,
							routeType: 'ferry',
							routeId: routePicker.ferryRoute.value
						}
					})
				} else {
					router.push({
						name: 'searchresults',
						params: {
							tabname: tabname
						}
					})
				}

				updateResults()
			}

			const onClearClick = () => {
				ShowSummary.value = true
				resetRoutepicker()
				resetFilters()
				router.push({ name: 'index' })
			}

			const resetFilters = () => {
				alerts.resetFilters()
				cameras.resetFilters()
				restrictions.resetFilters()
				reports.resetFilters()
			}

			const updateResults = async () => {
				return Promise.all([alerts.fetchFilteredAlerts(), cameras.fetchFilteredCameras(), restrictions.fetchFilteredRestrictions(), reports.fetchFilteredReports()])
			}

			const onEditClick = () => {
				routePicker.searchActive.value = false
			}

			const onMapClick = () => {
				window.location.href = (MAP_URL)
			}

			const setTitle = () => {
				const defaultRoute = { title: 'Real-time travel data', routeName: 'index' }
				if (route.params.tabname) {
					const simple = pageTitleSimple()
					const viewing = pageTitleViewing()
					if (route.params.tabname === 'mountain-pass-reports') {
						description.value = 'View mountain pass reports and cameras in a list.'
						keywords.value = ''
					}
					if (simple) {
						documentTitle.value = simple
						pageTitle.value = simple
						if (breadcrumbs.value.length > 0 && breadcrumbs.value[breadcrumbs.value.length - 1].routeName !== defaultRoute.routeName) {
							breadcrumbs.value.push(defaultRoute)
						}
					} else {
						if (route.params.routeType) {
							if ((route.params.routeType as string).toLowerCase() === 'road') {
								documentTitle.value = viewing
								pageTitle.value = 'Statewide results by road'
								if (breadcrumbs.value.length > 0 && breadcrumbs.value[breadcrumbs.value.length - 1].routeName !== defaultRoute.routeName) {
									breadcrumbs.value.push(defaultRoute)
								}
							} else if ((route.params.routeType as string).toLowerCase() === 'ferries') {
								documentTitle.value = pageTitleSimple()
								pageTitle.value = 'Statewide results for ferries'
								if (breadcrumbs.value.length > 0 && breadcrumbs.value[breadcrumbs.value.length - 1].routeName !== defaultRoute.routeName) {
									breadcrumbs.value.push(defaultRoute)
								}
							}
						}
						else {
							documentTitle.value = viewing
							pageTitle.value = 'Statewide results'
							if (breadcrumbs.value.length > 0 && breadcrumbs.value[breadcrumbs.value.length - 1].routeName !== defaultRoute.routeName) {
								breadcrumbs.value.push(defaultRoute)
							}
						}
					}
				}
			}

			const resetRoutepicker = () => {
				routePicker.searchActive.value = false
				routePicker.vehicleRoute.value = null
				routePicker.routeType.value = null
				routePicker.ferryRoute.value = null
				routePicker.startLocation.value = null
				routePicker.endLocation.value = null
			}

			const parseRouteParams = (init: boolean = false) => {
				// ':tabname/:routeType?/:routeId?/:startCity?/:endCity?'

				resetRoutepicker()

				if (route.params.tabname) {
					routePicker.searchActive.value = true
					if (route.params.routeType) {
						if ((route.params.routeType as string).toLowerCase() === 'road') {
							routePicker.routeType.value = 'road'
							if (route.params.routeId) {
								if (stateRoutes.value.find((obj) => obj.stateRouteId === route.params.routeId)) {
									routePicker.vehicleRoute.value = route.params.routeId as string
								} else {
									router.push({ name: 'searchresults', params: { tabname: 'alerts', routeType: 'road' } })
									return
								}

								if (route.params.startCity) {
									const startCity = routePicker.cities.value.find((obj) => obj.name.toLowerCase() === (route.params.startCity as string).toLowerCase())
									if (startCity) {
										routePicker.startLocation.value = startCity
									} else {
										router.push({ name: 'searchresults', params: { tabname: 'alerts', routeType: 'road', routeId: route.params.routeId } })
										return
									}
									if (route.params.endCity) {
										const endCity = routePicker.cities.value.find((obj) => obj.name.toLowerCase() === (route.params.endCity as string).toLowerCase())
										if (endCity) {
											routePicker.endLocation.value = endCity
										} else {
											router.push({ name: 'searchresults', params: { tabname: 'alerts', routeType: 'road', startCity: startCity.name } })
											return
										}
									}
								}
							}
						} else if ((route.params.routeType as string).toLowerCase() === 'ferry') {
							routePicker.routeType.value = 'ferry'
							if (route.params.routeId) {
								const routeId = parseInt(route.params.routeId as string)
								if (ferryRoutes.value.find((obj) => obj.routeId === routeId)) {
									routePicker.ferryRoute.value = routeId
								} else {
									router.push({ name: 'searchresults', params: { tabname: 'alerts', routeType: 'ferry' } })
								}
							}
						}
					}
					Promise.all([alerts.fetchFilteredAlerts(), cameras.fetchFilteredCameras(), restrictions.fetchFilteredRestrictions(), reports.fetchFilteredReports()])
						.then(() => {
							if (init) {
								onSearchClick(route.params.tabname as string)
							}
							setTitle()
						})
				} else {
					onClearClick()
				}
			}

			Promise.all([fetchStateRoutes(), fetchFerryRoutes()]).then(() => {
				parseRouteParams(true)
			})

			watch(() => route.fullPath, () => {
				if (route.name === 'index' || route.name === 'searchresults') {
					parseRouteParams(false)
				}
			})

			watch(() => routePicker.vehicleRoute.value, (curr, prev) => {
				if (curr !== prev && prev) {
					routePicker.startLocation.value = null
					routePicker.endLocation.value = null
				}
			})

			const routeToWeather = () => {
				router.push({ name: 'weatherstations' })
			}

			const showAlerts = () => {
				router.push({ name: 'searchresults', params: { tabname: 'alerts' } })
			}

			const showCameras = () => {
				router.push({ name: 'searchresults', params: { tabname: 'cameras' } })
			}

			const showRestrictions = () => {
				router.push({ name: 'searchresults', params: { tabname: 'truck-restrictions' } })
			}

			const showPassReports = () => {
				router.push({ name: 'searchresults', params: { tabname: 'mountain-pass-reports' } })
			}

			const activateTab = (tabName: string) => {
				let routeParams = route.params
				let newTabName = route.params.tabname
				ShowSummary.value = false
				switch (tabName) {
					case 'TravelAlerts':
						newTabName = 'alerts'
						break;
					case 'Cameras':
						newTabName = 'cameras'
						break;
					case 'TruckRestrictions':
						newTabName = 'truck-restrictions'
						break;
					case 'PassReports':
						newTabName = 'mountain-pass-reports'
						break;
					default:
						ShowSummary.value = true
				}
				router.replace({ name: 'searchresults', params: { tabname: newTabName, routeType: routeParams.routeType, routeId: routeParams.routeId, startCity: routeParams.startCity, endCity: routeParams.endCity } })
				currentTab.value = tabName
				document.getElementById(tabName)?.focus()
			}

			const stateWideAlerts = computed(() => {
				return statewideAlerts.value.filter((obj) => obj.group === 'Statewide')
			})

			const getTabToShow = () => {
				if (route.params.tabname) {
					ShowSummary.value = false;
					switch ((route.params.tabname as string).toLowerCase()) {
						case 'alerts':
							currentTab.value = 'TravelAlerts'
							break;
						case 'cameras':
							currentTab.value = 'Cameras'
							break;
						case 'truck-restrictions':
							currentTab.value = 'TruckRestrictions'
							break;
						case 'mountain-pass-reports':
							currentTab.value = 'PassReports'
							break;
						default:
							ShowSummary.value = true;
					}
				} else {
					ShowSummary.value = true;
				}
			}

			watch(() => route.params, () => {
				getTabToShow()
			})

			onMounted(() => {
				getTabToShow()
			})

			const navigateToMap = () => {
				window.location.href = 'https://wsdot.com/travel/real-time/map'
			}

			return {
				...routePicker,
				stateRoutes,
				ferryRoutes,
				onSearchClick,
				onClearClick,
				onEditClick,
				onMapClick,
				MAP_URL,
				locationNameHeader,
				pageHeaderSimple,
				ShowSummary,
				cameras,
				alerts,
				restrictions,
				reports,
				routeToWeather,
				currentTab,
				showAlerts,
				showCameras,
				showRestrictions,
				showPassReports,
				activateTab,
				stateWideAlerts,
				currentTabReady,
				navigateToMap
			}
		}
	})
</script>

<style lang="scss">
	#vehicleroute {
		width: 50%;

		@include sm {
			width: 100%;
		}
	}

	label, .v-label {
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 12.8px;
		line-height: 16px;
		color: $Gray100;
	}

	.search-text {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 20px;
		font-weight: bold;
	}

	.search-text-route {
		font-weight: bold;
		line-height: 61px;
		font-size: 48.83px;
	}

	fieldset {
		display: flex;

		legend {
			display: none;
		}
	}

	.label-h3 {
		font-size: 25px;
		font-weight: bold;
		line-height: 31px;
		color: $Gray100;
	}

	.routepicker {
		width: 871px;

		@include sm {
			width: 100%;
		}

		.form-header {
			font-size: 39.06px;
			line-height: 49px;
			color: $Gray100;
			font-weight: 300;
			width: 100%;
			margin-bottom: 24px;
		}
	}

	.routepicker-body {
		width: 790px;
		display: flex;
		flex-grow: 0;
		flex-shrink: 0;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: space-between;

		@include sm {
			width: 100%;
			margin: auto;
		}
	}

	.routepicker-body-group {
		margin: 16px 0;
		width: 380px;

		@include sm {
			width: 100%;
		}
	}

	.routepicker-body-group-full {
		width: 790px;

		@include sm {
			width: 100%;
		}
	}

	.routepicker-body-group-full-horizontal {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: start;

		.routepicker-option {
			margin: 0px 16px;
		}

		.routepicker-option:first-child {
			margin-left: 0;
		}
	}

	.routepicker-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 16px;

		.routeType {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding: 0px;
			width: 100%;

			@include sm {
				justify-content: space-around;
				width: 100%;
				flex-wrap: wrap;
				row-gap: 5px;
			}

			& > fieldset {
				@include sm {
					display: flex;
					flex-direction: row;
					width: 100%;
					flex-wrap: wrap;
					justify-content: space-around;
				}

				.routepicker-option {
					position: static;
					left: 0px;
					top: 0px;
					flex: none;
					flex-grow: 0;
					margin: 0 16px;
					height: 29px;

					@include sm {
						display: flex;
						flex-wrap: wrap;
					}
				}

				.routetype-separator {
					position: static;
					left: 0px;
					top: 0px;
					flex: none;
					flex-grow: 0;
					margin: 0 16px;
					height: 29px;
					font-size: 12.8px;
					line-height: 16px;
					font-weight: bold;
					display: flex;
					align-items: center;
					text-align: center;

					@include sm {
						justify-content: center;
						display: flex;
						order: 2;
						flex-basis: 100%;
						flex-grow: 1;
						margin: 0;
					}
				}
			}

			.routepicker-map {
				margin-left: auto;

				@include sm {
					margin: auto;
					order: 3;
				}
			}
		}
	}

	.form-clear {
		text-decoration: underline;
		cursor: pointer;
		font-size: 18px;
		line-height: 28px;
		margin-left: 20px;
		color: $PrimaryBrand;
		border: 0;
	}

	.form-group:last-child {
		margin-bottom: 15px;
	}

	.v-radio {
		input[type="radio"] {
			position: absolute;
			opacity: 0;

			+ .v-radio-label {
				color: $PrimaryBrand;
				fill: $PrimaryBrand;
				font-size: 18px;
				line-height: 28px;
				font-weight: normal;
				display: flex;
				align-items: center;
				text-align: center;

				.v-radio-label-icon {
					margin-right: 0.5em;
					display: inline-block;
				}

				&:before {
					content: '';
					background: $White;
					border-radius: 100%;
					border: 1px solid darken($Gray20, 25%);
					display: inline-block;
					width: 24px;
					height: 24px;
					position: static;
					left: 0px;
					top: 2px;
					vertical-align: top;
					cursor: pointer;
					text-align: center;
					transition: all 250ms ease;
					margin-right: 0.5em;
				}

				&:empty {
					&:before {
						margin-right: 0;
					}
				}
			}

			&:checked {
				+ .v-radio-label {
					&:before {
						background-color: $PrimaryBrand;
						box-shadow: inset 0 0 0 4px $White;
					}
				}
			}

			&:focus {
				&:before {
					border-color: $PrimaryBrand;
					outline: 1px solid $PrimaryBrand;
				}
				+ .v-radio-label {
					&:before {
						outline: none;
						border-color: $PrimaryBrand;

					}
				}
			}

			&:disabled {
				+ .v-radio-label {
					&:before {
						box-shadow: inset 0 0 0 4px $PrimaryBrand;
						border-color: darken($PrimaryBrand, 25%);
						background: darken($PrimaryBrand, 25%);
					}
				}
			}
		}
	}

	#breadcrumb {
		clip-path: polygon(0 0,90% 0,88% 100%,0% 100%);
		@include sm {
			clip-path: polygon(0 0,98% 0,96% 100%,0% 100%);
			padding-left: 15px;
		}
	}

	#breadcrumb, .breadcrumb, ol.breadcrumb {
		background: #F4F4F5;
	}

	#pagesummary p {
		font-size: 20px;
	}

	.pullquote {
		flex: none;
		flex-grow: 0;
		margin: 24px;
		position: relative;
		background-color: $Gray5;
		z-index: 1;
		width: calc(100% - 48px);

		&:before {
			width: 47.5px;
			border-top: #D3ED97 3px solid;
			border-left: #D3ED97 3px solid;
			border-top-left-radius: 4px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			content: '';
			transform: skewX(-7deg);
			height: 100%;
			box-sizing: border-box;
		}

		&:after {
			width: 47.5px;
			border-bottom: #D3ED97 3px solid;
			border-right: #D3ED97 3px solid;
			border-bottom-right-radius: 4px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: -1;
			content: '';
			transform: skewX(-7deg);
			height: 100%;
			box-sizing: border-box;
		}

		span {
			display: block;
			padding: 12px 0;
			margin: 0 32px;
			font-size: 14.4px;
			line-height: 22px;
			color: $Gray100;
			box-sizing: content-box;
		}
	}

	.line {
		width: 100%;
		content: '';
		margin-top: 26px;
		border-bottom: 1px solid $Gray20;
	}

	.emergency-alerts {
		margin-top: 56px;

		.card {
			margin: 8px 0;
		}
	}

	.tab-container {
		margin-top: 20px;

		@include sm {
			margin-top: 0;
			padding-top: 20px;
			background-color: $Gray5;
		}
	}

	html body:not(.page-node-type-landing_page, .page-node-type-pathway-page) .main-container.container {
		max-width: 1260px;
		width: 100%;
		margin: 0 auto;
		padding-left: 0;
	}

	h1.page-header {
		color: $Gray100;
	}

	#page-summary {
		p {
			font-size: 20px;
			line-height: 31px;
			color: $Gray100;
		}
	}

	.padding-top-16 {
		padding-top: 16px;
	}

	.full-width {
		width: 954px;
		margin: 0 auto;

		@include sm {
			width: 100%;
		}

		.content-width {
			width: 790px;

			@include sm {
				width: 100%;
				margin: 0 auto;
			}
		}

		.content-indent {
			margin-left: 81px;

			@include sm {
				margin: 0 auto;
			}
		}
	}

	.gray-background {
		background-color: $Gray5;
	}

	.container-border-bottom {
		border-bottom: 1px solid $Gray20;
	}

	div.breadcrumb {
		background: $White;
		margin-bottom: 0;

		ol.breadcrumb {
			background: $White;
			margin-bottom: 9px;
		}
	}

	.v-button {
		&.tab-bold {
			> span {
				font-weight: bold;
			}
		}
	}

	.section {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		position: static;
		width: 790px;
		left: 0px;
		margin: 24px 0;

		@include sm {
			width: 100%;
		}

		a.section-link {
			font-family: Lato;
			font-style: normal;
			font-weight: bold;
			font-size: 25px;
			line-height: 31px;
			color: $PrimaryBrand;
			border-bottom: none;
			text-decoration: underline;
		}
	}

	h3.section-heading {
		display: flex;
		font-size: 25px;
		line-height: 31px;
		font-weight: bold;
		color: $Gray100;
	}

	.section-link-container {
		display: flex;
		margin-bottom: 8px;

		a.section-link {
			font-family: Lato;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 31px;
			color: $PrimaryBrand;
			border-bottom: none;
			text-decoration: underline;

			&:visited, &:hover {
				color: $PrimaryBrand;
				text-decoration: underline;
			}
		}
	}
</style>
