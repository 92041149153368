<template>
	<svg :width="width" :height="height" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
		<path d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"/>
		<path d="M16.7505 8.86758C16.7505 13.0793 13.2842 16.4998 9.00049 16.4998C4.71677 16.4998 1.25049 13.0793 1.25049 8.86758C1.25049 4.6559 4.71677 1.23535 9.00049 1.23535C13.2842 1.23535 16.7505 4.6559 16.7505 8.86758Z" stroke="white" stroke-width="0.5"/>
		<line x1="2.40001" y1="8.60001" x2="15.1775" y2="8.60024" stroke="white" stroke-width="0.8" stroke-linecap="square" stroke-dasharray="4 4"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M12.2597 3.15005C12.3366 3.01672 12.5291 3.01672 12.6061 3.15005L14.3594 6.18688C14.4364 6.32021 14.3401 6.48688 14.1862 6.48688H12.9181V12.3482C12.9181 12.4587 12.8285 12.5482 12.7181 12.5482H12.1482C12.0378 12.5482 11.9482 12.4587 11.9482 12.3482V6.48688H10.6795C10.5256 6.48688 10.4294 6.32021 10.5063 6.18688L12.2597 3.15005Z" fill="white"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M5.36113 5.75952C5.25067 5.75952 5.16113 5.84906 5.16113 5.95952V10.8511H3.89244C3.73848 10.8511 3.64225 11.0177 3.71923 11.1511L5.47255 14.1879C5.54953 14.3212 5.74198 14.3212 5.81896 14.1879L7.57227 11.1511C7.64925 11.0177 7.55303 10.8511 7.39907 10.8511H6.13095V5.95952C6.13095 5.84906 6.04141 5.75952 5.93095 5.75952H5.36113Z" fill="white"/>
		<circle cx="9.03974" cy="8.66889" r="1.93964" fill="white"/>
		<path d="M8.07031 9.10987L8.91178 9.63886L10.01 7.69922" stroke="#E885B4" stroke-width="0.5" stroke-linecap="square" stroke-linejoin="round"/>
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'BorderCrossingIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
