﻿<template>
	<generic-card bannerText="Mountain pass report" iconName="mountain" bannerTag="" class="mountain">
		<div role="heading" aria-level="4" class="card-title">{{ passReport.mountainPass?.mountainPassName }}</div>
		<div class="card-location">
		</div>
		<div class="card-body">
			<div class='pass-report'>
				<div v-if="formattedTemperature"><span><strong>Temperature: </strong>{{ formattedTemperature }}</span></div>
				<div><span><strong>Elevation: </strong>{{ formattedElevation }}</span></div>
				<div v-if="passReport.condition?.travelAdvisoryFlag">
					<div><span><strong>Travel {{ passReport.condition?.restrictionOne?.travelDirectionName?.toLowerCase() }}: </strong>{{ passReport.condition?.restrictionOne?.publicPage }}</span></div>
					<div><span><strong>Travel {{ passReport.condition?.restrictionTwo?.travelDirectionName?.toLowerCase() }}: </strong>{{ passReport.condition?.restrictionTwo?.publicPage }}</span></div>
				</div>
				<div v-if="passReport.condition?.roadCondition"><span><strong>Conditions: </strong>{{ passReport.condition?.roadCondition }}</span></div>
				<div v-if="passReport.condition?.weather"><span><strong>Weather: </strong>{{ passReport.condition?.weather }}</span></div>
				<div><span><strong>Last updated: </strong>{{ formattedDisplayDate }}</span></div>
				<div>
					<span>
						Learn more about
						<router-link :to="{ name: 'mountainpass', params: { id: passReport.mountainPass?.friendlyName ?? '' } }">
							{{passReport.mountainPass?.mountainPassName}}
						</router-link>
					</span>
				</div>
			</div>
			<div class='pass-camera' v-if="passCamera">
				<Camera :flat="true" :camera="passCamera" :key="passCamera.cameraId" />
			</div>
		</div>
	</generic-card>
</template>

<script lang="ts">
	import { defineComponent, PropType, computed } from 'vue'
	import { useHelpers } from '@/composables/helpers'
	import { MountainPassReportModel } from '@/api/travelServiceApi'
	import GenericCard from '@/components/GenericCard.vue'
	import Camera from '@/components/Cameras/Camera.vue'
	import useCameras from '@/store/cameras'

	export default defineComponent({
		name: 'PassReportCard',
		components: {
			GenericCard,
			Camera
		},
		props: {
			passReport: {
				type: Object as PropType<MountainPassReportModel>,
				required: true
			}
		},
		setup(props) {
			const { formatTemperature, formatElevation, formatDateTimeLongDayMonth } = useHelpers()
			const { cameras } = useCameras()

			const passCamera = computed(() => {

				if (props.passReport?.mountainPass?.defaultCameraId) {
					return cameras.value.find(c => props.passReport?.mountainPass?.defaultCameraId === c.cameraId)
				}
				return undefined
			})

			const formattedTemperature = computed(() => {
				if (props.passReport?.condition?.temperature) {
					return formatTemperature(
						props.passReport?.condition?.temperature,
						props.passReport?.condition?.temperatureUnit ?? '',
						props.passReport?.condition?.displayDate
					)
				}
				return undefined
			})

			const formattedElevation = computed(() => {
				if (props.passReport?.mountainPass?.elevation) {
					return formatElevation(
						props.passReport.mountainPass?.elevation,
						props.passReport.mountainPass?.elevationUnit ?? ''
					)
				}
				return undefined
			})

			const formattedDisplayDate = computed(() => {
				var displayDate = props.passReport?.condition?.displayDate
				if (displayDate) {
					return formatDateTimeLongDayMonth(displayDate)
				}
				return undefined
			})

			return {
				formattedTemperature,
				formattedElevation,
				formattedDisplayDate,
				passCamera
			}
		}
	})
</script>

<style lang="scss">
	.card.mountain {
		width: 954px;

		@include sm {
			width: calc(100% - 20px);
			align-self: flex-start;
		}

		.card-tags-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0px;
			position: static;
			width: 129px;
			height: 24px;
			left: 0px;
			top: 8px;
			flex: none;
			order: 0;
			flex-grow: 0;
			margin: 0;

			.card-banner {
				background-color: rgba($MountainPassBlue, 0.2);
				border: 1px solid $MountainPassBlue;

				.card-banner-text {
					position: static;
				}

				.card-banner-img {
					fill: $MountainPassBlue
				}
			}
		}

		.card-content-wrapper {
			width: 954px;

			@include sm {
				width: 100%;
			}

			.card-title {
				font-family: Lato;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				color: $Gray100;
				position: static;
				left: 16px;
				top: 16px;
				order: 1;
				align-self: stretch;
				flex-grow: 0;
				margin: 4px 0px;
			}

			.card-location {
				flex-direction: row;
				align-items: flex-start;
				padding: 0px;
				position: static;
				left: 16px;
				top: 44px;
				order: 2;
				flex: none;
				flex-grow: 0;
				margin: 4px 0px;
			}

			.card-body {
				position: static;
				left: 16px;
				font-size: 14.4px;
				line-height: 18px;
				color: $Gray100;
				order: 3;
				align-self: stretch;
				margin: 4px 0px;
				display: flex;
				flex-direction: row;
				width: 100%;

				@include sm {
					flex-direction: column;
				}

				div {
					margin-bottom: 8px;
				}

				.pass-report {
					display: flex;
					flex-direction: column;
					width: 619px;

					@include sm {
						width: 100%;
					}
				}

				.pass-camera {
					width: 335px;

					@include sm {
						width: 100%;
					}

					.flat-camera {
						.flat-camera-image {
							height: unset;
							flex: none;

							img {
								width: unset;
								max-width: 100%;
								max-height: 100%;
							}
						}
					}
				}

				span {
					p {
						font-size: 14.4px;
						line-height: 18px;
					}
				}
			}
		}
	}
</style>
