﻿<template>
	<generic-card :bannerText="travelAlert.eventCategoryTypeDescription ?? ''" iconName="alert" :bannerTag="priorityName" :class="['alert', priorityClass]">
		<div role="heading" aria-level="4" class="card-title" v-html="travelAlert.alertTitle"></div>
		<div class="card-location" v-if="travelAlert.mapLinkText">
			<v-map-link :linkText="travelAlert.mapLinkText" :linkUrl="mapLink"></v-map-link>
		</div>
		<div class="card-body">
			<span v-html="travelAlert.headlineMessage"></span><br />
			<template v-if="travelAlert.extendedMessage">
				<span v-html="travelAlert.extendedMessage"></span>
				<br />
			</template>
			<br />
			<span><strong>Last updated: </strong> {{ formatDateTime(travelAlert.lastModifiedDate) }}</span>
		</div>
	</generic-card>
</template>

<script lang="ts">
	import { defineComponent, PropType, computed, inject } from 'vue'
	import { IAlert } from '@/interfaces/IAlert'
	import { useHelpers } from '@/composables/helpers'
	import GenericCard from '@/components/GenericCard.vue'
	import VMapLink from '@/components/UI/VMapLink.vue'
	import VIcon from '@/components/UI/VIcon.vue'

	export default defineComponent({
		name: 'TravelAlert',
		components: {
			GenericCard,
			VIcon,
			VMapLink
		},
		props: {
			travelAlert: {
				type: Object as PropType<IAlert>,
				required: true
			}
		},
		setup(props) {
			const { formatDateTime } = useHelpers()
			const MAP_URL = inject('$MAP_URL') as string
			const mapLink = computed(() => {
				return props.travelAlert.alertType === 'Ferry' ?
					`${MAP_URL}?featuretype=ferryroute&featureid=${props.travelAlert.alertId}`
					: `${MAP_URL}?featuretype=alert&featureid=${props.travelAlert.alertId}`
			})

			const priorityClass = computed(() => {
				return {
					'card-banner-low': props.travelAlert.travelCenterPriorityId >= 4,
					'card-banner-medium': props.travelAlert.travelCenterPriorityId === 3,
					'card-banner-high': props.travelAlert.travelCenterPriorityId === 2,
					'card-banner-closed': props.travelAlert.travelCenterPriorityId === 1
				}
			})

			const priorityName = computed(() => {
				switch (props.travelAlert.travelCenterPriorityId) {
					case 1: {
						return 'Closed'
					}
					case 2: {
						return 'High'
					}
					case 3: {
						return 'Medium'
					}
					case 4: {
						return 'Low'
					}
				}
			})

			return {
				mapLink,
				priorityClass,
				priorityName,
				formatDateTime
			}
		}
	})
</script>

<style lang="scss">
	.card.alert {
		.card-tags-header {
			.card-banner {
				background-color: rgba($Warning, 0.3);
				border: 1px solid $Warning;
			}
		}

		&.card-banner-low {
			.card-tags-header {
				.card-banner {
					.card-banner-img {
						fill: $Warning;
					}
				}

				.card-banner-priority {
					background-color: $WarningBG;
					border: 1px solid $Warning;
				}
			}
		}

		&.card-banner-medium {
			.card-tags-header {
				.card-banner {
					.card-banner-img {
						fill: $PMS1585;
					}
				}

				.card-banner-priority {
					background-color: rgba($PMS1585, 0.3);
					border: 1px solid $PMS1585;
				}
			}
		}

		&.card-banner-high {
			.card-tags-header {
				.card-banner {
					.card-banner-img {
						fill: $HighestAlertRed;
					}
				}

				.card-banner-priority {
					background-color: rgba($HighestAlertRed, 0.4);
					border: 1px solid $HighestAlertRed;
				}
			}
		}
		&.card-banner-closed {
			.card-tags-header {
				.card-banner {
					.card-banner-img {
						fill: $Gray100;
					}
				}

				.card-banner-priority {
					background-color: rgba($Gray100, 0.8);
					border: 1px solid $Gray100;

					.card-banner-priority-text {
						color: $White;
					}
				}
			}
		}

		.card-content-wraper {
			.card-title {
				font-family: Lato;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				color: $Gray100;
				position: static;
				left: 16px;
				top: 16px;
				flex: none;
				order: 1;
				align-self: stretch;
				flex-grow: 0;
				margin: 4px 0px;
			}

			.card-location {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 0px;
				position: static;
				left: 16px;
				top: 44px;
				order: 2;
				flex: none;
				flex-grow: 0;
				margin: 4px 0px;
			}

			.card-body {
				position: static;
				left: 16px;
				font-size: 14.4px;
				line-height: 18px;
				color: $Gray100;
				flex: none;
				order: 3;
				align-self: stretch;
				flex-grow: 0;
				margin: 4px 0px;
			}

			.card-affected-tags {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 0px;
				position: static;
				left: 16px;
				order: 4;

				.card-affected-tag {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 0px;
					position: static;
					height: 16px;
					left: 0px;
					top: 0px;
					flex: none;
					order: 0;
					flex-grow: 0;
					margin: 0px 8px 0px 0px;

					.card-affected-tag-img {
						position: static;
						width: 16px;
						height: 16px;
						left: 0px;
						top: 0px;
						flex: none;
						order: 0;
						flex-grow: 0;
						margin: 0px;
					}

					.card-affected-tag-text {
						position: static;
						left: 20px;
						top: 0px;
						font-size: 12.8px;
						line-height: 16px;
						color: $Gray100;
						flex: none;
						order: 1;
						flex-grow: 0;
						margin: 0 4px;
					}
				}
			}
		}
	}
</style>
