import { reactive, toRefs, computed, ComputedRef, Ref } from 'vue'
import { IRoutePicker } from '@/interfaces/IRoutePicker'
import { useLocation, ILocationData } from '@/interfaces/ILocation'
import useStateRoutes from '@/store/stateRoutes'
import useFerryRoutes from '@/store/ferryRoutes'

const state = reactive({
	vehicleRoute: null,
	startLocation: null,
	endLocation: null,
	ferryRoute: null,
	searchActive: false,
	routeType: null,
	loaded: false
} as IRoutePicker)

export const useRoutePicker = (): {
	vehicleRoute: Ref<string | null>
	startLocation: Ref<ILocationData | null>
	endLocation: Ref<ILocationData | null>
	ferryRoute: Ref<number | null>
	searchActive: Ref<boolean>
	routeType: Ref<string | null>
	loaded: Ref<boolean>
	cities: ComputedRef<ILocationData[]>
	startCities: ComputedRef<ILocationData[]>
	endCities: ComputedRef<ILocationData[]>
	hasLocation: ComputedRef<boolean>
	hasMileposts: ComputedRef<boolean>
	lowMp: ComputedRef<number>
	highMp: ComputedRef<number>
} => {

	const { stateRoutes, fetchStateRoutes } = useStateRoutes()
	const { fetchFerryRoutes } = useFerryRoutes()

	function locationSortByName(a: ILocationData, b: ILocationData) {
		if (a.name > b.name) {
			return 1
		}
		if (a.name < b.name) {
			return -1
		}
		return 0
	}

	const cities = computed((): ILocationData[] => {
		if (state.vehicleRoute) {
			let srId = state.vehicleRoute
			if (srId !== '') {
				if (stateRoutes.value && stateRoutes.value.length > 0) {
					const foundCities = stateRoutes.value.find(item => item.stateRouteId === srId)?.cities?.map(a => {
						return useLocation(a.city ?? '', a.mpBegin ?? 0, a.mpEnd ?? null, 'B')
					})
					return foundCities ?? []
				}
			}
		}
		return []
	})

	const startCities = computed((): ILocationData[] => {
		if (state.endLocation) {
			return cities.value?.filter((item) => {
				return item !== state.endLocation
			}).sort(locationSortByName)
		}
		return cities.value.sort(locationSortByName)
	})

	const endCities = computed((): ILocationData[] => {
		if (state.startLocation) {
			return cities.value?.filter((item) => {
				return item.name !== state.startLocation?.name
			}).sort(locationSortByName)
		}
		return cities.value.sort(locationSortByName)
	})

	const hasLocation = computed((): boolean => {
		return state.vehicleRoute ? true : false
	})

	const hasMileposts = computed((): boolean => {
		return hasLocation.value && (state.startLocation !== null || state.endLocation !== null) ? true : false
	})

	const lowMp = computed((): number => {
		if (hasMileposts.value) {
			const startMin = state.startLocation?.minValue ?? 9999
			const endMin = state.endLocation?.minValue ?? 9999
			if (startMin === 9999 && endMin === 9999) {
				return 0
			}
			return (startMin < endMin ? startMin : endMin)
		}
		return 0
	})

	const highMp = computed((): number => {
		if (hasMileposts.value) {
			const startMax = state.startLocation?.maxValue ?? 0
			const endMax = state.endLocation?.maxValue ?? 0
			if (startMax === 0 && endMax === 0) {
				return 9999
			}
			return (startMax > endMax ? startMax : endMax)
		}
		return 9999
	})

	fetchStateRoutes().finally()
	fetchFerryRoutes().finally()

	return {
		...toRefs(state),
		cities,
		startCities,
		endCities,
		hasLocation,
		hasMileposts,
		lowMp,
		highMp
	}
}