<template>
	<VFilterPage :loaded="loaded" pageTitle="Alerts">
		<template #filter>
			<div id="filterPanel" class="v-modal">
				<v-icon class="modal-close" name="filterClose" :width="26" :height="26" @keyup.enter="closeFilters" @click="closeFilters" tabindex="0" role="button" aria-label="close filters" />
				<div class="filter-header">
					<div role="heading" aria-level="3" class="form-header">Filter alerts</div>
					<a href="#alert-results" class="visually-hidden focusable skip-link" @click.prevent="resultsFocus('alert-results')">Skip to results</a>
					<div class="filter-panel-clearall" role="button" @keyup.enter="onClearClick" @click="onClearClick" tabindex="0">Clear</div>
				</div>
				<div role="group" aria-labelledby="filterAlertLevel" class="filter-group">
					<div class="form-category-header" id="filterAlertLevel">Alert level</div>
					<fieldset>
						<legend>Alert Level</legend>
						<template v-for="[key, value] in priorityFilters" :key="key">
							<div class="v-checkbox">
								<input type="checkbox" :id="`priority${key}`" v-model="checkedPriorityIds" :value="key" :disabled="value.indexOf('(0)') >= 0" />
								<label class="v-checkbox-label" :for="`priority${key}`">{{ value }}</label>
							</div>
						</template>
					</fieldset>
				</div>
				<div role="group" aria-labelledby="filterAlertType" class="filter-group">
					<div class="form-category-header" id="filterAlertType">Alert type</div>
					<fieldset>
						<legend>Alert Type</legend>
						<template v-for="[key, value] in categoryFilters" :key="key">
							<div class="v-checkbox">
								<input type="checkbox" :id="`category${key}`" v-model="checkedCategoryIds" :value="key" :disabled="value.indexOf('(0)') >= 0" />
								<label class="v-checkbox-label" :for="`category${key}`">{{ value }}</label>
							</div>
						</template>
					</fieldset>
				</div>
				<div role="group" aria-labelledby="travelDirection" class="filter-group">
					<div class="form-category-header" id="travelDirection">Travel direction</div>
					<fieldset>
						<legend>Travel Direction</legend>
						<template v-for="[key, value] in directionFilters" :key="key">
							<div class="v-checkbox">
								<input type="checkbox" :id="`direction${key}`" v-model="checkedDirections" :value="key" :disabled="value.indexOf('(0)') >= 0" />
								<label class="v-checkbox-label" :for="`direction${key}`">{{ value }}</label>
							</div>
						</template>
					</fieldset>
				</div>
				<div class="filter-footer">
					<v-button style="width: 150px;" button-label="Apply" tabindex="0" @click="applyFilters" class="v-button-primary v-button-small v-button-center" />
				</div>
			</div>
		</template>
		<template #mobile>
			<v-icon-button class="v-button v-button-primary" @click="openFilters" iconName="filter" buttonLabel="Filter Alerts" iconPosition="Right" />
		</template>
		<template #tags>
			<template v-for="[key, value] in appliedPriorityFilters" :key="key">
				<v-tag @close="priorityClose(key)" :title="value.substring(0, value.lastIndexOf(' '))" />
			</template>
			<template v-for="[key, value] in appliedCategoryFilters" :key="key">
				<v-tag @close="categoryClose(key)" :title="value.substring(0, value.lastIndexOf(' '))" />
			</template>
			<template v-for="[key, value] in appliedDirectionFilters" :key="key">
				<v-tag @close="directionClose(key)" :title="value.substring(0, value.lastIndexOf(' '))" />
			</template>
			<template v-if="appliedPriorityFilters.size > 0 || appliedCategoryFilters.size > 0 || appliedDirectionFilters.size > 0">
				<div class="tag-list-clearall v-tag" role="button" @keyup.enter="onClearClick" @click="onClearClick" tabindex="0">Clear All</div>
			</template>
		</template>
		<div class="result-header" id="alert-results" tabindex="-1">
			<div class="result-count" v-if="totalAlerts > 0" role="heading" aria-level="3">
				{{ totalAlerts.toLocaleString('en-US') }} 
				<template v-if="totalAlerts === 1">
					result
				</template>
				<template v-else>
					results
				</template>
			</div>
			<div class="result-count" v-if="totalAlerts === 0" role="heading" aria-level="3">
				0 alerts found
			</div>
			<div class="result-sort" v-if="totalAlerts > 0">
				<span>Sort by </span>
				<select class="form-control" v-model="selectedSort">
					<option value="level">Alert level</option>
					<option value="updated">Recently updated</option>
				</select>
				<div class="sort-button" role="button" @click="onSortChange" @keyup.enter="onSortChange" tabindex="0">Sort</div>
			</div>
		</div>
		<template v-for="alert in result" :key="alert.alertId">
			<template v-if="alert.group === 'Statewide'">
				<emergency-alert :travelAlert="alert" />
			</template>
			<template v-else>
				<travel-alert :travelAlert="alert"></travel-alert>
			</template>
		</template>
		<VPager :pagesEachSide="2" :currentPage="currentPage" :totalPages="lastPage" @setPage="setPage" @prev="prev" @next="next" @applyPageSize="applyPageSize" :pageSize="pageSize" />
	</VFilterPage>
</template>

<script lang="ts">
	import { defineComponent, watch, ref, computed } from 'vue'
	import { useArrayPagination } from 'vue-composable'
	import TravelAlert from '@/components/TravelAlerts/TravelAlert.vue'
	import EmergencyAlert from '@/components/TravelAlerts/EmergencyAlert.vue'
	import VFilterPage from '@/components/UI/VFilterPage.vue'
	import VTag from '@/components/UI/VTag.vue'
	import VIconButton from '@/components/UI/VIconButton.vue'
	import VIcon from '@/components/UI/VIcon.vue'
	import VPager from '@/components/UI/VPager.vue'
	import VButton from '@/components/UI/VButton.vue'
	import { useAlertFilters } from '@/composables/alertFilters'

	export default defineComponent({
		name: 'TravelAlerts',
		components: {
			VFilterPage,
			TravelAlert,
			VPager,
			VButton,
			VTag,
			VIconButton,
			VIcon,
			EmergencyAlert
		},
		setup() {
			const { alerts, loaded, appliedCategoryFilters, appliedPriorityFilters, appliedDirectionFilters, categoryFilters, priorityFilters, directionFilters, selectedSort, fetchFilteredAlerts } = useAlertFilters()
			const checkedCategoryIds = ref([] as string[])
			const checkedPriorityIds = ref([] as number[])
			const checkedCategories = ref(new Map<string, string>())
			const checkedPriorities = ref(new Map<number, string>())
			const checkedDirections = ref([] as string[])

			const defaultPageSize = 10

			const { result, next, prev, currentPage, lastPage, pageSize } = useArrayPagination(
				alerts,
				{
					currentPage: 1
				}
			)

			pageSize.value = defaultPageSize

			const applyPageSize = (newSize: number) => {
				pageSize.value = newSize
				currentPage.value = 1

			}

			const setPage = (index: number) => {
				currentPage.value = index
			}

			const applyFilters = () => {
				appliedPriorityFilters.value.clear()
				checkedPriorityIds.value.forEach(x => {
					if (priorityFilters.value.has(x)) {
						appliedPriorityFilters.value.set(x, priorityFilters.value.get(x) ?? '')
					}
				})

				appliedCategoryFilters.value.clear()
				checkedCategoryIds.value.forEach(x => {
					if (categoryFilters.value.has(x)) {
						appliedCategoryFilters.value.set(x, categoryFilters.value.get(x) ?? '')
					}
				})

				appliedDirectionFilters.value.clear()
				checkedDirections.value.forEach(x => {
					if (directionFilters.value.has(x)) {
						appliedDirectionFilters.value.set(x, directionFilters.value.get(x) ?? '')
					}
				})
				fetchFilteredAlerts()
				closeFilters()
			}

			const priorityClose = (value: number) => {
				const index = checkedPriorityIds.value.indexOf(value, 0)
				if (index > -1) {
					checkedPriorityIds.value.splice(index, 1)
				}
				applyFilters()
			}

			const categoryClose = (value: string) => {
				const index = checkedCategoryIds.value.indexOf(value, 0)
				if (index > -1) {
					checkedCategoryIds.value.splice(index, 1)
				}
				applyFilters()
			}

			const directionClose = (value: string) => {
				const index = checkedDirections.value.indexOf(value, 0)
				if (index > -1) {
					checkedDirections.value.splice(index, 1)
				}
				applyFilters()
			}

			const onSortChange = () => {
				applyFilters()
			}

			watch(alerts, (newValue, oldValue) => {
				if (newValue !== oldValue) {
					currentPage.value = 1
				}
			})

			const totalAlerts = computed(() => {
				return alerts.value.length
			})

			const closeFilters = () => {
				document.getElementById('filterPanel')?.classList.remove('active')
				document.removeEventListener('keydown', handleFilterEscape, false)
			}

			const handleFilterEscape = (e: KeyboardEvent) => {
				if (e.key === 'Escape') {
					closeFilters()
				}
			}

			const openFilters = () => {
				document.getElementById('filterPanel')?.classList.add('active')
				document.addEventListener('keydown', handleFilterEscape, false)
			}

			const resultsFocus = (elid: string) => {
				document.getElementById(elid)?.focus()
			}

			const onClearClick = () => {
				checkedPriorityIds.value = []
				checkedCategoryIds.value = []
				checkedDirections.value = []
				applyFilters();
			}

			return {
				result,
				currentPage,
				lastPage,
				pageSize,
				applyPageSize,
				next,
				prev,
				setPage,
				categoryFilters,
				priorityFilters,
				directionFilters,
				applyFilters,
				checkedCategoryIds,
				checkedCategories,
				checkedPriorityIds,
				checkedPriorities,
				checkedDirections,
				appliedCategoryFilters,
				appliedPriorityFilters,
				appliedDirectionFilters,
				priorityClose,
				categoryClose,
				directionClose,
				onSortChange,
				selectedSort,
				totalAlerts,
				openFilters,
				closeFilters,
				resultsFocus,
				onClearClick,
				loaded
			}
		}
	})
</script>

<style lang="scss">
</style>
