<template>
	<div class="pager">
		<nav class="pager-wrapper" role="navigation" aria-label="Pagination">
			<div v-if="showFirstLast" :class="['pager-page', { disabled: currentPage === 1 }]" @keyup.enter="first" @click="first" role="button" aria-label="Goto First Page" tabindex="0">First</div>
			<div :class="['pager-page', { disabled: currentPage === 1 }]" @keyup.enter="prev" @click="prev" role="button" aria-label="Goto Previous Page" tabindex="0">Previous</div>
			<div v-for="page in pagesToDisplay" :key="page" :class="['pager-page', { current: currentPage === page }]" @keyup.enter="setPage(page)" @click="setPage(page)" role="button" :aria-current="currentPage === page ? 'true' : undefined" :aria-label="`Page ${page}`" tabindex="0">{{ page }}</div>
			<div :class="['pager-page', { disabled: currentPage === totalPages }]" @keyup.enter="next" @click="next" role="button" aria-label="Goto Next Page" tabindex="0">Next</div>
			<div v-if="showFirstLast" :class="['pager-page', { disabled: currentPage === totalPages }]" @keyup.enter="last" @click="last" role="button" aria-label="Goto Last Page" tabindex="0">Last</div>
		</nav>
		<div class="pager-page-size">
			<span>Page size </span>
			<select v-model="newPageSize" class="form-control">
				<option v-for="size in pageSizes" :key="size" :value="size" :selected="(pageSize === size)">{{ size }}</option>
			</select>
			<div class="apply-button" role="button" tabindex="0" @click="applyPageSize" @keyup.enter="applyPageSize">Apply</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, ref, toRefs } from 'vue'
	import { usePageNumbersToDisplay } from '@/composables/pageNumbersToDisplay'

	export default defineComponent({
		name: 'VPager',
		props: {
			pagesEachSide: {
				type: Number,
				default: 3
			},
			currentPage: {
				type: Number,
				default: 1
			},
			totalPages: {
				type: Number,
				default: 1
			},
			showFirstLast: {
				type: Boolean,
				default: false
			},
			pageSize: {
				type: Number,
				required: true
			}
		},
		setup(props, { emit }) {
			const { currentPage, totalPages, pagesEachSide, pageSize } = toRefs(props)
			const { pagesToDisplay } = usePageNumbersToDisplay(currentPage, totalPages, pagesEachSide)

			const pageSizes = [ 10, 20, 50, 100 ]
			const newPageSize = ref(pageSize.value)

			const applyPageSize = () => {
				emit('applyPageSize', newPageSize.value)
			}

			const setPage = (id: number) => {
				emit('setPage', id)
			}

			const prev = () => {
				emit('prev')
			}

			const next = () => {
				emit('next')
			}

			const first = () => {
				emit('setPage', 1)
			}

			const last = () => {
				emit('setPage', props.totalPages)
			}

			return {
				pageSize,
				newPageSize,
				applyPageSize,
				pagesToDisplay,
				pageSizes,
				setPage,
				prev,
				next,
				first,
				last
			}
		}
	})
</script>

<style lang="scss">
	.pager {
		display: flex;
		flex-direction: row;

		.pager-wrapper {
			position: static;
			flex: none;
			flex-grow: 0;
			margin: 16px auto;
			align-items: center;
			display: flex;
			width: 50%;
			justify-content: flex-start;

			.pager-page {
				font-weight: bold;
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				padding: 10px 12px;
				top: 0%;
				bottom: 0%;
				background: $White;
				border: 1px solid $Gray20;
				box-sizing: border-box;
				color: $PrimaryBrand;
				margin-left: -1px;

				&:first-child {
					border-radius: 4px 0px 0px 4px;
					margin-left: 0;
				}

				&:last-child {
					border-radius: 0px 4px 4px 0px;
				}

				&:hover {
					cursor: pointer;
				}

				&.current {
					background-color: $Gray5;
					color: $Gray100;

					&:hover {
						cursor: default;
					}
				}

				&.disabled {
					color: $Gray100;

					&:hover {
						cursor: default;
					}
				}
			}
		}

		.pager-page-size {
			justify-content: flex-end;
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 50%;

			span {
				flex: none;
				display: flex;
				margin-right: 8px;
			}

			.form-control {
				width: 8rem;
			}

			.apply-button {
				display: flex;
				flex: none;
				flex-grow: 0;
				border-radius: 4px;
				background-color: $White;
				border: 1px solid $PrimaryBrand;
				color: $PrimaryBrand;
				cursor: pointer;
				height: 34px;
				font-size: 12px;
				line-height: 20px;
				padding: 6px 12px;
				justify-content: center;
				margin-left: 4px;
			}
		}
	}
</style>
