<template>
	<svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0)">
			<path d="M10.2718 3.56667C11.0131 3.56667 11.6196 2.96016 11.6196 2.21888C11.6196 1.4776 11.0131 0.871094 10.2718 0.871094C9.53054 0.871094 8.92404 1.4776 8.92404 2.21888C8.92404 2.96016 9.53054 3.56667 10.2718 3.56667ZM3.19594 7.94697C1.30904 7.94697 -0.173523 9.42954 -0.173523 11.3164C-0.173523 13.2033 1.30904 14.6859 3.19594 14.6859C5.08285 14.6859 6.56541 13.2033 6.56541 11.3164C6.56541 9.42954 5.08285 7.94697 3.19594 7.94697ZM3.19594 13.6751C1.91555 13.6751 0.837317 12.5968 0.837317 11.3164C0.837317 10.036 1.91555 8.95781 3.19594 8.95781C4.47634 8.95781 5.55457 10.036 5.55457 11.3164C5.55457 12.5968 4.47634 13.6751 3.19594 13.6751ZM7.10453 6.93613L8.72187 5.31879L9.26099 5.8579C10.137 6.73397 11.2827 7.27308 12.6978 7.27308V5.92529C11.687 5.92529 10.8783 5.52096 10.2718 4.91445L8.99143 3.63406C8.65448 3.3645 8.31753 3.22972 7.9132 3.22972C7.50886 3.22972 7.17192 3.3645 6.96975 3.63406L5.08285 5.52096C4.81329 5.79052 4.67851 6.12746 4.67851 6.46441C4.67851 6.86874 4.81329 7.20569 5.08285 7.40786L7.23931 9.29476V12.6642H8.58709V8.48609L7.10453 6.93613ZM12.6305 7.94697C10.7436 7.94697 9.26099 9.42954 9.26099 11.3164C9.26099 13.2033 10.7436 14.6859 12.6305 14.6859C14.5174 14.6859 15.9999 13.2033 15.9999 11.3164C15.9999 9.42954 14.5174 7.94697 12.6305 7.94697ZM12.6305 13.6751C11.3501 13.6751 10.2718 12.5968 10.2718 11.3164C10.2718 10.036 11.3501 8.95781 12.6305 8.95781C13.9109 8.95781 14.9891 10.036 14.9891 11.3164C14.9891 12.5968 13.9109 13.6751 12.6305 13.6751Z" fill="#1D252D" />
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'CyclistIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
