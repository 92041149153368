<template>
	<VFilterPage :loaded="loaded" pageTitle="Truck restrictions">
		<template #filter>
			<div id="filterPanel" class="v-modal">
				<v-icon class="modal-close" name="filterClose" :width="26" :height="26" @keyup.enter="closeFilters" @click="closeFilters" tabindex="0" role="button" aria-label="close filters" />
				<div class="filter-header">
					<div role="heading" aria-level="3" class="form-header">Filter truck restrictions</div>
					<a href="#restriction-results" class="visually-hidden focusable skip-link" @click.prevent="resultsFocus('restriction-results')">Skip to results</a>
					<div class="filter-panel-clearall" role="button" @keyup.enter="onClearClick" @click="onClearClick" tabindex="0">Clear</div>
				</div>
				<div role="group" aria-labelledby="filterAlertLevel" class="filter-group">
					<div class="form-category-header" id="filterAlertLevel">Restriction type</div>
					<fieldset>
						<legend>Restriction Type</legend>
						<template v-for="[key, value] in typeFilters" :key="key">
							<div class="v-checkbox">
								<input type="checkbox" :id="`type${key}`" v-model="checkedTypes" :value="key" :disabled="value.indexOf('(0)') >= 0" />
								<label class="v-checkbox-label" :for="`type${key}`">{{ value }}</label>
							</div>
						</template>
					</fieldset>
				</div>
				<div role="group" aria-labelledby="travelDirection" class="filter-group">
					<div class="form-category-header" id="travelDirection">Travel direction</div>
					<fieldset>
						<legend>Travel Direction</legend>
						<template v-for="[key, value] in directionFilters" :key="key">
							<div class="v-checkbox">
								<input type="checkbox" :id="`direction${key}`" v-model="checkedDirections" :value="key" :disabled="value.indexOf('(0)') >= 0" />
								<label class="v-checkbox-label" :for="`direction${key}`">{{ value }}</label>
							</div>
						</template>
					</fieldset>
				</div>
				<div class="filter-footer">
					<v-button style="width: 150px;" button-label="Apply" tabindex="0" @click="applyFilters" class="v-button-primary v-button-small v-button-center" />
				</div>
			</div>
		</template>
		<template #leftcolumn>
			<div class="filter">
				<div class="v-modal" id="bridgekey">
					<v-icon class="modal-close" name="filterClose" :width="26" :height="26" @keyup.enter="closeBridgeKey" @click="closeBridgeKey" tabindex="0" role="button" aria-label="close bridge weight key" />
					<div class="filter-header">
						<div role="heading" aria-level="3" class="form-header">Bridge weight key</div>
					</div>
					<div class="filter-group">
						<span class="form-category-header" role="heading" aria-level="4">CL-8</span>
						<p>
							Class 8<br />
							Applies to 5 axle combinations (3 axle tractor with a 2 axle trailer)
						</p>
						<span class="form-category-header" role="heading" aria-level="4">BL</span>
						<p>
							Big load<br />
							Applies to 3 or 4 axle single unit vehicles or combinations with 6 axles or more.
						</p>
						<span class="form-category-header" role="heading" aria-level="4">SA</span>
						<p>
							Single axle<br />
							Applies to 2 axle vehicles with very large tires described in <a href="http://app.leg.wa.gov/RCW/default.aspx?cite=46.44.091">RCW 46.44.091(3)</a>
						</p>
					</div>
				</div>
			</div>
		</template>
		<template #mobile>
			<v-icon-button class="v-button v-button-primary" @click="openFilters" iconName="filter" buttonLabel="Filter Truck Restrictions" iconPosition="Right" />
			<v-button class="v-button v-button-small v-button-secondary" @click="openBridgeKey" buttonLabel="Bridge weight key" />
		</template>
		<template #tags>
			<template v-for="[key, value] in appliedTypeFilters" :key="key">
				<v-tag @close="typeClose(key)" :title="value.substring(0, value.lastIndexOf(' '))" />
			</template>
			<template v-for="[key, value] in appliedDirectionFilters" :key="key">
				<v-tag @close="directionClose(key)" :title="value.substring(0, value.lastIndexOf(' '))" />
			</template>
			<template v-if="appliedTypeFilters.size > 0 || appliedDirectionFilters.size > 0">
				<div class="tag-list-clearall v-tag" role="button" @keyup.enter="onClearClick" @click="onClearClick" tabindex="0">Clear All</div>
			</template>
		</template>
		<div class="result-header" id="restriction-results" tabindex="-1">
			<div class="result-count" v-if="totalRestrictions > 0" role="heading" aria-level="3">
				{{ totalRestrictions.toLocaleString('en-US') }} 
				<template v-if="totalRestrictions === 1">
					result
				</template>
				<template v-else>
					results
				</template>
			</div>
			<div class="result-count" v-if="totalRestrictions === 0" role="heading" aria-level="3">
				0 restrictions found
			</div>
			<div class="result-sort" v-if="totalRestrictions > 0">
				<span>Sort by </span>
				<select class="form-control" v-model="selectedSort">
					<option value="added">Recently posted</option>
					<option value="updated">Recently updated</option>
				</select>
				<div class="sort-button" role="button" @click="onSortChange" @keyup.enter="onSortChange" tabindex="0">Sort</div>
			</div>
		</div>
		<restriction v-for="restriction in result" :key="restriction.uniqueId ?? ''" :restriction="restriction"></restriction>
		<VPager :pagesEachSide="2" :currentPage="currentPage" :totalPages="lastPage" @setPage="setPage" @prev="prev" @next="next" @applyPageSize="applyPageSize" :pageSize="pageSize" />
	</VFilterPage>
</template>

<script lang="ts">
	import { defineComponent, watch, computed, ref } from 'vue'
	import { useArrayPagination } from 'vue-composable'
	import Restriction from '@/components/TruckRestrictions/Restriction.vue'
	import VFilterPage from '@/components/UI/VFilterPage.vue'
	import VTag from '@/components/UI/VTag.vue'
	import VIconButton from '@/components/UI/VIconButton.vue'
	import VIcon from '@/components/UI/VIcon.vue'
	import VPager from '@/components/UI/VPager.vue'
	import VButton from '@/components/UI/VButton.vue'
	import { useRestrictionFilter } from '@/composables/restrictionFilters'

	export default defineComponent({
		name: 'TruckRestrictions',
		components: {
			VFilterPage,
			Restriction,
			VPager,
			VButton,
			VTag,
			VIconButton,
			VIcon
		},
		setup() {
			const { restrictions, loaded, appliedTypeFilters, typeFilters, appliedDirectionFilters, directionFilters, selectedSort, fetchFilteredRestrictions } = useRestrictionFilter()
			const checkedTypes = ref([] as string[])
			const checkedDirections = ref([] as string[])

			const defaultPageSize = 10

			const { result, next, prev, currentPage, lastPage, pageSize } = useArrayPagination(
				restrictions,
				{
					currentPage: 1
				}
			)

			pageSize.value = defaultPageSize

			const applyPageSize = (newSize: number) => {
				pageSize.value = newSize
				currentPage.value = 1
			}

			const setPage = (index: number) => {
				currentPage.value = index
			}

			const applyFilters = () => {
				closeFilters()
				appliedTypeFilters.value.clear()
				checkedTypes.value.forEach(x => {
					if (typeFilters.value.has(x)) {
						appliedTypeFilters.value.set(x, typeFilters.value.get(x) ?? '')
					}
				})

				appliedDirectionFilters.value.clear()
				checkedDirections.value.forEach(x => {
					if (directionFilters.value.has(x)) {
						appliedDirectionFilters.value.set(x, directionFilters.value.get(x) ?? '')
					}
				})

				fetchFilteredRestrictions()
			}

			const typeClose = (value: string) => {
				const index = checkedTypes.value.indexOf(value, 0)
				if (index > -1) {
					checkedTypes.value.splice(index, 1)
				}
				applyFilters()
			}

			const directionClose = (value: string) => {
				const index = checkedDirections.value.indexOf(value, 0)
				if (index > -1) {
					checkedDirections.value.splice(index, 1)
				}
				applyFilters()
			}

			const onSortChange = () => {
				applyFilters()
			}

			watch(restrictions, (newValue, oldValue) => {
				if (newValue !== oldValue) {
					currentPage.value = 1
				}
			})

			const totalRestrictions = computed(() => {
				return restrictions.value.length
			})

			const closeFilters = () => {
				document.getElementById('filterPanel')?.classList.remove('active')
				document.removeEventListener('keydown', handleFilterEscape, false)
			}

			const handleFilterEscape = (e: KeyboardEvent) => {
				if (e.key === 'Escape') {
					closeFilters()
				}
			}

			const openFilters = () => {
				document.getElementById('filterPanel')?.classList.add('active')
				document.addEventListener('keydown', handleFilterEscape, false)
			}

			const closeBridgeKey = () => {
				document.getElementById('bridgekey')?.classList.remove('active')
				document.removeEventListener('keydown', handleBridgeKeyEscape, false)
			}

			const handleBridgeKeyEscape = (e: KeyboardEvent) => {
				if (e.key === 'Escape') {
					closeBridgeKey()
				}
			}

			const openBridgeKey = () => {
				document.getElementById('bridgekey')?.classList.add('active')
				document.addEventListener("keydown", handleBridgeKeyEscape, false)
			}

			const resultsFocus = (elid: string) => {
				document.getElementById(elid)?.focus()
			}

			const onClearClick = () => {
				checkedTypes.value = []
				checkedDirections.value = []
				applyFilters();
			}

			return {
				result,
				currentPage,
				lastPage,
				pageSize,
				applyPageSize,
				next,
				prev,
				setPage,
				totalRestrictions,
				applyFilters,
				appliedDirectionFilters,
				appliedTypeFilters,
				typeFilters,
				directionFilters,
				checkedTypes,
				checkedDirections,
				typeClose,
				directionClose,
				onSortChange,
				selectedSort,
				//pagesToShow,
				openFilters,
				closeFilters,
				openBridgeKey,
				closeBridgeKey,
				resultsFocus,
				onClearClick,
				loaded
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
