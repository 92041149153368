<template>
	<div class="ad-banner">
		<div :id='slotId' class="ad-slot">
		</div>
		<div class="ad-link"><a href="https://wsdot.wa.gov/business-wsdot/website-advertising">Advertising</a></div>
	</div>
</template>
<script lang="ts">
	import { defineComponent, PropType, onMounted, nextTick, ref, watch } from 'vue'
	import { useRoute, onBeforeRouteLeave } from 'vue-router'
	
	declare global {
		interface Window { googletag: googletag.Googletag }
	}

	let slot: googletag.Slot

	window.googletag = window.googletag || { cmd: [] as googletag.CommandArray }

	export default defineComponent({
		name: 'AdSlot',
		props: {
			adUnit: {
				type: String,
				required: true
			},
			size: {
				type: Object as PropType<googletag.MultiSize>,
				default: () => {
					return [[728, 90], [320, 50]] as googletag.MultiSize
				}
			},
			sizeMapping: {
				type: Object as PropType<[googletag.SingleSizeArray, googletag.GeneralSize][]>,
				default: () => {
					return [
						[
							[1000, 200] as googletag.SingleSize,
							[728, 90] as googletag.GeneralSize,
						], [
							[730, 200] as googletag.SingleSize,
							[728, 90] as googletag.SingleSize,
						], [
							[320, 200] as googletag.SingleSize,
							[
								[320, 50] as googletag.SingleSize,
								[300, 50] as googletag.SingleSize,
							] as googletag.MultiSize
						]
					]
				}
			}
		},
		setup(props) {
			const route = useRoute()
			const slotId = ref('')
			let tag = window.googletag || { cmd: [] as googletag.CommandArray }

			let polling: number | undefined

			const setupSlots = () => {
				tag.destroySlots()
				nextTick(() => {
					tag.cmd.push(() => {
						let mapping = tag.sizeMapping()
						
						props.sizeMapping.forEach(size => {
							mapping.addSize(size[0], size[1])
						})
						const mappingBuilt = mapping.build()

						// Define the GPT slot
						slot = tag.defineSlot(props.adUnit, props.size, props.adUnit)
							.addService(tag.pubads())
							.defineSizeMapping(mappingBuilt)

						setTargeting()

						slotId.value = props.adUnit
						tag.pubads().enableSingleRequest()
						tag.pubads().collapseEmptyDivs()

						// Start ad fetching
						tag.enableServices()
						nextTick(() => {
							tag.cmd.push(() => {
								tag.display(slot.getSlotElementId())
							})
						})
					})
				})
			}

			const setTargeting = async () => {
				if (slot) {
					slot.clearTargeting()
					let page_ts = document.location.pathname.split('/');
					let k1 = page_ts[1] || 'none';
					let k2 = page_ts[2] || 'none';
					let k3 = page_ts[3] || 'none';
					let k4 = page_ts[4] || 'none';
					let k5 = page_ts[5] || 'none';

					slot
						.setTargeting('key1', k1)
						.setTargeting('key2', k2)
						.setTargeting('key3', k3)
						.setTargeting('key4', k4)
						.setTargeting('key5', k5)
				}
			}

			const checkTag = () => {
				nextTick(() => {
					tag = window.googletag || { cmd: [] as googletag.CommandArray }
					if (tag && typeof tag.sizeMapping === 'function') {
						clearInterval(polling)
						polling = undefined
						setupSlots()
					}
				})
			}

			onMounted(() => {
				if (polling === undefined) {
					polling = setInterval(() => {
						checkTag()
					}, 500)	
				}
			})

			onBeforeRouteLeave(() => {
				if (tag && typeof tag.destroySlots === 'function') {
					tag.destroySlots()
				}
			})

			watch(() => route.path, (pathCurr, pathPrev) => {
				tag = window.googletag || { cmd: [] as googletag.CommandArray }
				setTargeting().then(() => {
					tag.pubads().refresh()
				})
			})

			return {
				slotId
			}
		}
	})

</script>
<style lang="scss">
	.ad-banner {
		display: flex;
		justify-content: center;
		max-width: 728px;
		margin: 16px auto 24px auto;
		flex-direction: column;

		@include sm {
			max-width: 320px;
		}

		.ad-slot {
			min-width: 320px;
			min-height: 50px;
			max-width: 728px;
			max-height: 90px;
			display: flex;

			@include sm {
				min-width: 320px;
				min-height: 50px;
				max-width: 320px;
				max-height: 50px;
				overflow: hidden;
			}
		}

		.ad-link {
			display: flex;
			justify-content: flex-end;
			align-content: flex-end;

			a {
				font-size: 12px;
				line-height: 14px;
				color: $PrimaryBrand;

				&:hover {
					border-bottom: 1px solid $White;
				}
			}
		}
	}
</style>