<template>
	<div class="full-width">
	<ol class="breadcrumb" role="navigation" aria-label="breadcrumb">
		<li><a href="https://wsdot.wa.gov/">Home</a></li>
		<li><a href="https://wsdot.wa.gov/travel">Travel</a></li>
		<li v-for="breadcrumb in breadcrumbs" :key="breadcrumb.title">
			<template v-if="breadcrumb.routeName">
				<router-link :to="{ name: breadcrumb.routeName }">{{ breadcrumb.title }}</router-link>
			</template>
			<template v-else>
				{{ breadcrumb.title }}
			</template>
		</li>
		<li>{{ pageTitle }}</li>
	</ol>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'
	import { useNavigation } from '@/composables/navigation'

	const state = {
		locked: false
	}

	export default defineComponent({
		name: 'Breadcrumbs',
		setup() {
			const { pageTitle, breadcrumbs } = useNavigation()

			return {
				pageTitle,
				breadcrumbs
			}
		}
	})
</script>

<style scoped lang="scss">
	.full-width .breadcrumb {
		padding: 8px 15px 8px 0;
		margin-bottom: 0;
		a {
			text-decoration: none;
			color: $PrimaryBrand;
		}
	}
	.content-outer-wrapper {
		border-bottom: 1px solid #D2D3D5;
		background: #F4F4F5;
		width: 100%
	}
</style>
