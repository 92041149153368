<template>
	<svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M13.4142 1.41422C12.6332 0.633168 11.3668 0.633168 10.5858 1.41422L1.41422 10.5858C0.633168 11.3668 0.633168 12.6332 1.41422 13.4142L10.5858 22.5858C11.3668 23.3668 12.6332 23.3668 13.4142 22.5858L22.5858 13.4142C23.3668 12.6332 23.3668 11.3668 22.5858 10.5858L13.4142 1.41422ZM7 13.5H17V10.5H7V13.5Z" fill="#1D252D" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'ClosedIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
