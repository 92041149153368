import { inject, reactive, Ref, toRefs, onBeforeUnmount } from 'vue'
import { useStorage } from 'vue-composable'
import { ActiveVehicleRestrictionsMapService, Api } from '@/api/travelServiceApi'
import { ITruckRestrictionList } from '@/interfaces/ITruckRestrictionList'

const state = reactive({
	restrictions: [],
	loaded: false
} as ITruckRestrictionList)

const internalState = {
	lastUpdated: null as Date | null,
	promise: null as Promise<void> | null,
	polling: undefined as number | undefined
}

export default function useTruckRestrictions(): {
	restrictions: Ref<ActiveVehicleRestrictionsMapService[]>
	loaded: Ref<boolean>
	fetchTruckRestrictions: () => Promise<void>
} {
	const MAX_CACHE_MIN = 5
	const TRAFFIC_API = inject('$TRAFFIC_API') as string
	const travelService = new Api({ baseUrl: TRAFFIC_API })
	const key = '__wsdot_restrictions'
	const { supported, storage, remove } = useStorage(key, { lastUpdated: new Date(), version: import.meta.env.VITE_BUILD_VERSION, restrictions: [] as ActiveVehicleRestrictionsMapService[] }, true)

	if (supported && storage.value.lastUpdated === null || storage.value.version !== import.meta.env.VITE_BUILD_VERSION) {
		remove()
		storage.value.version = import.meta.env.VITE_BUILD_VERSION
		storage.value.restrictions = []
		storage.value.lastUpdated = new Date()
	}

	// Poll for changes every 10 minutes
	if (!internalState.polling) {
		internalState.polling = window.setInterval(() => {
			checkForUpdates()
		}, 60000 * MAX_CACHE_MIN)
	}

	const setFromStorage = (): boolean => {
		if (supported) {
			if (storage.value.version === import.meta.env.VITE_BUILD_VERSION) {
				if (
					storage.value.restrictions.length > 0
					&& Math.abs(new Date().getTime() - new Date(storage.value.lastUpdated).getTime()) / 60000 <= MAX_CACHE_MIN
				) {
					state.restrictions = storage.value.restrictions
					internalState.lastUpdated = storage.value.lastUpdated
					return true
				}
			}
			remove()
		}
		return false
	}

	const fetchTruckRestrictions = async (force = false): Promise<void> => {
		if (internalState.promise) {
			return internalState.promise
		}
		if (force || !setFromStorage()) {
			state.loaded = false
			internalState.promise = travelService.api.commercialVehiclesList().then((response) => {
				state.restrictions = response.data
				internalState.lastUpdated = new Date()
				if (supported) {
					storage.value.version = import.meta.env.VITE_BUILD_VERSION
					storage.value.restrictions = state.restrictions
					storage.value.lastUpdated = internalState.lastUpdated
				}
				state.loaded = true
			})
			.finally(() => {
				internalState.promise = null
			})
			return internalState.promise
		}
	}

	const checkForUpdates = async () => {
		const lastUpdateResp = await travelService.api.commercialVehiclesGetLastUpdateList()
		const newDate = new Date(lastUpdateResp.data)
		if (!internalState.lastUpdated || internalState.lastUpdated < newDate) {
			refreshRestrictions()
		}
	}

	const refreshRestrictions = async () => {
		fetchTruckRestrictions(true)
	}

	if (state.restrictions.length === 0) {
		fetchTruckRestrictions()
	}

	onBeforeUnmount(() => {
		if (internalState.polling) {
			clearInterval(internalState.polling)
		}
	})

	return {
		...toRefs(state),
		fetchTruckRestrictions
	}
}