<template>
	<svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.00002 7.99935C9.47335 7.99935 10.6667 6.80602 10.6667 5.33268C10.6667 3.85935 9.47335 2.66602 8.00002 2.66602C6.52669 2.66602 5.33335 3.85935 5.33335 5.33268C5.33335 6.80602 6.52669 7.99935 8.00002 7.99935ZM8.00002 9.33268C6.22002 9.33268 2.66669 10.226 2.66669 11.9993V13.3327H13.3334V11.9993C13.3334 10.226 9.78002 9.33268 8.00002 9.33268Z" fill="#1D252D" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'PedestrianIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
