<template>
	<VFilterPage :loaded="loaded" :TwoColumn="false" pageTitle="Mountain pass reports">
		<template #tags>
		</template>
		<div class="result-header">
			<div class="result-count" role="heading" aria-level="3">
				{{ reports.length }}
				<template v-if="reports.length === 1">
					result
				</template>
				<template v-if="reports.length === 0 || reports.length > 1">
					results
				</template>
			</div>
		</div>
		<pass-report-card v-for="(report) in reports" :key="report.mountainPass?.mountainPassId" :passReport="report"></pass-report-card>
	</VFilterPage>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'
	import PassReportCard from '@/components/MountainPasses/PassReportCard.vue'
	import VFilterPage from '@/components/UI/VFilterPage.vue'
	import { usePassReportFilters } from '@/composables/passReportFilters'

	export default defineComponent({
		name: 'PassReports',
		components: {
			VFilterPage,
			PassReportCard
		},
		setup() {
			const { reports, loaded } = usePassReportFilters()

			return {
				reports,
				loaded
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
