<template>
	<svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clipCar)">
			<path d="M20.238 4.36539C20.0005 3.66477 19.3355 3.16602 18.5518 3.16602H5.48926C4.70551 3.16602 4.05238 3.66477 3.80301 4.36539L1.33301 11.4785V20.9785C1.33301 21.6316 1.86738 22.166 2.52051 22.166H3.70801C4.36113 22.166 4.89551 21.6316 4.89551 20.9785V19.791H19.1455V20.9785C19.1455 21.6316 19.6799 22.166 20.333 22.166H21.5205C22.1736 22.166 22.708 21.6316 22.708 20.9785V11.4785L20.238 4.36539ZM5.48926 16.2285C4.50363 16.2285 3.70801 15.4329 3.70801 14.4473C3.70801 13.4616 4.50363 12.666 5.48926 12.666C6.47488 12.666 7.27051 13.4616 7.27051 14.4473C7.27051 15.4329 6.47488 16.2285 5.48926 16.2285ZM18.5518 16.2285C17.5661 16.2285 16.7705 15.4329 16.7705 14.4473C16.7705 13.4616 17.5661 12.666 18.5518 12.666C19.5374 12.666 20.333 13.4616 20.333 14.4473C20.333 15.4329 19.5374 16.2285 18.5518 16.2285ZM3.70801 10.291L5.48926 4.94727H18.5518L20.333 10.291H3.70801Z" />
		</g>
		<defs>
			<clipPath id="clipCar">
				<rect width="24" height="24" transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'CarIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
