import { reactive, Ref, toRefs } from 'vue'
import { CameraListModel } from '@/api/travelServiceApi'
import { useRoutePicker } from '@/store/routePicker'
import useStateRoutes from '@/store/stateRoutes'
import useFerryRoutes from '@/store/ferryRoutes'
import useCameras from '@/store/cameras'
import usePassReports from '@/store/passReports'

const state = reactive({
	cameras: [] as CameraListModel[],
	baseCameraCount: 0,
	filteredCamerasCount: 0,
	categoryFilters: new Map<string, string>(),
	appliedCategoryFilters: new Map<string, string>(),
	loaded: false
})

const internalState = {
	updating: false,
	initialized: false
}

export function useCameraFilters(): {
	cameras: Ref<CameraListModel[]>
	baseCameraCount: Ref<number>
	filteredCamerasCount: Ref<number>
	categoryFilters: Ref<Map<string, string>>
	appliedCategoryFilters: Ref<Map<string, string>>
	loaded: Ref<boolean>,
	fetchFilteredCameras: () => Promise<void>,
	resetFilters: () => Promise<void>
} {
	let first = false
	if (!internalState.initialized) {
		internalState.initialized = true
		first = true
	}
	const { cameras, fetchCameras } = useCameras()
	const { stateRoutes, fetchStateRoutes } = useStateRoutes()
	const { ferryRoutes } = useFerryRoutes()
	const { reports, fetchPassReports } = usePassReports()
	const routePicker = useRoutePicker()
	let distinctRoutes: string[] = []
	let mountainPassCameras: number[] = []

	fetchStateRoutes().then(() => {
		distinctRoutes = stateRoutes.value.map(sr => sr.stateRouteId ?? '')
	})
	fetchPassReports().then(() => {
		mountainPassCameras = reports.value.flatMap(p => p.mountainPass?.cameras?.map(v => v) ?? [])
	})

	const filterByCategory = (cams: CameraListModel[]): CameraListModel[] => {
		if (state.appliedCategoryFilters.size > 0) {
			return cams.filter(cam =>
				cam.cameraId
				&& (
					state.appliedCategoryFilters.has(cam.stateRouteId ?? '')
					|| (
						state.appliedCategoryFilters.has('Other')
						&& !distinctRoutes.includes(cam.stateRouteId ?? '')
						&& ![...state.categoryFilters.keys()].includes(cam.stateRouteId ?? '')
					)
					|| (
						state.appliedCategoryFilters.has('Highways')
						&& distinctRoutes.includes(cam.stateRouteId?.substr(0, 3) ?? '')
					)
					|| (
						state.appliedCategoryFilters.has('Mountain Passes')
						&& mountainPassCameras.includes(cam.cameraId)
					)
				)
			)
		}
		return cams
	}

	const filterByRoute = (cams: CameraListModel[]): CameraListModel[] => {
		if (routePicker.routeType.value === 'road') {
			const srId = routePicker.vehicleRoute.value
			if (srId) {
				return cams.filter(cam => srId.startsWith(cam.stateRouteId ?? ''))
			}
		} else if (routePicker.routeType.value === 'ferry') {
			const ferryRoute = ferryRoutes.value.find(r => (r.routeId ?? 0) === routePicker.ferryRoute.value)
			if (ferryRoute) {
				return cams.filter(cam => ferryRoute.cameraIds?.includes(cam.cameraId ?? 0))
			}
		}
		return cams
	}

	const filterByMilepost = (cams: CameraListModel[]): CameraListModel[] => {
		if (routePicker.routeType.value === 'road') {
			if (routePicker.hasMileposts.value) {
				return cams.filter(cam =>
					!cam.milePost
					|| (
						cam.milePost <= routePicker.highMp.value
						&& cam.milePost >= routePicker.lowMp.value
					)
				)
			}
		}
		return cams
	}

	const sortedCameras = (cams: CameraListModel[]): CameraListModel[] => {
		if (routePicker.routeType.value === 'road' && routePicker.hasLocation.value) {
			if (routePicker.startLocation.value?.startMp && routePicker.endLocation.value?.startMp) {
				if (routePicker.startLocation.value?.startMp > routePicker.endLocation.value?.startMp) {
					return cams.reverse()
				}
			}
		}
		
		return cams
	}

	const filteredCameras = async (): Promise<CameraListModel[]> => {
		state.loaded = false
		return fetchCameras().then(() => {
			const result = filterByMilepost(
				filterByRoute(cameras.value)
			)

			const highwayCount = result.filter((obj) => distinctRoutes.includes(obj.stateRouteId ?? '')).length
			const airportCount = result.filter((obj) => obj.stateRouteId === 'Airports').length
			const cityCount = result.filter((obj) => obj.stateRouteId === 'City & County Cams').length
			const ferryCount = result.filter((obj) => obj.stateRouteId === 'Ferries').length
			const passCount = result.filter((obj) => obj.cameraId && mountainPassCameras.includes(obj.cameraId)).length
			const otherCount = result.filter((obj) => !distinctRoutes.includes(obj.stateRouteId ?? '') && ![...state.categoryFilters.keys()].includes(obj.stateRouteId ?? '')).length

			state.categoryFilters.set('Highways', `Highways (${highwayCount})`)
			state.categoryFilters.set('Airports', `Airports (${airportCount})`)
			state.categoryFilters.set('City & County Cams', `City and County (${cityCount})`)
			state.categoryFilters.set('Ferries', `Ferries (${ferryCount})`)
			state.categoryFilters.set('Mountain Passes', `Mountain Passes (${passCount})`)
			state.categoryFilters.set('Other', `Other (${otherCount})`)

			state.baseCameraCount = cameras.value.length

			const finalResult = sortedCameras(
				filterByCategory(result)
			)

			state.loaded = cameras.value.length > 0
			state.filteredCamerasCount = finalResult.length
			return finalResult
		})
	}

	const updateResult = async () => {
		if (!internalState.updating) {
			internalState.updating = true
			state.cameras = await filteredCameras()
			internalState.updating = false
		}
	}

	const fetchFilteredCameras = async () => {
		return updateResult()
	}

	const resetFilters = async () => {
		state.appliedCategoryFilters = new Map<string, string>()
		return updateResult()
	}

	if (first) {
		if (!state.loaded && !internalState.updating) {
			updateResult()
		}
	}

	return {
		...toRefs(state),
		fetchFilteredCameras,
		resetFilters
	}
}