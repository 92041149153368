<template>
	<div class="v-button" @keyup.enter="onClick" @click="onClick" role="button">
		<slot></slot>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'BaseButton',
		emits: ['click'],
		setup(_, { emit }) {
			const onClick = () => {
				emit('click')
			}

			return {
				onClick
			}
		}
	})
</script>

<style lang="scss">
	$White: #FFF;
	$primary: #007B5F;
	$active: #005151;
	$disabled: #777C81;
	$grey: #D2D3D5;

	.v-button {
		box-sizing: border-box;
		height: 48px;
		position: relative;
		display: inline-flex;
		margin: 0 20px;
		padding: 14px 14px;
		font-size: 16px;
		text-align: center;
		text-decoration: none;
		line-height: 20px;
		color: $White;
		fill: $White;
		border: 1px solid $primary;
		z-index: 0;
		border-width: 1px 0;
		flex-direction: row;

		> span {
			vertical-align: middle;
			display: flex;
			align-self: center;
			margin: 0 auto;
		}

		> svg.icon-left {
			margin-right: 8px;
			vertical-align: middle;
			display: flex;
		}

		> svg.icon-right {
			margin-left: 8px;
			vertical-align: middle;
			display: flex;
		}

		&.v-button-primary {
			background: $primary;
		}

		&.v-button-secondary {
			background: #FFF;
			color: $primary;
			fill: $primary;
		}

		&:hover {
			cursor: pointer;
		}

		&:before, &:after {
			box-sizing: content-box;
			position: absolute;
			z-index: -1;
			bottom: -1px;
			top: -1px;
			border-width: 1px;
			border-style: solid;
			border-color: $primary;
			width: 100%;
			height: 100%;
			content: '';
			transform: skew(-7deg);
			background: inherit;

			@include sm {
				top: -2px;
				bottom: -3px;
				height: unset;
			}
		}

		&:before {
			left: -16px;
			border-bottom-left-radius: 4px;
			border-top-left-radius: 4px;
			border-right: none;
		}

		&:after {
			right: -16px;
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;
			border-left: none;
		}

		&.v-button-small {
			height: 32px;
			margin: 0 14px;
			padding: 6px 10px;
			font-size: 16px;
			line-height: 16px;
		}

		&.v-button-small:before {
			left: -5px;
		}

		&.v-button-small:after {
			right: -5px;
		}

		&.v-button-center {
			display: flex;
			align-self: center;
			margin: 0 auto;
		}
	}
</style>
