import { inject, reactive, Ref, toRefs, onBeforeUnmount } from 'vue'
import { useStorage } from 'vue-composable'
import { AlertsModel, FerryRouteAlert, Api } from '@/api/travelServiceApi'
import { IAlert } from '@/interfaces/IAlert'
import { ITravelAlertList } from '@/interfaces/ITravelAlertList'

const state = reactive({
	alerts: [],
	loaded: false
} as ITravelAlertList)

const internalState = {
	lastUpdated: null as Date | null,
	promise: null as Promise<void> | null,
	polling: undefined as number | undefined
}

export default function useTravelAlerts(): {
	alerts: Ref<IAlert[]>
	loaded: Ref<boolean>
	fetchTravelAlerts: () => Promise<void>
} {
	const MAX_CACHE_MIN = 2
	const TRAFFIC_API = inject('$TRAFFIC_API') as string
	const travelService = new Api({ baseUrl: TRAFFIC_API })
	const key = '__wsdot_alerts'
	const { supported, storage, remove } = useStorage(key, { lastUpdated: new Date(), version: import.meta.env.VITE_BUILD_VERSION, alerts: [] as IAlert[] }, true)

	if (supported && storage.value.lastUpdated === null) {
		remove()
		storage.value.version = import.meta.env.VITE_BUILD_VERSION
		storage.value.alerts = []
		storage.value.lastUpdated = new Date()
	}

	// Poll for changes every 10 minutes
	if (!internalState.polling) {
		internalState.polling = window.setInterval(() => {
			checkForUpdates()
		}, 60000 * MAX_CACHE_MIN)
	}

	const setFromStorage = (): boolean => {
		if (supported) {
			if (storage.value.version === import.meta.env.VITE_BUILD_VERSION) {
				if (
					storage.value.alerts.length > 0
					&& Math.abs(new Date().getTime() - new Date(storage.value.lastUpdated).getTime()) / 60000 <= MAX_CACHE_MIN
				) {
					state.alerts = storage.value.alerts
					internalState.lastUpdated = storage.value.lastUpdated
					return true
				}
			}
			remove()
		}
		return false
	}

	const fetchTravelAlerts = async (force = false): Promise<void> => {
		if (internalState.promise) {
			return internalState.promise
		}
		if (force || !setFromStorage()) {
			state.loaded = false
			let newAlerts = [] as IAlert[]

			const promiseAlerts = travelService.api.alertsList().then((alerts) => {
				alerts.data.forEach((alert: AlertsModel) => {
					newAlerts.push({
						alertType: 'Travel',
						alertId: alert.eventId ?? 0,
						sortOrder: alert.displayOrder ?? 0,
						startMilepost: alert.startMilepost ?? null,
						endMilepost: alert.endMilepost ?? alert.startMilepost ?? null,
						headlineMessage: alert.headlineMessage ?? '',
						extendedMessage: alert.extendedMessage ?? null,
						travelCenterPriorityId: alert.travelCenterPriorityId ?? 4,
						lastModifiedDate: alert.lastModifiedDate ?? null,
						locationName: alert.roadName ?? '',
						roadDirection: alert.roadDirection ?? null,
						eventCategoryDescription: alert.eventCategoryDescription ?? null,
						eventCategoryTypeDescription: alert.eventCategoryTypeDescription ?? null,
						eventCategoryType: alert.eventCategoryType ?? null,
						mapLinkText: alert.roadName ? alert.roadName + ((alert.startMilepost) ? " milepost " + alert.startMilepost + ((alert.endMilepost) ? " to " + alert.endMilepost : "") : "") : null,
						alertTitle: alert.roadName ? alert.roadName + ((alert.startMilepost) ? " milepost " + alert.startMilepost + ((alert.endMilepost) ? " to " + alert.endMilepost : "") : "") : (alert.eventPriorityDescription ?? ''),
						locationId: alert.stateRouteId ?? '',
						group: (alert.eventCategoryType === 'Statewide' || alert.eventCategoryType === 'County' || alert.eventCategoryType === 'Region' ? 'Statewide' : (alert.locationName === 'Local') ? 'Regional' : 'Travel' )
					} as IAlert)
				})

				newAlerts.forEach(alert => {
					if (alert.group === 'Regional') {
						alert.locationId = '090'
						alert.startMilepost = 277.89
						alert.endMilepost = 297.3
					}
				})
			})
			const promiseFerry = travelService.api.getFerryRouteAlertsList().then((ferries) => {
				ferries.data.forEach((ferry: FerryRouteAlert) => {
					newAlerts.push({
						alertType: 'Ferry',
						alertId: ferry.bulletinID ?? 0,
						sortOrder: ferry.sortOrder ?? 0,
						startMilepost: null,
						endMilepost: null,
						headlineMessage: ferry.textHtml ?? '',
						extendedMessage: null,
						travelCenterPriorityId: 4,
						lastModifiedDate: ferry.publishDate ?? null,
						locationName: ferry.title,
						roadDirection: null,
						eventCategoryTypeDescription: 'Ferries',
						mapLinkText: null,
						alertTitle: ferry.title ?? '',
						locationId: '',
						allRoutes: ferry.allRoutesFlag ?? false,
						ferryRoutes: ferry.affectedRoutes || null,
						group: 'Ferry'
					} as IAlert)
				})
			})
			internalState.promise = Promise.all([promiseAlerts, promiseFerry]).then(() => {
				state.alerts = newAlerts
				internalState.lastUpdated = new Date()
				if (supported) {
					storage.value.version = import.meta.env.VITE_BUILD_VERSION
					storage.value.alerts = state.alerts
					storage.value.lastUpdated = internalState.lastUpdated
				}
				state.loaded = true
			})
			.finally(() => {
				internalState.promise = null
			})

			return internalState.promise
		}
	}

	const checkForUpdates = async () => {
		const lastUpdateResp = await travelService.api.alertsGetLastUpdateList()
		const ferryLastUpdateResp = await travelService.api.getFerryAlertsLastUpdateList()
		const newDate = new Date(lastUpdateResp.data)
		const newDateFerry = new Date(ferryLastUpdateResp.data)
		if (!internalState.lastUpdated || internalState.lastUpdated < newDate || internalState.lastUpdated < newDateFerry) {
			refreshTravelAlerts()
		}
	}

	const refreshTravelAlerts = async () => {
		fetchTravelAlerts(true)
	}

	if (state.alerts.length === 0) {
		fetchTravelAlerts()
	}

	onBeforeUnmount(() => {
		if (internalState.polling) {
			clearInterval(internalState.polling)
		}
	})

	return {
		...toRefs(state),
		fetchTravelAlerts
	}
}
