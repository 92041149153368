import { reactive, Ref, toRefs } from 'vue'
import { IAlert } from '@/interfaces/IAlert'
import { useRoutePicker } from '@/store/routePicker'
import useTravelAlerts from '@/store/travelAlerts'

const state = reactive({
	alerts: [] as IAlert[],
	baseAlertCount: 0,
	filteredAlertsCount: 0,
	appliedPriorityFilters: new Map<number, string>(),
	appliedCategoryFilters: new Map<string, string>(),
	appliedDirectionFilters: new Map<string, string>(),
	priorityFilters: new Map<number, string>(),
	categoryFilters: new Map<string, string>(),
	directionFilters: new Map<string, string>(),
	selectedSort: 'level',
	loaded: false
})

const internalState = {
	updating: false,
	initialized: false
}

export function useAlertFilters(): {
	alerts: Ref<IAlert[]>
	baseAlertCount: Ref<number>
	filteredAlertsCount: Ref<number>
	appliedPriorityFilters: Ref<Map<number, string>>
	appliedCategoryFilters: Ref<Map<string, string>>
	appliedDirectionFilters: Ref<Map<string, string>>
	priorityFilters: Ref<Map<number, string>>
	categoryFilters: Ref<Map<string, string>>,
	directionFilters: Ref<Map<string, string>>
	selectedSort: Ref<string>
	loaded: Ref<boolean>
	fetchFilteredAlerts: () => Promise<void>
	resetFilters: () => Promise<void>
} {
	let first = false
	if (!internalState.initialized) {
		internalState.initialized = true
		first = true
	}
	const { alerts, fetchTravelAlerts } = useTravelAlerts()
	const routePicker = useRoutePicker()

	const filterByRoute = (talert: IAlert[]) => {
		if (routePicker.routeType.value === 'road') {
			const srId = routePicker.vehicleRoute.value
			if (srId) {
				return talert.filter(alert => alert.group === 'Statewide' || alert.alertType === 'Travel' && alert.locationId === '' || alert.locationId === srId)
			}
		} else if (routePicker.routeType.value === 'ferry') {
			const ferryRoute = routePicker.ferryRoute.value
			if (ferryRoute) {
				return talert.filter(alert => alert.group === 'Statewide' || alert.alertType === 'Ferry' && (alert.allRoutes === true || alert.ferryRoutes?.includes(ferryRoute)))
			}
		}
		return talert
	}

	const filterByMilepost = (talert: IAlert[]): IAlert[] => {
		if (routePicker.routeType.value === 'road') {
			if (routePicker.hasMileposts.value) {
				return talert.filter(alert =>
					alert.group === 'Statewide'
					|| alert.locationId === ''
					|| (
						(
							(alert.startMilepost ?? 0) <= routePicker.highMp.value
							&& (alert.endMilepost ?? 0) >= routePicker.lowMp.value
						)
						|| (
							(alert.endMilepost ?? 0) <= routePicker.highMp.value
							&& (alert.startMilepost ?? 0) >= routePicker.lowMp.value
						)
					)
				)
			}
		}
		return talert
	}

	const filterPriorities = (talert: IAlert[]): IAlert[] => {
		if (state.appliedPriorityFilters.size > 0) {
			return talert.filter(alert => alert.group === 'Statewide' || state.appliedPriorityFilters.has(alert.travelCenterPriorityId))
		}
		return talert
	}

	const filterCategories = (talert: IAlert[]): IAlert[] => {
			if (state.appliedCategoryFilters.size > 0) {
				return talert.filter(alert =>
					alert.group === 'Statewide'
					|| (
						alert.group === 'Regional'
						&& state.appliedCategoryFilters.has('Regional')
					)
					|| state.appliedCategoryFilters.has(alert.eventCategoryTypeDescription ?? '(blank)')
				)
			}
		return talert
	}

	const filterByDirection = (talert: IAlert[]): IAlert[] => {
		if (routePicker.routeType.value === 'road') {
			if (state.appliedDirectionFilters.size > 0) {
				let directions = [] as string[]
				if (state.appliedDirectionFilters.has('I')) {
					directions.push('Northbound')
					directions.push('Eastbound')
					directions.push('Both Directions')
				}
				if (state.appliedDirectionFilters.has('D')) {
					directions.push('Southbound')
					directions.push('Westbound')
					directions.push('Both Directions')
				}
				return talert.filter(alert => alert.group === 'Statewide' || directions.includes(alert.roadDirection ?? '(blank)'))
			}
		}
		return talert
	}

	const sortByDate = (talert: IAlert[]): IAlert[] => {
		return talert.slice().sort((a, b) => {
			if (a.lastModifiedDate === b.lastModifiedDate) {
				return 0
			} else {
				return (a.lastModifiedDate ?? '') > (b.lastModifiedDate ?? '') ? -1 : 1
			}
		})
	}

	const sortByAlertLevel = (talert: IAlert[]): IAlert[] => {
		return talert.slice().sort((a, b) => {
			if (a.travelCenterPriorityId === b.travelCenterPriorityId) {
				return 0
			} else {
				return a.travelCenterPriorityId > b.travelCenterPriorityId ? 1 : -1
			}
		})
	}

	const sortByMilepost = (talert: IAlert[]): IAlert[] => {
		if (routePicker.hasMileposts) {
			return talert.slice().sort((a, b) => {
				if ((routePicker.startLocation.value?.startMp ?? 0) > (routePicker.endLocation.value?.startMp ?? 0))
				{
					if (a.startMilepost === b.startMilepost) {
						return 0
					}
					return (a.startMilepost ?? 0) > (b.startMilepost ?? 0) ? -1 : 1
				}
				else {
					if (a.startMilepost === b.startMilepost) {
						return 0
					}
					return (a.startMilepost ?? 0) > (b.startMilepost ?? 0) ? 1 : -1
				}
			})
		}
		return talert
	}

	const sortAlerts = (talert: IAlert[]): IAlert[] => {
		if (state.selectedSort === 'updated') {
			const baseAlerts = sortByDate(talert.filter(x => x.group !== 'Statewide' && x.group !== 'Regional'))
			const stateWideSorted = sortByDate(talert.filter(x => x.group === 'Statewide'))
			const regionalSorted = sortByDate(talert.filter(x => x.group === 'Regional'))
	
			return stateWideSorted
				.concat(baseAlerts)
				.concat(regionalSorted)
		} else {
			const baseAlerts = sortByAlertLevel(sortByMilepost(talert.filter(x => x.group !== 'Statewide' && x.group !== 'Regional')))
			const stateWideSorted = sortByDate(talert.filter(x => x.group === 'Statewide'))
			const regionalSorted = sortByDate(talert.filter(x => x.group === 'Regional'))
	
			return stateWideSorted
				.concat(baseAlerts)
				.concat(regionalSorted)
		}
	}

	const filteredAlerts = async (): Promise<IAlert[]> => {
		state.loaded = false
		return fetchTravelAlerts().then(() => {
			const localizedAlerts = alerts.value
			const result = filterByMilepost(
				filterByRoute(
					localizedAlerts
				)
			)

			const closedCount = result.filter((obj) => obj.travelCenterPriorityId === 1).length
			const highCount = result.filter((obj) => obj.travelCenterPriorityId === 2).length
			const mediumCount = result.filter((obj) => obj.travelCenterPriorityId === 3).length
			const lowCount = result.filter((obj) => obj.travelCenterPriorityId === 4).length
			state.priorityFilters.set(1, `Closed (${closedCount})`)
			state.priorityFilters.set(2, `High (${highCount})`)
			state.priorityFilters.set(3, `Medium (${mediumCount})`)
			state.priorityFilters.set(4, `Low (${lowCount})`)

			const incidentCount = result.filter((obj) => obj.eventCategoryTypeDescription === 'Incident').length
			const ferriesCount = result.filter((obj) => obj.eventCategoryTypeDescription === 'Ferries').length
			const weatherCount = result.filter((obj) => obj.eventCategoryTypeDescription === 'Weather').length
			const bridgeCount = result.filter((obj) => obj.eventCategoryTypeDescription === 'Bridge').length
			const policeCount = result.filter((obj) => obj.eventCategoryTypeDescription === 'Police activity').length
			const constructionCount = result.filter((obj) => obj.eventCategoryTypeDescription === 'Construction').length
			const maintenanceCount = result.filter((obj) => obj.eventCategoryTypeDescription === 'Maintenance').length
			const regionalCount = result.filter((obj) => obj.group === 'Regional').length

			state.categoryFilters.set('Incident', `Incident (${incidentCount})`)
			state.categoryFilters.set('Ferries', `Ferries (${ferriesCount})`)
			state.categoryFilters.set('Weather', `Weather (${weatherCount})`)
			state.categoryFilters.set('Bridge', `Bridge (${bridgeCount})`)
			state.categoryFilters.set('Police activity', `Police activity (${policeCount})`)
			state.categoryFilters.set('Construction', `Construction (${constructionCount})`)
			state.categoryFilters.set('Maintenance', `Maintenance (${maintenanceCount})`)
			state.categoryFilters.set('Regional', `Regional (${regionalCount})`)

			const increasingCount = result.filter((obj) => obj.roadDirection?.startsWith('North') || obj.roadDirection?.startsWith('East')).length
			const decreasingCount = result.filter((obj) => obj.roadDirection?.startsWith('South') || obj.roadDirection?.startsWith('West')).length
			const bothCount = result.filter((obj) => obj.roadDirection?.startsWith('Both')).length
			state.directionFilters.set('I', `Northbound/Eastbound (${increasingCount + bothCount})`)
			state.directionFilters.set('D', `Southbound/Westbound (${decreasingCount + bothCount})`)

			state.baseAlertCount = localizedAlerts.length

			const finalResult = sortAlerts(
				filterByDirection(
					filterPriorities(
						filterCategories(result)
					)
				)
			)
			state.loaded = alerts.value.length > 0
			state.filteredAlertsCount = finalResult.length
			return finalResult
		})
	}

	const updateResult = async () => {
		if (!internalState.updating) {
			internalState.updating = true
			state.alerts = await filteredAlerts()
			internalState.updating = false
		}
	}

	const fetchFilteredAlerts = async () => {
		return updateResult()
	}

	const resetFilters = async () => {
		state.appliedCategoryFilters = new Map<string, string>()
		state.appliedPriorityFilters = new Map<number, string>()
		state.appliedDirectionFilters = new Map<string, string>()
		return updateResult()
	}

	if (first) {
		if (!state.loaded && !internalState.updating) {
			updateResult()
		}
	}
	return {
		...toRefs(state),
		fetchFilteredAlerts,
		resetFilters
	}
}