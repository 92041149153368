<template>
	<svg :width="width" :height="height" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
		<path d="M18 9.12809C18 14.1694 13.9706 18.2562 9 18.2562C4.02944 18.2562 0 14.1694 0 9.12809C0 4.08679 4.02944 0 9 0C13.9706 0 18 4.08679 18 9.12809Z" fill="#FFC107" />
		<path d="M16.75 9C16.75 13.2802 13.2802 16.75 9 16.75C4.71979 16.75 1.25 13.2802 1.25 9C1.25 4.71979 4.71979 1.25 9 1.25C13.2802 1.25 16.75 4.71979 16.75 9Z" stroke="white" stroke-width="0.5" />
		<path d="M8.93103 10.911H8.98317C9.53545 10.911 9.98317 10.4633 9.98317 9.91098V4.79987C9.98317 4.24758 9.53545 3.79987 8.98317 3.79987H8.93103C8.37875 3.79987 7.93103 4.24758 7.93103 4.79987V9.91098C7.93103 10.4633 8.37874 10.911 8.93103 10.911Z" fill="white" />
		<path d="M8.1108 12.8863C7.99359 13.0533 7.93103 13.2497 7.93103 13.4507C7.93159 13.7199 8.04416 13.978 8.24407 14.1684C8.44399 14.3588 8.71497 14.466 8.9977 14.4665C9.20866 14.4665 9.41489 14.407 9.5903 14.2953C9.76572 14.1837 9.90243 14.025 9.98317 13.8394C10.0639 13.6538 10.085 13.4495 10.0439 13.2525C10.0027 13.0554 9.90112 12.8744 9.75194 12.7323C9.60277 12.5903 9.41271 12.4935 9.20579 12.4543C8.99888 12.4151 8.78441 12.4352 8.5895 12.5121C8.39459 12.589 8.228 12.7192 8.1108 12.8863Z" fill="white" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'AlertIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
