import { Api } from "@/api/travelServiceApi";
import { IDrupalAlertBanner, IAlertBannerInfo } from "@/interfaces/IDrupalAlertBanner";
import { Ref, inject, reactive, toRefs } from "vue";

const state = reactive({
	alertBanner: {},
	loaded: false
} as IDrupalAlertBanner)

const internalState = {
	lastUpdated: null as Date | null,
	promise: null as Promise<void> | null,
	polling: undefined as number | undefined
}

export default function useDrupalAlertBanner(): {
	alertBanner: Ref<IAlertBannerInfo>
	loaded: Ref<boolean>
	fetchAlertBanner: () => Promise<void>
} {
	const MAX_CACHE_MIN = 5
	const TRAFFIC_API = inject('$TRAFFIC_API') as string
	const travelService = new Api({ baseUrl: TRAFFIC_API })

	// Poll for changes every 5 minutes
	if (!internalState.polling) {
		internalState.polling = window.setInterval(() => {
			fetchAlertBanner()
		}, 60000 * MAX_CACHE_MIN)
	}

	const fetchAlertBanner = async () => {
		return travelService.api.getDrupalAlertEventStatusList().then((result: any) => {
			state.alertBanner = result.data; //It will override default value with API value.
			state.loaded = true
		}).catch((resp: any) => {
			console.log("getDrupalAlertEventStatus API call failed with error. " + "\n Error Message: " + resp.error.toString() + "\n Requested Url: " + resp.url.toString());
		});

	}

	fetchAlertBanner()

	return {
		...toRefs(state),
		fetchAlertBanner
	}
}