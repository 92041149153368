<template>
	<component :is="iconComponent" role="img" :width="width" :height="height" />
</template>

<script lang="ts">
	import { defineComponent, computed } from 'vue'
	import AircraftIcon from './Icons/AircraftIcon.vue'
	import AlertIcon from './Icons/AlertIcon.vue'
	import BorderCrossingIcon from './Icons/BorderCrossingIcon.vue'
	import BridgeRestrictionIcon from './Icons/BridgeRestrictionIcon.vue'
	import CameraIcon from './Icons/CameraIcon.vue'
	import CarIcon from './Icons/CarIcon.vue'
	import ClosedIcon from './Icons/ClosedIcon.vue'
	import CollisionIcon from './Icons/CollisionIcon.vue'
	import CyclistIcon from './Icons/CyclistIcon.vue'
	import EmergencyAlertIcon from './Icons/EmergencyAlertIcon.vue'
	import FerryIcon from './Icons/FerryIcon.vue'
	import FilterCloseIcon from './Icons/FilterCloseIcon.vue'
	import FilterIcon from './Icons/FilterIcon.vue'
	import MapIcon from './Icons/MapIcon.vue'
	import MountainPassIcon from './Icons/MountainPassIcon.vue'
	import PedestrianIcon from './Icons/PedestrianIcon.vue'
	import RoadRestrictonIcon from './Icons/RoadRestrictonIcon.vue'
	import TravelTimeIcon from './Icons/TravelTimeIcon.vue'
	import TruckIcon from './Icons/TruckIcon.vue'
	import WeatherIcon from './Icons/WeatherIcon.vue'

	export default defineComponent({
		name: 'VIcon',
		props: {
			name: {
				type: String,
				required: true
			},
			width: {
				type: Number,
				required: true
			},
			height: {
				type: Number,
				required: true
			}
		},
		setup(props) {
			const icons: { [x: string]: unknown } = {
				aircraft: AircraftIcon,
				alert: AlertIcon,
				borderCrossing: BorderCrossingIcon,
				bridge: BridgeRestrictionIcon,
				camera: CameraIcon,
				car: CarIcon,
				closed: ClosedIcon,
				collision: CollisionIcon,
				cyclist: CyclistIcon,
				emergencyAlert: EmergencyAlertIcon,
				ferry: FerryIcon,
				filter: FilterIcon,
				filterClose: FilterCloseIcon,
				map: MapIcon,
				mountain: MountainPassIcon,
				pedestrian: PedestrianIcon,
				road: RoadRestrictonIcon,
				travelTime: TravelTimeIcon,
				truck: TruckIcon,
				weather: WeatherIcon
			}

			const iconComponent = computed(() => {
				return icons[props.name]
			})

			return {
				iconComponent
			}
		}
	})
</script>

<style scoped lang="scss">
	.v-button {
		position: relative;
		display: inline-block;
		z-index: 1;
		background: #007B5F;
		color: $White;
		padding: 4px;
	}

	.v-button:hover {
		cursor: pointer;
	}

	.v-button:before {
		z-index: -1;
		content: "";
		position: absolute;
		left: -10%;
		border-radius: 4px;
		width: 100%;
		height: 100%;
		top: 0;
		transform: skewX(-7deg);
		background-color: inherit;
	}

	.v-button:after {
		z-index: -1;
		content: "";
		position: absolute;
		right: -10%;
		border-radius: 4px;
		width: 100%;
		height: 100%;
		top: 0;
		transform: skewX(-7deg);
		background-color: inherit;
	}
</style>
