<template>
	<a :href="linkUrl" class="v-card-map-link">
		<span class="v-card-map-link-img">
			<v-icon :width="24" :height="24" name="map" aria-label="Map this!" />
		</span>
		<span class="v-card-map-link-text">{{ linkText }}</span>
	</a>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'
	import VIcon from './VIcon.vue'

	export default defineComponent({
		name: 'VMapLink',
		props: {
			linkText: {
				type: String,
				required: true
			},
			linkUrl: {
				type: String,
				required: true
			}
		},
		components: {
			VIcon
		}
	})
</script>

<style scoped lang="scss">
	.v-card-map-link {
		display: flex;
		text-decoration: none;
		border: none;
		flex-direction: row;
		align-items: flex-end;
		width: 100%;
	}

	.v-card-map-link-img {
		display: flex;
		width: 24px;
		height: 24px;
		order: 0;
		flex-grow: 0;
		fill: $PrimaryBrand;
	}

	.v-card-map-link-text {
		display: flex;
		font-size: 16px;
		line-height: 16px;
		text-decoration-line: underline;
		color: $PrimaryBrand;
		order: 1;
		margin: 0 4px;
		flex-flow: wrap;
		width: 100%;
	}
</style>
