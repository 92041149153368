<template>
	<svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<rect x="8" y="0.353553" width="10.8137" height="10.8137" rx="0.25" transform="rotate(45 8 0.353553)" fill="#DC3545" stroke="white" stroke-width="0.5" />
		<rect x="8" y="1.88557" width="8.79955" height="8.79955" rx="0.5" transform="rotate(45 8 1.88557)" fill="white" />
		<path d="M7.37158 5.1253V8.70254C7.37158 9.10324 7.69642 9.42808 8.09712 9.42808C8.49783 9.42808 8.82266 9.10325 8.82266 8.70254V5.12531C8.82266 4.7246 8.49783 4.39977 8.09712 4.39977C7.69642 4.39977 7.37158 4.7246 7.37158 5.1253Z" />
		<path d="M7.4987 10.8248C7.41582 10.943 7.37158 11.0818 7.37158 11.2239C7.37198 11.4143 7.45157 11.5968 7.59294 11.7314C7.7343 11.8661 7.92591 11.9419 8.12583 11.9422C8.27501 11.9422 8.42083 11.9001 8.54487 11.8212C8.6689 11.7422 8.76558 11.6301 8.82266 11.4988C8.87975 11.3675 8.89469 11.2231 8.86558 11.0838C8.83648 10.9444 8.76464 10.8164 8.65916 10.716C8.55368 10.6155 8.41929 10.5471 8.27298 10.5194C8.12667 10.4917 7.97501 10.5059 7.83719 10.5603C7.69937 10.6146 7.58157 10.7067 7.4987 10.8248Z" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'EmergencyAlertIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
