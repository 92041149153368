<template>
	<svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5858 1.41422C11.3668 0.633168 12.6332 0.633168 13.4142 1.41422L22.5858 10.5858C23.3668 11.3668 23.3668 12.6332 22.5858 13.4142L13.4142 22.5858C12.6332 23.3668 11.3668 23.3668 10.5858 22.5858L1.41422 13.4142C0.633168 12.6332 0.633168 11.3668 1.41422 10.5858L10.5858 1.41422ZM10.3212 13H13.6788L14 5H10L10.3212 13ZM12 19C13.1046 19 14 18.1046 14 17C14 15.8954 13.1046 15 12 15C10.8954 15 10 15.8954 10 17C10 18.1046 10.8954 19 12 19Z" fill="#1D252D" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'CollisionIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
