<template>
	<VFilterPage :loaded="loaded" pageTitle="Cameras">
		<template #filter>
			<div id="filterPanel" class="v-modal">
				<v-icon class="modal-close" name="filterClose" :width="26" :height="26" @keyup.enter="closeFilters" @click="closeFilters" tabindex="0" role="button" aria-label="close filters" />
				<div class="filter-header">
					<div role="heading" aria-level="3" class="form-header">Filter Cameras</div>
					<a href="#camera-results" class="visually-hidden focusable skip-link" @click.prevent="resultsFocus('camera-results')">Skip to results</a>
					<div class="filter-panel-clearall" role="button" @keyup.enter="onClearClick" @click="onClearClick" tabindex="0">Clear</div>
				</div>
				<div role="group" aria-labelledby="filterLocation" class="filter-group">
					<div class="form-category-header" id="filterLocation">Category</div>
					<fieldset>
						<legend>Category</legend>
						<template v-for="[key, value] in categoryFilters" :key="key">
							<div class="v-checkbox">
								<input type="checkbox" :id="`category${key}`" v-model="checkedCategories" :value="key" :disabled="value.indexOf('(0)') >= 0" />
								<label class="v-checkbox-label" :for="`category${key}`">{{ value }}</label>
							</div>
						</template>
					</fieldset>
				</div>
				<div class="filter-footer">
					<v-button style="width: 150px;" button-label="Apply" tabindex="0" @click="applyFilters" class="v-button-primary v-button-small v-button-center" />
				</div>
			</div>
		</template>
		<template #mobile>
			<v-icon-button class="v-button v-button-primary" @click="openFilters" iconName="filter" buttonLabel="Filter Cameras" iconPosition="Right" />
		</template>
		<template #tags>
			<template v-for="[key, value] in appliedCategoryFilters" :key="key">
				<v-tag @close="categoryClose(key)" :title="value.substring(0, value.lastIndexOf(' '))" />
			</template>
			<template v-if="appliedCategoryFilters.size > 0">
				<div class="tag-list-clearall v-tag" role="button" @keyup.enter="onClearClick" @click="onClearClick" tabindex="0">Clear All</div>
			</template>
		</template>
		<div class="result-header" id="camera-results" tabindex="-1">
			<div class="result-count" v-if="totalCameras > 0" role="heading" aria-level="3">
				{{ totalCameras.toLocaleString('en-US') }} 
				<template v-if="totalCameras === 1">
					result
				</template>
				<template v-else>
					results
				</template>
			</div>
			<div class="result-count" v-if="totalCameras === 0" role="heading" aria-level="3">
				0 cameras found
			</div>
			<div class="result-sort" v-if="totalCameras > 0">
			</div>
		</div>
		<camera v-for="camera in result" :key="camera.cameraId" :camera="camera"></camera>
		<VPager :pagesEachSide="2" :currentPage="currentPage" :totalPages="lastPage" @setPage="setPage" @prev="prev" @next="next" @applyPageSize="applyPageSize" :pageSize="pageSize" />
	</VFilterPage>
</template>

<script lang="ts">
	import { defineComponent, watch, computed, ref } from 'vue'
	import { useArrayPagination } from 'vue-composable'
	import Camera from '@/components/Cameras/Camera.vue'
	import VFilterPage from '@/components/UI/VFilterPage.vue'
	import VButton from '@/components/UI/VButton.vue'
	import VTag from '@/components/UI/VTag.vue'
	import VIconButton from '@/components/UI/VIconButton.vue'
	import VIcon from '@/components/UI/VIcon.vue'
	import VPager from '@/components/UI/VPager.vue'
	import { useCameraFilters } from '@/composables/cameraFilters'

	export default defineComponent({
		name: 'Cameras',
		components: {
			VFilterPage,
			Camera,
			VPager,
			VButton,
			VTag,
			VIconButton,
			VIcon
		},
		setup() {
			const { cameras, loaded, categoryFilters, appliedCategoryFilters, fetchFilteredCameras } = useCameraFilters()
			const checkedCategories = ref([] as string[])

			const defaultPageSize = 10

			const { result, next, prev, currentPage, lastPage, pageSize } = useArrayPagination(
				cameras,
				{
					currentPage: 1
				}
			)

			pageSize.value = defaultPageSize

			const applyPageSize = (newSize: number) => {
				pageSize.value = newSize
				currentPage.value = 1
			}

			const setPage = (index: number) => {
				currentPage.value = index
			}

			const applyFilters = () => {
				appliedCategoryFilters.value.clear()
				checkedCategories.value.forEach(x => {
					if (categoryFilters.value.has(x)) {
						appliedCategoryFilters.value.set(x, categoryFilters.value.get(x) ?? '')
					}
				})
				fetchFilteredCameras()
			}

			const categoryClose = (value: string) => {
				const index = checkedCategories.value.indexOf(value, 0)
				if (index > -1) {
					checkedCategories.value.splice(index, 1)
				}
				applyFilters()
			}

			watch(cameras, (newValue, oldValue) => {
				if (newValue !== oldValue) {
					currentPage.value = 1
				}
			})

			const totalCameras = computed(() => {
				return cameras.value.length
			})

			const closeFilters = () => {
				document.getElementById('filterPanel')?.classList.remove('active')
				document.removeEventListener('keydown', handleFilterEscape, false)
			}

			const handleFilterEscape = (e: KeyboardEvent) => {
				if (e.key === 'Escape') {
					closeFilters()
				}
			}

			const openFilters = () => {
				document.getElementById('filterPanel')?.classList.add('active')
				document.addEventListener('keydown', handleFilterEscape, false)
			}

			const resultsFocus = (elid: string) => {
				document.getElementById(elid)?.focus()
			}

			const onClearClick = () => {
				checkedCategories.value = []
				applyFilters();
			}

			return {
				result,
				currentPage,
				lastPage,
				pageSize,
				applyPageSize,
				next,
				prev,
				setPage,
				totalCameras,
				applyFilters,
				categoryClose,
				checkedCategories,
				categoryFilters,
				appliedCategoryFilters,
				openFilters,
				closeFilters,
				resultsFocus,
				onClearClick,
				loaded
			}
		}
	})
</script>

<style scoped lang="scss">
	.ad-container {
		max-width: 336px;
		max-height: 280px;

		@include sm {
			width: 100%;
			max-width: 100%;
			text-align: center;
		}
	}
</style>
