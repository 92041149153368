﻿<template>
	<generic-card bannerText="Emergency alert" iconName="emergencyAlert" class="alert emergency-alert">
		<div role="heading" aria-level="4" class="card-title" v-html="travelAlert.alertTitle"></div>
		<div class="card-body">
			<span v-html="travelAlert.headlineMessage"></span><br />
			<template v-if="travelAlert.extendedMessage">
				<span v-html="travelAlert.extendedMessage"></span>
				<br />
			</template>
			<br />
			<span><strong>Last updated: </strong> {{ formatDateTime(travelAlert.lastModifiedDate) }}</span>
		</div>
	</generic-card>
</template>

<script lang="ts">
	import { defineComponent, PropType } from 'vue'
	import { IAlert } from '@/interfaces/IAlert'
	import { useHelpers } from '@/composables/helpers'
	import GenericCard from '@/components/GenericCard.vue'
	import VIcon from '@/components/UI/VIcon.vue'

	export default defineComponent({
		name: 'EmergencyAlert',
		components: {
			GenericCard,
			VIcon
		},
		props: {
			travelAlert: {
				type: Object as PropType<IAlert>,
				required: true
			}
		},
		setup() {
			const { formatDateTime } = useHelpers()

			return {
				formatDateTime
			}
		}
	})
</script>

<style lang="scss">
	.card.alert.emergency-alert {
		width: 100%;
		border: 3px solid $Error;
		background-color: $StateEmergencyRedBG;

		.card-tags-header {
			.card-banner {
				border: 1px solid $Error;
				background-color: $Error;
				.card-banner-img {
					fill: $Error;
				}
			}
		}
	}
</style>
