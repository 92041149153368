<template>
	<svg :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="#00398E"/>
		<path d="M22.4167 11.8233C22.4167 17.4861 17.7565 22.083 12 22.083C6.24354 22.083 1.58334 17.4861 1.58334 11.8233C1.58334 6.16057 6.24354 1.56369 12 1.56369C17.7565 1.56369 22.4167 6.16057 22.4167 11.8233Z" fill="#00398E" stroke="white" stroke-width="0.5"/>
		<path d="M7.51126 9.82111C8.09015 7.57185 9.90405 6 12.0248 6C12.0808 6 12.7535 6.00494 13.4361 6.24559C14.9182 6.76807 16.0931 8.09278 16.5381 9.82112C16.698 9.78819 16.8652 9.76348 17.0058 9.76348C17.29 9.76348 17.5642 9.81109 17.8257 9.89419C17.8758 9.91011 18.3476 10.0503 18.8197 10.4493C19.5643 11.0787 20.0497 12.0817 20.0497 13.2132C20.0497 15.1153 18.6841 16.663 17.0058 16.663H7.04387C5.3653 16.663 4 15.1153 4 13.2132C4 11.3111 5.3653 9.76347 7.04387 9.76347C7.18417 9.76347 7.35159 9.78818 7.51126 9.82111Z" fill="white"/>
		<path d="M11.1711 7.70182V11.736C11.1711 12.137 11.4961 12.462 11.897 12.462C12.298 12.462 12.623 12.137 12.623 11.736V7.70182C12.623 7.30087 12.298 6.97583 11.897 6.97583C11.4961 6.97583 11.1711 7.30087 11.1711 7.70182Z" fill="#00398E"/>
		<path d="M11.2982 13.9859C11.2153 14.1148 11.1711 14.2663 11.1711 14.4213C11.1714 14.6291 11.2511 14.8282 11.3925 14.9751C11.534 15.122 11.7257 15.2047 11.9258 15.2051C12.075 15.2051 12.221 15.1591 12.3451 15.073C12.4692 14.9869 12.5659 14.8645 12.623 14.7213C12.6802 14.5781 12.6951 14.4205 12.666 14.2684C12.6369 14.1164 12.565 13.9768 12.4594 13.8672C12.3539 13.7575 12.2194 13.6829 12.073 13.6527C11.9266 13.6224 11.7749 13.6379 11.6369 13.6973C11.499 13.7566 11.3812 13.857 11.2982 13.9859Z" fill="#00398E"/>
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'WeatherIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
