import { Ref, reactive, toRefs } from 'vue'
import { useRoutePicker } from '@/store/routePicker'
import { ActiveVehicleRestrictionsMapService } from '@/api/travelServiceApi'
import useTruckRestrictions from '@/store/truckRestrictions'

const state = reactive({
	restrictions: [] as ActiveVehicleRestrictionsMapService[],
	baseRestrictionCount: 0,
	filteredRestrictionsCount: 0,
	appliedTypeFilters: new Map<string, string>(),
	appliedDirectionFilters: new Map<string, string>(),
	typeFilters: new Map<string, string>(),
	directionFilters: new Map<string, string>(),
	selectedSort: 'updated',
	loaded: false
})

const internalState = {
	updating: false,
	initialized: false
}

export function useRestrictionFilter(): {
	restrictions: Ref<ActiveVehicleRestrictionsMapService[]>
	baseRestrictionCount: Ref<number>
	filteredRestrictionsCount: Ref<number>
	appliedTypeFilters: Ref<Map<string, string>>
	appliedDirectionFilters: Ref<Map<string, string>>
	typeFilters: Ref<Map<string, string>>
	directionFilters: Ref<Map<string, string>>
	selectedSort: Ref<string>
	loaded: Ref<boolean>
	fetchFilteredRestrictions: () => Promise<void>
	resetFilters: () => Promise<void>
} {
	let first = false
	if (!internalState.initialized) {
		internalState.initialized = true
		first = true
	}
	const { restrictions, fetchTruckRestrictions } = useTruckRestrictions()
	const routePicker = useRoutePicker()

	const filterByRoute = (rest: ActiveVehicleRestrictionsMapService[]): ActiveVehicleRestrictionsMapService[] => {
		if (routePicker.routeType.value === 'road') {
			const srId = routePicker.vehicleRoute.value
			if (srId) {
				return rest.filter(res => res.routeNr === srId)
			}
		} else if (routePicker.routeType.value === 'ferry') {
			return []
		}
		return rest
	}

	const filterByType = (rest: ActiveVehicleRestrictionsMapService[]): ActiveVehicleRestrictionsMapService[] => {
		if (state.appliedTypeFilters.size > 0) {
			return rest.filter(r => state.appliedTypeFilters.has(r.ttype ?? ''))
		}
		return rest
	}

	const filterByDirection = (rest: ActiveVehicleRestrictionsMapService[]): ActiveVehicleRestrictionsMapService[] => {
		if (state.appliedDirectionFilters.size > 0) {
			return rest.filter(r => state.appliedDirectionFilters.has(r.direction ?? '') || r.direction === 'B')
		}
		return rest
	}

	const filterByMilepost = (rest: ActiveVehicleRestrictionsMapService[]): ActiveVehicleRestrictionsMapService[] => {
		if (routePicker.routeType.value === 'road') {
			if (routePicker.hasMileposts.value) {
				return rest.filter(res =>
					(
						(res.restrictionStartMp ?? 0) <= routePicker.highMp.value
						&& (res.restrictionEndMp ?? 0) >= routePicker.lowMp.value
					)
					|| (
						(res.restrictionEndMp ?? 0) <= routePicker.highMp.value
						&& (res.restrictionStartMp ?? 0) >= routePicker.lowMp.value
					)
				)
			}
		}
		return rest
	}

	const sortRestrictions = (rest: ActiveVehicleRestrictionsMapService[]): ActiveVehicleRestrictionsMapService[] => {
		const compareDate = new Date().toISOString().split('T')[0] + 'T' + new Date().toISOString().split('T')[1].split('.')[0]
		switch (state.selectedSort) {
			case 'added':
				return rest.sort((a, b) => {
					if (a.datePosted === b.datePosted) {
						return 0
					}
					if ((a.datePosted ?? compareDate) > (b.datePosted ?? compareDate)) {
						return -1
					} else {
						return 1
					}
				})
			case 'updated':
				return rest.sort((a, b) => {
					if ((a.recordUpdateDate ?? a.datePosted) === (b.recordUpdateDate ?? b.datePosted)) {
						return 0
					}
					if ((a.recordUpdateDate ?? a.datePosted ?? compareDate) > (b.recordUpdateDate ?? b.datePosted ?? compareDate)) {
						return -1
					} else {
						return 1
					}
				})
			default:
				return rest.sort((a, b) => {
					if (!a.routeNr && !b.routeNr) {
						return 0
					}
					if (!a.routeNr || !b.routeNr) {
						return (!a.routeNr) ? -1 : 1
					}
					if (a.routeNr === b.routeNr) {
						return ((a.restrictionStartMp ?? 0) > (b.restrictionStartMp ?? 0)) ? 1 : -1
					}
					return 0
				})
		}
	}

	const filteredRestrictions = async (): Promise<ActiveVehicleRestrictionsMapService[]> => {
		state.loaded = false
		return fetchTruckRestrictions().then(() => {
			state.baseRestrictionCount = restrictions.value.length
			if (routePicker.routeType.value === 'ferry') {
				// Return ferry alerts
				state.loaded = restrictions.value.length > 0
				state.filteredRestrictionsCount = 0
				return []
			}

			const result = filterByMilepost(
				filterByRoute(restrictions.value)
			)

			const bridgeCount = result.filter((obj) => obj.ttype === 'B').length
			const roadCount = result.filter((obj) => obj.ttype === 'R').length
			state.typeFilters.set('B', `Bridge restriction (${bridgeCount})`)
			state.typeFilters.set('R', `Road restriction (${roadCount})`)

			const increasingCount = result.filter((obj) => obj.direction === 'I').length
			const decreasingCount = result.filter((obj) => obj.direction === 'D').length
			const bothCount = result.filter((obj) => obj.direction === 'B').length
			state.directionFilters.set('I', `Northbound/Eastbound (${increasingCount + bothCount})`)
			state.directionFilters.set('D', `Southbound/Westbound (${decreasingCount + bothCount})`)

			const finalResult = sortRestrictions(
				filterByType(
					filterByDirection(result)
				)
			)
			state.loaded = restrictions.value.length > 0
			state.filteredRestrictionsCount = finalResult.length
			return finalResult
		})
	}

	const updateResult = async () => {
		if (!internalState.updating) {
			internalState.updating = true
			state.restrictions = await filteredRestrictions()
			internalState.updating = false
		}
	}

	const fetchFilteredRestrictions = async () => {
		return updateResult()
	}

	const resetFilters = async () => {
		state.appliedTypeFilters = new Map<string, string>()
		state.appliedDirectionFilters = new Map<string, string>()
		return fetchFilteredRestrictions()
	}

	if (first) {
		if (!state.loaded && !internalState.updating) {
			updateResult()
		}
	}

	return {
		...toRefs(state),
		fetchFilteredRestrictions,
		resetFilters
	}
}