<template>
	<svg :width="width" :height="height" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
		<path d="M18 9.13448C18 14.1793 13.9706 18.269 9 18.269C4.02944 18.269 0 14.1793 0 9.13448C0 4.08965 4.02944 0 9 0C13.9706 0 18 4.08965 18 9.13448Z" />
		<circle cx="9" cy="9" r="7.75" stroke="white" stroke-width="0.5" />
		<path d="M4.36386 11.0794L4.8985 10.04L5.95807 10.2904L5.95816 10.2903L6.43437 9.73522L7.46554 10.8128L7.46619 10.8134C7.48338 10.8312 7.50152 10.8439 7.51811 10.853L7.99877 11.35L7.99883 11.35C8.09698 11.4514 8.22722 11.5047 8.36004 11.5047C8.48431 11.5047 8.61017 11.4588 8.70856 11.3643L8.70858 11.3643L9.01132 11.0735L9.60561 12.2453H5.07525C4.47653 12.2453 4.08998 11.6118 4.36386 11.0794Z" stroke="white" stroke-width="0.4" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M9.12688 5.11607C9.50024 4.39023 10.539 4.39328 10.9081 5.1213L12.1726 7.61562L11.2751 8.71588L9.71637 6.79922C9.65849 6.72807 9.57336 6.68714 9.48387 6.68594H9.48009C9.39208 6.68594 9.30883 6.72424 9.25009 6.79271L8.45009 7.725L8.05884 7.19114L8.11509 7.08307L9.12688 5.11607ZM12.1727 7.61596L11.2752 8.71622L9.71643 6.79956C9.65855 6.72841 9.57342 6.68748 9.48393 6.68627H9.48018C9.39216 6.68627 9.30892 6.72458 9.25018 6.79305L8.45018 7.72534L8.05893 7.19148L7.86768 7.56518L8.20018 8.02091C8.25632 8.09761 8.34142 8.14345 8.43393 8.14721C8.52616 8.15121 8.61475 8.11106 8.67643 8.03914L9.47893 7.10424L11.0389 9.02221C11.0972 9.09382 11.1826 9.136 11.2727 9.13679H11.2752C11.3643 9.13679 11.4489 9.09598 11.5077 9.02612L11.5089 9.02481L12.3589 7.98315L12.1727 7.61596Z" fill="white" />
		<path d="M7.89695 7.94453L8.03851 8.13855L8.03869 8.1388C8.12984 8.26332 8.27062 8.34043 8.42545 8.3468M7.89695 7.94453L8.42516 8.34678C8.42526 8.34679 8.42536 8.34679 8.42545 8.3468M7.89695 7.94453L7.77338 8.18429L7.81929 8.27508L8.06186 8.7549C8.26262 9.15212 8.53038 9.68207 8.79832 10.213C9.06625 10.7438 9.33441 11.2757 9.53598 11.6768C9.63674 11.8774 9.72095 12.0454 9.78019 12.1642C9.79521 12.1944 9.80867 12.2215 9.82041 12.2451H12.9943C13.591 12.2451 13.9777 11.6155 13.7078 11.0834L13.0639 9.81349L13.0611 9.81056L13.0473 9.78168L13.0382 9.76285L12.3195 8.34733L11.6638 9.15099L11.6586 9.15725L11.6558 9.16026C11.561 9.26965 11.4236 9.33656 11.275 9.33656H11.2725H11.2707L11.2707 9.33655C11.1188 9.3352 10.9779 9.26416 10.8836 9.14817C10.8836 9.14816 10.8836 9.14815 10.8836 9.14814L9.47469 7.41588L8.82814 8.1691M7.89695 7.94453L8.82814 8.1691M8.42545 8.3468C8.58231 8.35351 8.72878 8.28492 8.82809 8.16917C8.82811 8.16915 8.82812 8.16912 8.82814 8.1691M8.42545 8.3468L8.82814 8.1691" stroke="white" stroke-width="0.4" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M5.81088 7.82872C6.18404 7.10278 7.22278 7.10554 7.59206 7.83346L8.88862 10.3892L8.88866 10.3892L9.06741 10.742L8.56991 11.2199C8.51045 11.277 8.43493 11.3045 8.35991 11.3045C8.28108 11.3045 8.20275 11.2731 8.14241 11.2108L7.63366 10.6847C7.63032 10.6822 7.62641 10.6812 7.62253 10.6802C7.61798 10.6791 7.61346 10.678 7.60991 10.6743L6.42615 9.43734L5.80615 10.16C5.74482 10.2316 5.6611 10.2694 5.57615 10.2694C5.5061 10.2694 5.43565 10.2434 5.3774 10.1925L4.8374 9.72119L5.01613 9.37488L5.01611 9.37487L5.81088 7.82872ZM5.5699 9.85791L5.5699 9.85791L6.1924 9.13135C6.20162 9.12058 6.21149 9.11051 6.22193 9.10118C6.21148 9.11052 6.20159 9.1206 6.19236 9.13138L5.5699 9.85791Z" fill="white" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'MountainPassIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
