import dayjs from 'dayjs'

export function useHelpers(): {
	formatTemperature: (temperature: number, temperatureUnit: string, displayDate?: string) => string,
	formatElevation: (elevation: number, elvationUnit: string) => string,
	formatDate: (date: string | null | undefined) => string,
	formatDateTime: (date: string | null | undefined) => string,
	formatTimeDate: (date: string | null | undefined) => string,
	formatDateTimeLongDayMonth: (date: string | null | undefined) => string
} {
	const formatTemperature = (temperature: number, temperatureUnit: string, displayDate?: string): string => {
		let returnValue: string
		if (temperatureUnit === 'Fahrenheit' || temperatureUnit === 'F') {
			returnValue = `${temperature}\xB0F / ${Math.round((temperature - 32) * 5.0 / 9.0)}\xB0C`
		} else if (temperatureUnit === 'Celsius' || temperatureUnit === 'C') {
			returnValue = `${Math.round((temperature) * 9.0 / 5.0) + 32}\xB0F / ${temperature}\xB0C`
		} else {
			returnValue = `${temperature} ${temperatureUnit}`
		}

		if (displayDate) {
			return `${returnValue} as of ${ formatTimeDate(displayDate) }`
		}
		return returnValue
	}

	const formatElevation = (elevation: number, elevationUnit: string): string => {
		if (elevationUnit === 'Feet') {
			return `${elevation} ft / ${Math.round(elevation / 3.281)} m`
		} else {
			return `${elevation} ${elevationUnit}`
		}
	}

	const formatDate = (date: string | null | undefined): string => {
		if (!date) {
			return ''
		}
		return dayjs(date).format('MM/DD/YYYY')
	}

	const formatDateTime = (date: string | null | undefined): string => {
		if (!date) {
			return ''
		}
		return dayjs(date).format('MM/DD/YYYY h:mm A')
	}

	const formatTimeDate = (date: string | null | undefined): string => {
		if (!date) {
			return ''
		}
		return dayjs(date).format('h:mm A MM/DD/YYYY')
	}

	const formatDateTimeLongDayMonth = (date: string | null | undefined): string => {
		if (!date) {
			return ''
		}
		return dayjs(date).format('dddd, MMMM D, YYYY h:mm A')
	}

	return {
		formatTemperature,
		formatElevation,
		formatDate,
		formatDateTime,
		formatTimeDate,
		formatDateTimeLongDayMonth
	}
}