<template>
	<div class="v-tag" @keyup.enter="onClick" @click="onClick" role="button" :aria-label="`Remove filter ${title}`" >
		<div class="v-tag-text">{{ title }}</div>
		<div class="v-tag-img" tabindex="0">
			<v-icon :width="18" :height="18" name="filterClose" aria-hidden="true" />
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'
	import VIcon from '@/components/UI/VIcon.vue'

	export default defineComponent({
		name: 'VTag',
		emits: ['close'],
		components: {
			VIcon
		},
		props: {
			title: {
				type: String,
				required: false
			}
		},
		setup(_, { emit }) {
			const onClick = () => {
				emit('close')
			}

			return {
				onClick
			}
		}
	})
</script>

<style lang="scss">
	.v-tag {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 2px 4px;
		position: static;
		height: 28px;
		left: 0px;
		top: 0px;
		background: rgba(153, 202, 191, 0.5);
		border-radius: 4px;
		flex: none;
		order: 0;
		flex-grow: 0;
		margin: 0px 8px 0px 0px;
		font-size: 12.8px;
		line-height: 16px;

		@include sm {
			font-size: 10px;
		}

		.v-tag-img {
			position: static;
			width: 18px;
			height: 18px;
			top: 2px;
			right: 2px;
			flex: none;
			order: 1;
			flex-grow: 0;
			margin: 0px 2px;
			cursor: pointer;
		}

		.v-tag-text {
			position: static;
			height: 16px;
			left: 4px;
			top: 2px;
			color: $Gray100;
			flex: none;
			order: 0;
			flex-grow: 0;
			margin: 0px 2px;
			font-weight: bold;
		}
	}
</style>
