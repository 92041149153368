<template>
	<svg :width="width" :height="height" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
		<path d="M18 9.12809C18 14.1694 13.9706 18.2562 9 18.2562C4.02944 18.2562 0 14.1694 0 9.12809C0 4.08679 4.02944 0 9 0C13.9706 0 18 4.08679 18 9.12809Z" />
		<circle cx="9" cy="9.00024" r="7.75" fill="#007B5F" stroke="white" stroke-width="0.5" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6.33333 5.69702C5.22876 5.69702 4.33333 6.59245 4.33333 7.69702V10.1484C4.33333 11.253 5.22876 12.1484 6.33333 12.1484H10.075C11.1796 12.1484 12.075 11.253 12.075 10.1484V7.69702C12.075 6.59245 11.1796 5.69702 10.075 5.69702H6.33333ZM14.3331 6.79855V10.4022C14.3331 10.6541 14.1289 10.8583 13.8769 10.8583C13.7559 10.8583 13.6399 10.8103 13.5543 10.7247L12.2216 9.39194C12.1278 9.29817 12.0751 9.17099 12.0751 9.03838V8.16232C12.0751 8.02971 12.1278 7.90253 12.2216 7.80877L13.5543 6.47598C13.6399 6.39043 13.7559 6.34237 13.8769 6.34237C14.1289 6.34237 14.3331 6.54661 14.3331 6.79855Z" fill="white" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'CameraIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
