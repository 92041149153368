import { reactive, Ref, toRefs } from 'vue'
import { MountainPassReportModel } from '@/api/travelServiceApi'
import { useRoutePicker } from '@/store/routePicker'
import usePassReports from '@/store/passReports'

const state = reactive({
	reports: [] as MountainPassReportModel[],
	baseReportsCount: 0,
	filteredReportsCount: 0,
	loaded: false
})

const internalState = {
	updating: false,
	initialized: false
}

export function usePassReportFilters(): {
	reports: Ref<MountainPassReportModel[]>
	baseReportsCount: Ref<number>
	filteredReportsCount: Ref<number>
	loaded: Ref<boolean>
	fetchFilteredReports: () => Promise<void>
	resetFilters: () => Promise<void>
} {
	let first = false
	if (!internalState.initialized) {
		internalState.initialized = true
		first = true
	}
	const { reports, fetchPassReports } = usePassReports()
	const routePicker = useRoutePicker()

	const filterByRoute = (treport: MountainPassReportModel[]) => {
		if (routePicker.routeType.value === 'road') {
			const srId = routePicker.vehicleRoute.value
			if (srId) {
				return treport.filter(report => report.mountainPass?.stateRouteId === srId)
			}
		} else if (routePicker.routeType.value === 'ferry') {
			return []
		}
		return treport
	}

	const filterByMilepost = (treport: MountainPassReportModel[]): MountainPassReportModel[] => {
		if (routePicker.routeType.value === 'road') {
			if (routePicker.hasMileposts.value) {
				return treport.filter(report =>
					(
						(report.mountainPass?.startMP ?? 0) <= routePicker.highMp.value
						&& (report.mountainPass?.endMP ?? 0) >= routePicker.lowMp.value
					)
					|| (
						(report.mountainPass?.endMP ?? 0) <= routePicker.highMp.value
						&& (report.mountainPass?.startMP ?? 0) >= routePicker.lowMp.value
					)
				)
			}
		} else if (routePicker.routeType.value === 'ferry') {
			return []
		}
		return treport
	}

	const sortByName = (treport: MountainPassReportModel[]): MountainPassReportModel[] => {
		return treport.sort((a, b) => {
			if ((a.mountainPass?.mountainPassName ?? '') === (b.mountainPass?.mountainPassName ?? '')) {
				return 0
			}
			return ((a.mountainPass?.mountainPassName ?? '') > (b.mountainPass?.mountainPassName ?? '')) ? 1 : -1
		})
	}

	const sortByMilepost = (treport: MountainPassReportModel[]): MountainPassReportModel[] => {
		if (routePicker.routeType.value === 'road' && routePicker.hasLocation.value) {
				if (routePicker.startLocation.value && routePicker.endLocation.value) {
					if (routePicker.startLocation.value.startMp > routePicker.endLocation.value.startMp) {
						return treport.sort((a, b) => {
							return (a.mountainPass?.startMP ?? 0) > (b.mountainPass?.endMP ?? 0) ? 1 : 0
					})
				}
			}
		}
		return treport
	}

	const sortReports = (treport: MountainPassReportModel[]): MountainPassReportModel[] => {
		return sortByMilepost(
			sortByName(treport)
		)
	}

	const filteredReports = async (): Promise<MountainPassReportModel[]> => {
		state.loaded = false
		return fetchPassReports().then(() => {
			const result = sortReports(
				filterByMilepost(
					filterByRoute(reports.value)
				)
			)

			state.baseReportsCount = reports.value.length
			state.loaded = reports.value.length > 0
			state.filteredReportsCount = result.length
			return result
		})
	}

	const updateResult = async () => {
		if (!internalState.updating) {
			internalState.updating = true
			state.reports = await filteredReports()
			internalState.updating = false
		}
	}

	const fetchFilteredReports = async () => {
		return updateResult()
	}

	const resetFilters = async () => {
		return updateResult()
	}

	if (first) {
		if (!state.loaded && !internalState.updating) {
			updateResult()
		}
	}

	return {
		...toRefs(state),
		fetchFilteredReports,
		resetFilters
	}
}