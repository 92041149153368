import { createRouter, createWebHistory } from 'vue-router'
import IndexBase from '@/IndexBase.vue'
import Index from '@/components/Index.vue'

const routerHistory = createWebHistory('/travel/real-time/')

const scrollBehavior = () => {
	return { top: 0 }
}

const router = createRouter({
	history: routerHistory,
	scrollBehavior: scrollBehavior,
	routes: [
		{
			path: '/',
			name: 'home',
			component: IndexBase,
			meta: {
				title: 'Real-time travel data',
				description: 'The real-time travel data list shows travel alerts, cameras, truck restrictions and mountain pass reports in a list by road.',
				keywords: 'Washington traffic cameras list, Washington mountain pass reports list, Washington truck restrictions list',
				breadcrumb: []
			},
			children: [
				{
					path: '',
					name: 'index',
					component: Index,
					meta: {
						title: 'Real-time travel data',
						description: 'The real-time travel data list shows travel alerts, cameras, truck restrictions and mountain pass reports in a list by road.',
						keywords: 'Washington traffic cameras list, Washington mountain pass reports list, Washington truck restrictions list',
						breadcrumb: []
					},
					children: [
						{
							path: ':tabname(alerts|cameras|truck-restrictions|mountain-pass-reports)/:routeType?/:routeId?/:startCity?/:endCity?',
							name: 'searchresults',
							props: true,
							component: Index,
							meta: {
								title: 'Alerts',
								description: '',
								keywords: '',
								breadcrumb: [
									{ name: 'Real-time travel data', link: 'index' }
								]
							}
						},
					]
				},
				{
					path: '/border-crossings',
					name: 'bordercrossings',
					component: () => import('@/components/BorderCrossings/BorderCrossings.vue'),
					meta: {
						title: 'Border crossing wait times',
						description: '',
						keywords: '',
						breadcrumb: [
							{ name: 'Real-time travel data', link: 'index' }
						]
					}
				},
				{
					path: '/travel-times',
					name: 'traveltimes',
					component: () => import('@/components/TravelTimes/TravelTimes.vue'),
					meta: {
						title: 'Travel times',
						description: '',
						keywords: '',
						breadcrumb: [
							{ name: 'Real-time travel data', link: 'index' }
						]
					}
				},
				{
					path: '/hoodcanalbridgestatus',
					name: 'hoodcanalbridgestatus',
					component: () => import('@/components/Bridges/HoodCanalBridgeStatus.vue'),
					meta: {
						title: 'Hood Canal Bridge status',
						description: 'Check the status of the Hood Canal Bridge, view cameras and get the weather forecast.',
						keywords: '',
						breadcrumb: [
							{ name: 'Real-time travel data', link: 'index' }
						]
					}
				},
				{
					path: '/mountainpasses',
					name: 'mountainpassesindex',
					component: () => import('@/components/MountainPasses/MountainPassesIndex.vue'),
					children: [
						{
							path: '',
							name: 'mountainpasses',
							component: () => import('@/components/MountainPasses/MountainPasses.vue'),
							meta: {
								title: 'Mountain passes & winter travel',
								description: 'Access mountain pass pages and learn how to prepare for winter driving conditions.',
								keywords: '',
								breadcrumb: [
									{ name: 'Real-time travel data', link: 'index' }
								]
							}
						},
						{
							path: 'emergencycarkit',
							name: 'emergencycarkit',
							component: () => import('@/components/MountainPasses/EmergencyCarKit.vue'),
							meta: {
								title: 'Emergency car kit',
								description: '',
								keywords: '',
								breadcrumb: [
									{ name: 'Real-time travel data', link: 'index' },
									{ name: 'Mountain passes & winter travel', link: 'mountainpasses' }
								]
							}
						},
						{
							path: 'tiresandchains',
							name: 'tiresandchains',
							component: () => import('@/components/MountainPasses/TiresAndChains.vue'),
							meta: {
								title: 'Tires & chains',
								description: '',
								keywords: '',
								breadcrumb: [
									{ name: 'Real-time travel data', link: 'index' },
									{ name: 'Mountain passes & winter travel', link: 'mountainpasses' }
								]
							}
						},
						{
							path: 'winterdrivingtips',
							name: 'winterdrivingtips',
							component: () => import('@/components/MountainPasses/WinterDrivingTips.vue'),
							meta: {
								title: 'Winter driving tips',
								description: '',
								keywords: '',
								breadcrumb: [
									{ name: 'Real-time travel data', link: 'index' },
									{ name: 'Mountain passes & winter travel', link: 'mountainpasses' }
								]
							}
						},
						{
							path: 'snowfallreport',
							name: 'snowfallreport',
							component: () => import('@/components/MountainPasses/SnowfallReport.vue'),
							props: true,
							meta: {
								title: 'Snowfall report',
								description: '',
								keywords: '',
								breadcrumb: [
									{ name: 'Real-time travel data', link: 'index' },
									{ name: 'Mountain passes & winter travel', link: 'mountainpasses' }
								]
							}
						},
						{
							path: ':id',
							name: 'mountainpass',
							component: () => import('@/components/MountainPasses/MountainPass.vue'),
							props: true,
							meta: {
								title: 'Mountain pass details',
								description: '',
								keywords: '',
								breadcrumb: [
									{ name: 'Real-time travel data', link: 'index' },
									{ name: 'Mountain passes & winter travel', link: 'mountainpasses' }
								]
							}
						}
					]
				},
				{
					path: '/weather',
					name: 'weather',
					component: () => import('@/components/Weather/Weather.vue'),
					meta: {
						title: 'Weather stations',
						description: '',
						keywords: ''
					},
					children: [
						{
							path: '',
							name: 'weatherstations',
							component: () => import('@/components/Weather/WeatherStationList.vue'),
							meta: {
								title: 'Weather stations',
								description: '',
								keywords: '',
								breadcrumb: [
									{ name: 'Real-time travel data', link: 'index' }
								]
							}
						},
						{
							path: '/weather/:id',
							name: 'weatherdetail',
							component: () => import('@/components/Weather/WeatherDetail.vue'),
							props: true,
							children: [
								{
									path: '',
									name: 'weatherstationdetails',
									component: () => import('@/components/Weather/WeatherStationDetails.vue'),
									props: true,
									meta: {
										title: 'Weather station detail',
										description: '',
										keywords: '',
										breadcrumb: [
											{ name: 'Real-time travel data', link: 'index' },
											{ name: 'Weather stations', link: 'weatherstations' }
										]
									}
								},
								{
									path: 'recentweatherdata',
									name: 'recentweatherdata',
									component: () => import('@/components/Weather/RecentWeatherData.vue'),
									props: true,
									meta: {
										title: 'Recent weather data',
										description: '',
										keywords: '',
										breadcrumb: [
											{ name: 'Real-time travel data', link: 'index' },
											{ name: 'Weather stations', link: 'weatherstations' },
											{ name: 'Weather station detail', link: 'weatherstationdetails' }
										]
									}
								}
							]
						}
					]
				}
			]
		},
		{
			path: '/:catchAll(.*)',
			redirect: '/'
		}
	]
})

router.beforeEach((to, _, next) => {
	if (to.query.tab) {
		switch (to.query.tab) {
			case 'restrictions':
				next({ path: '/truck-restrictions' })
				break;
			case 'passes':
				next({ path: '/mountain-pass-reports' })
				break;
			default:
				next({ path: `/${to.query.tab}` })
				break;
		}
	} else if (to.query.route) {
		next({ path: `/alerts/road/${to.query.route}` })
	}
	else if (to.query.ferryroute) {
		next({ path: `/alerts/ferry/${to.query.ferryroute}` })
	} else {
		next()
	}
})

export { router, routerHistory }