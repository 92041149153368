<template>
	<svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<path d="M7.99967 1.33398C4.31301 1.33398 1.33301 4.31398 1.33301 8.00065C1.33301 11.6873 4.31301 14.6673 7.99967 14.6673C11.6863 14.6673 14.6663 11.6873 14.6663 8.00065C14.6663 4.31398 11.6863 1.33398 7.99967 1.33398ZM11.333 10.394L10.393 11.334L7.99967 8.94065L5.60634 11.334L4.66634 10.394L7.05967 8.00065L4.66634 5.60732L5.60634 4.66732L7.99967 7.06065L10.393 4.66732L11.333 5.60732L8.93967 8.00065L11.333 10.394Z" fill="#1D252D" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'FilterCloseIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
