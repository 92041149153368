<template>
	<div class="result-card" @keyup.enter="cardClick" @click="cardClick" role="link" tabindex="0">
		<template v-if="!loaded">
			<div class="circle2"></div>
		</template>
		<template v-if="loaded">
			<div class="result-card-header">
				<span class="result-card-header-count">{{ count.toLocaleString('en-US') }}</span>
				<span class="result-card-header-type">{{ resultType }}</span>
			</div>
			<div class="result-card-body">
				<span>{{ description }}</span>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'RouteSummaryCard',
		props: {
			count: {
				type: Number,
				required: true
			},
			resultType: {
				type: String,
				required: true
			},
			description: {
				type: String,
				required: true
			},
			loaded: {
				type: Boolean,
				required: false
			}
		},
		setup(_, { emit }) {
			const cardClick = () => {
				emit('click')
			}

			return {
				cardClick
			}
		}

	})
</script>

<style scoped lang="scss">
	.result-card {
		position: relative;
		padding: 24px 32px;
		background-color: $White;
		margin: 24px 0;
		border: 1px solid $Gray10;
		box-sizing: border-box;
		border-radius: 4px;

		&:hover {
			cursor: pointer;
		}

		.result-card-header-count {
			margin: 0 8px 0 0;
			font-size: 48.83px;
			color: $PrimaryBrand;
			line-height: 61px;
			font-weight: 700;
		}

		.result-card-header-type {
			text-decoration: underline;
			color: $PrimaryBrand;
			font-weight: 700;
			line-height: 61px;
			font-size: 31.25px;
		}
	}
</style>
