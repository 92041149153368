/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActiveVehicleRestrictionsMapService {
  uniqueId?: string;
  state?: string;
  routeNr?: string;

  /** @format int32 */
  seqNr?: number;
  direction?: string;
  cardinalDirection?: string;

  /** @format double */
  restrictionStartMp?: number;

  /** @format double */
  restrictionEndMp?: number;
  restrictionComment?: string;
  locationName?: string;
  locationDescription?: string;

  /** @format date-time */
  datePosted?: string;

  /** @format date-time */
  dateEffective?: string;

  /** @format date-time */
  dateExpires?: string;

  /** @format int32 */
  restrictionWidth?: number;

  /** @format int32 */
  restrictionHeight?: number;

  /** @format int32 */
  restrictionLength?: number;

  /** @format int32 */
  restrictionWeight?: number;
  roadVehType?: string;
  commercialVehYn?: string;
  detourAvailableYn?: string;
  permanentRestrictionYn?: string;
  exceptionsAllowedYn?: string;
  warningYn?: string;
  bridgeNr?: string;

  /** @format int32 */
  maxGvw?: number;
  bridgeType?: string;
  bridgeName?: string;

  /** @format int32 */
  blMaxAxle?: number;

  /** @format int32 */
  cl8MaxAxle?: number;

  /** @format int32 */
  saMaxAxle?: number;

  /** @format int32 */
  tdMaxAxle?: number;
  ttype?: string;

  /** @format int32 */
  postedRestrictionFlag?: number;

  /** @format date-time */
  recordUpdateDate?: string;
  relatedRouteType?: string;
  relatedRouteQualifier?: string;
  aheadBackIndicator?: string;
}

export interface AlertsModel {
  /** @format int32 */
  eventId?: number;

  /** @format int32 */
  criticalEventIndicator?: number;
  iconName?: string;

  /** @format int32 */
  eventPriorityID?: number;
  eventPriorityDescription?: string;

  /** @format int32 */
  eventCategoryID?: number;
  eventCategoryDescription?: string;
  stateRouteId?: string;
  roadName?: string;
  eventCategoryType?: string;

  /** @format date-time */
  lastModifiedDate?: string;

  /** @format int32 */
  displayOrder?: number;

  /** @format double */
  displayLatitude?: number;

  /** @format double */
  displayLongitude?: number;

  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;

  /** @format double */
  endLatitude?: number;

  /** @format double */
  endLongitude?: number;
  geometryType?: string;
  locationName?: string;

  /** @format double */
  startMilepost?: number;

  /** @format double */
  endMilepost?: number;
  roadDirection?: string;
  headlineMessage?: string;
  extendedMessage?: string;

  /** @format int32 */
  sourceSystemId?: number;

  /** @format int32 */
  regionId?: number;

  /** @format date-time */
  eventStartDate?: string;

  /** @format date-time */
  eventEndDate?: string;
  eventCategoryTypeDescription?: string;

  /** @format int32 */
  travelCenterPriorityId?: number;
}

export interface AlertsModelV2 {
  /** @format int32 */
  eventId?: number;

  /** @format int32 */
  criticalEventIndicator?: number;
  iconName?: string;

  /** @format int32 */
  eventPriorityID?: number;
  eventPriorityDescription?: string;

  /** @format int32 */
  eventCategoryID?: number;
  eventCategoryDescription?: string;
  stateRouteId?: string;
  roadName?: string;
  eventCategoryType?: string;

  /** @format date-time */
  lastModifiedDate?: string;

  /** @format int32 */
  displayOrder?: number;

  /** @format double */
  displayLatitude?: number;

  /** @format double */
  displayLongitude?: number;

  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;

  /** @format double */
  endLatitude?: number;

  /** @format double */
  endLongitude?: number;
  geometryType?: string;
  locationName?: string;

  /** @format double */
  startMilepost?: number;

  /** @format double */
  endMilepost?: number;
  roadDirection?: string;
  headlineMessage?: string;
  extendedMessage?: string;

  /** @format int32 */
  sourceSystemId?: number;

  /** @format int32 */
  regionId?: number;

  /** @format date-time */
  eventStartDate?: string;

  /** @format date-time */
  eventEndDate?: string;
  eventCategoryTypeDescription?: string;

  /** @format int32 */
  travelCenterPriorityId?: number;
  geoJSON?: string;
}

export interface Amenities {
  amenity?: string;
  status?: string;
}

export interface BorderCrossingInfo {
  name?: string;
  description?: string;
  waitTimeInText?: string;
  stateRouteId?: string;

  /** @format double */
  srmp?: number;
  direction?: string;

  /** @format date-time */
  lastUpdatedDate?: string;
}

export interface BridgeMessage {
  bridgeName?: string;
  title?: string;
  description?: string;
}

export interface BridgeOpenSchedule {
  /** @format date-time */
  scheduledDate?: string;
  openFor?: string;

  /** @format double */
  duration?: number;
}

export interface BridgeStatusModel {
  code?: string;
  detail?: string;
}

export interface CameraListModel {
  /** @format int32 */
  cameraId?: number;
  cameraLabel?: string;
  cameraUrl?: string;
  stateRoute?: string;
  stateRouteId?: string;

  /** @format int32 */
  width?: number;

  /** @format int32 */
  height?: number;

  /** @format double */
  milePost?: number;

  /** @format int32 */
  displayOrder?: number;
  camCredit?: string;
  creditUrl?: string;

  /** @format int32 */
  camPosition?: number;
  direction?: string;
}

export interface CameraModel {
  /** @format int32 */
  cameraId?: number;
  cameraLabel?: string;
  cameraUrl?: string;
  stateRoute?: string;
  stateRouteId?: string;

  /** @format int32 */
  width?: number;

  /** @format int32 */
  height?: number;

  /** @format double */
  milePost?: number;

  /** @format int32 */
  displayOrder?: number;
  camCredit?: string;
  creditUrl?: string;

  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;

  /** @format int32 */
  camPosition?: number;
  direction?: string;
}

export interface CityModel {
  city?: string;

  /** @format double */
  mpBegin?: number;

  /** @format double */
  mpEnd?: number;
}

export interface DailySnowFallReadings {
  /** @format int32 */
  day?: number;

  /** @format double */
  newDailySnowFall?: number;

  /** @format int32 */
  totalSnowFall?: number;

  /** @format int32 */
  accumulatedSnowFall?: number;
}

export interface FerryRouteAlert {
  /** @format int32 */
  bulletinID?: number;
  title?: string;
  textHtml?: string;

  /** @format int32 */
  sortOrder?: number;
  allRoutesFlag?: boolean;
  affectedRoutes?: number[];

  /** @format date-time */
  publishDate?: string;
}

export interface FerryRouteInfo {
  /** @format int32 */
  scheduledRouteId?: number;

  /** @format int32 */
  routeId?: number;
  routeName?: string;
  terminals?: FerryTerminal[];
  cameraIds?: number[];
}

export interface FerryTerminal {
  /** @format int32 */
  id?: number;
  name?: string;
}

export interface FerryTerminalAlerts {
  /** @format int32 */
  terminalId?: number;

  /** @format int32 */
  sortOrder?: number;
  title?: string;
  textHtml?: string;

  /** @format date-time */
  lastUpdated?: string;
}

export interface FixedTollRateRoutes {
  stateRouteID?: string;
  tollRouteName?: string;
  tollRouteType?: string;

  /** @format double */
  startSRMP?: number;

  /** @format double */
  endSRMP?: number;
  travelDirection?: string;

  /** @format int32 */
  tollHourStart?: number;

  /** @format int32 */
  tollHourEnd?: number;

  /** @format double */
  tollRateByG2G?: number;

  /** @format double */
  tollRateByMail?: number;

  /** @format double */
  tollRateByBooth?: number;

  /** @format int32 */
  axleCount?: number;
  isWeekEndOrHoliday?: string;
}

export interface ForecastDatum {
  /** @format int32 */
  forecastNumber?: number;
  forecastText?: string;
  periodText?: string;
  weatherIconFileName?: string;
  weatherDescription?: string;
}

export interface ForecastModel {
  nwsZoneId?: string;
  nwsZoneRegionName?: string;

  /** @format date-time */
  forecastDateTime?: string;

  /** @format date-time */
  forecastExpirationDateTime?: string;
  forecastData?: ForecastDatum[];
}

export interface ForecastSummaryModel {
  nwsZoneId?: string;

  /** @format int32 */
  forecastNumber?: number;
  weatherIconFileName?: string;
  weatherDescription?: string;
  periodText?: string;
}

export interface HoodCanalBridgeDetails {
  status?: BridgeStatusModel;
  openingSchedules?: BridgeOpenSchedule[];
  cameras?: CameraModel[];
  message?: BridgeMessage;
}

export interface Location {
  stateRouteID?: string;
  description?: string;
  direction?: string;

  /** @format double */
  milepost?: number;

  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;
}

export interface MountainPassConditionModel {
  /** @format int32 */
  mountainPassId?: number;

  /** @format int32 */
  temperature?: number;
  temperatureUnit?: string;
  weather?: string;
  roadCondition?: string;

  /** @format date-time */
  displayDate?: string;
  travelAdvisoryFlag?: boolean;
  restrictionOne?: PassRestriction;
  restrictionTwo?: PassRestriction;
}

export interface MountainPassDetailsModel {
  mountainPass?: MountainPassModel;
  condition?: MountainPassConditionModel;
  cameras?: CameraModel[];
  stationForecasts?: WeatherStationWithForecastModel[];
}

export interface MountainPassModel {
  /** @format int32 */
  mountainPassId?: number;
  mountainPassName?: string;

  /** @format int32 */
  elevation?: number;
  elevationUnit?: string;
  weatherZone?: string;

  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;
  stateRouteId?: string;

  /** @format double */
  srmp?: number;

  /** @format double */
  startMP?: number;

  /** @format double */
  endMP?: number;
  friendlyName?: string;

  /** @format int32 */
  defaultCameraId?: number;
  cameras?: number[];
}

export interface MountainPassReportModel {
  mountainPass?: MountainPassModel;
  condition?: MountainPassConditionModel;
}

export interface PassRestriction {
  travelDirectionName?: string;
  publicPage?: string;
}

export interface RecentWeatherDataModel {
  /** @format int32 */
  stationId?: number;
  stationDescription?: string;
  recentWeatherData?: StationWeatherData[];
}

export interface RestAreaModel {
  /** @format int32 */
  restAreaId?: number;
  restAreaName?: string;
  stateRoute?: string;

  /** @format double */
  milepost?: number;
  routeDirection?: string;
  routeDescription?: string;

  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;
  geoJSON?: string;
  status?: string;
  amenities?: Amenities[];
}

export interface RouteLocation {
  startPoint?: Location;
  endPoint?: Location;
}

export interface RouteSegment {
  stateRouteID?: string;

  /** @format double */
  startSRMP?: number;

  /** @format double */
  endSRMP?: number;
  travelDirection?: string;

  /** @format double */
  startLatitude?: number;

  /** @format double */
  startLongitude?: number;

  /** @format double */
  endLatitude?: number;

  /** @format double */
  endLongitude?: number;
  geoJson?: string;
}

export interface RouteTollStatus {
  hasTollLane?: boolean;
  tollRateType?: string;
}

export interface SnowFallDataModel {
  /** @format int32 */
  displayOrder?: number;

  /** @format int32 */
  year?: number;

  /** @format int32 */
  monthNum?: number;
  month?: string;

  /** @format double */
  avgNewSnowfallInches?: number;

  /** @format int32 */
  avgTotalSnowfallInches?: number;
  dailySnowFall?: DailySnowFallReadings[];
}

export interface SnowfallPassYears {
  /** @format int32 */
  mountainPassId?: number;
  passName?: string;
  startYear?: number[];
}

export interface StateRouteExit {
  stateRouteId?: string;

  /** @format double */
  srmp?: number;
  exitText?: string;
  direction?: string;
}

export interface StateRouteModel {
  /** @format int32 */
  displayOrder?: number;
  stateRouteId?: string;
  srCommonName?: string;
  increasingCommonName?: string;
  decreasingCommonName?: string;

  /** @format double */
  startMP?: number;

  /** @format double */
  endMP?: number;
  cities?: CityModel[];
}

export interface StationWeatherData {
  /** @format date-time */
  weatherReportTime?: string;
  tempInFarhenheit?: string;
  dewPoint?: string;
  relativeHumidity?: string;
  precipitation?: string;
  altm?: string;
  windSpeed?: string;
  windDirection?: string;
  windGustMph?: string;
  preci3to6HrPerCuIn?: string;
  ceiling?: string;
  sky?: string;
  surfaceTemp?: string;
}

export interface TollRouteInfo {
  /** @format int32 */
  tollRouteID?: number;
  stateRouteID?: string;
  tollRouteName?: string;
  tollRouteType?: string;

  /** @format double */
  startSRMP?: number;

  /** @format double */
  endSRMP?: number;
  travelDirection?: string;
  tollRateModel?: string;
}

export interface TravelInfoDetails {
  summary?: TravelInfoSummary;
  alerts?: AlertsModel[];
  cameras?: CameraModel[];
  restrictions?: ActiveVehicleRestrictionsMapService[];
  tollStatus?: RouteTollStatus;
}

export interface TravelInfoSummary {
  /** @format int32 */
  alertsCount?: number;

  /** @format int32 */
  camerasCount?: number;

  /** @format int32 */
  restrictionsCount?: number;
}

export interface TravelTimesModel {
  /** @format int32 */
  travelTimeID?: number;
  route?: string;

  /** @format double */
  distance?: number;

  /** @format int32 */
  averageTime?: number;

  /** @format int32 */
  currentTime?: number;

  /** @format int32 */
  hovCurrentTime?: number;

  /** @format int32 */
  hovAverageTime?: number;
  routeState?: string;
  expressLaneTitle?: string;

  /** @format int32 */
  expressLaneAverageTime?: number;

  /** @format int32 */
  expressLaneCurrentTime?: number;

  /** @format int32 */
  expressLaneHOVCurrentTime?: number;

  /** @format int32 */
  expressLaneHOVAverageTime?: number;
  coveredRoutes?: string;
  travelRegion?: string;

  /** @format int32 */
  displayOrderAll?: number;

  /** @format int32 */
  displayOrderDirection?: number;
  travelDirection?: string;

  /** @format date-time */
  timeUpdated?: string;
  commutePageAddress?: string;
  routeSegments?: RouteSegment[];
  routeLocation?: RouteLocation;
}

export interface VariableTollRateRoutes {
  stateRouteID?: string;
  tollRouteName?: string;
  tollRouteSignName?: string;
  travelDirection?: string;

  /** @format double */
  startSRMP?: number;

  /** @format double */
  endSRMP?: number;
  startLocationName?: string;
  endLocationName?: string;

  /** @format double */
  currentToll?: number;

  /** @format date-time */
  lastUpdated?: string;
}

export interface WSCounty {
  /** @format int32 */
  countyId?: number;
  countyName?: string;

  /** @format int32 */
  weatherStationCount?: number;
}

export interface WSZone {
  zoneId?: string;
  zoneName?: string;

  /** @format int32 */
  weatherStationCount?: number;
}

export interface WarningModel {
  nwsZoneId?: string;

  /** @format int32 */
  countyId?: number;
  locationName?: string;
  warningTypeCode?: string;
  warningTypeDescription?: string;
  warningText?: string;

  /** @format date-time */
  warningIssuedDate?: string;

  /** @format date-time */
  warningEndDate?: string;
}

export interface WeatherMapAreaData {
  /** @format int32 */
  stationId?: number;

  /** @format int32 */
  weatherNetworkId?: number;

  /** @format int32 */
  temperatureInF?: number;
  description?: string;

  /** @format int32 */
  northingY?: number;

  /** @format int32 */
  eastingX?: number;
  mapViewId?: string;
}

export interface WeatherStationDetailsModel {
  currentWeather?: WeatherStationWithForecastModel;

  /** @format int32 */
  defaultCameraId?: number;
  cameras?: CameraModel[];
  nearbyWeatherStations?: WeatherStationWithForecastModel[];
}

export interface WeatherStationModel {
  /** @format int32 */
  weatherStationId?: number;

  /** @format int32 */
  priority?: number;
  weatherStationCode?: string;
  weatherStationDescription?: string;

  /** @format int32 */
  elevationFeet?: number;

  /** @format int32 */
  elevationMeters?: number;

  /** @format date-time */
  weatherReportDateTime?: string;
  temperatureFarhenheit?: string;
  temperatureCelcius?: string;
  surfaceTemperature?: string;
  temperature24Low?: string;
  temperature24High?: string;
  dewPoint?: string;
  windSpeed?: string;
  windDirection?: string;
  barometricPressure?: string;
  relativeHumidity?: string;
  visibility?: string;

  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;
  precipitationAccumulated?: string;
  weatherIconDisplayName?: string;
  weatherIconFileName?: string;
  condition?: string;
  nwsZoneId?: string;

  /** @format int32 */
  countyId?: number;
  gridPointZoneId?: string;
}

export interface WeatherStationWithForecastModel {
  /** @format int32 */
  weatherStationId?: number;

  /** @format int32 */
  priority?: number;
  weatherStationCode?: string;
  weatherStationDescription?: string;

  /** @format int32 */
  elevationFeet?: number;

  /** @format int32 */
  elevationMeters?: number;

  /** @format date-time */
  weatherReportDateTime?: string;
  temperatureFarhenheit?: string;
  temperatureCelcius?: string;
  surfaceTemperature?: string;
  temperature24Low?: string;
  temperature24High?: string;
  dewPoint?: string;
  windSpeed?: string;
  windDirection?: string;
  barometricPressure?: string;
  relativeHumidity?: string;
  visibility?: string;

  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;
  precipitationAccumulated?: string;
  weatherIconDisplayName?: string;
  weatherIconFileName?: string;
  condition?: string;
  nwsZoneId?: string;

  /** @format int32 */
  countyId?: number;
  forecast?: ForecastModel;
  hasWeatherWarnings?: boolean;
}

export interface WorkZoneCounty {
  /** @format int32 */
  countyId?: number;
  countyName?: string;
}

export interface WorkZoneGeometry {
  /** @format int32 */
  workZoneId?: number;
  lrsTypeIndicator?: string;

  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;
}

export interface WorkZoneModel {
  /** @format int32 */
  workZoneId?: number;

  /** @format int32 */
  regionId?: number;
  workType?: string;
  title?: string;
  stateRouteId?: string;
  stateRouteName?: string;
  location?: string;

  /** @format double */
  startSRMP?: number;

  /** @format double */
  endSRMP?: number;
  roadDirection?: string;

  /** @format date-time */
  scheduleStartDate?: string;

  /** @format date-time */
  scheduleEndDate?: string;
  workDescription?: string;
  trafficImpact?: string;
  landmark?: string;
  laneClosures?: string;
  isMonday?: boolean;
  isTuesday?: boolean;
  isWednesday?: boolean;
  isThursday?: boolean;
  isFriday?: boolean;
  isSaturday?: boolean;
  isSunday?: boolean;

  /** @format date-time */
  lastUpdated?: string;

  /** @format int32 */
  countyId?: number;
}

export interface WorkZoneRegion {
  /** @format int32 */
  regionId?: number;
  regionCode?: string;
  regionName?: string;
}

export interface WorkZoneStateRoute {
  stateRouteId?: string;
  stateRouteName?: string;

  /** @format int32 */
  countyId?: number;
}

export interface WorkZoneType {
  /** @format int32 */
  workTypeId?: number;
  workType?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/travel/real-time/service";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Traveler Information Service
 * @version v2
 * @baseUrl /travel/real-time/service
 *
 * Asp.net core Web API for Travel Center Website [DEV]
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Alerts
     * @name AlertsList
     * @request GET:/api/Alerts
     */
    alertsList: (params: RequestParams = {}) =>
      this.request<AlertsModel[], any>({
        path: `/api/Alerts`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alerts
     * @name AlertsGetAlertsV2List
     * @request GET:/api/Alerts/GetAlertsV2
     */
    alertsGetAlertsV2List: (params: RequestParams = {}) =>
      this.request<AlertsModelV2[], any>({
        path: `/api/Alerts/GetAlertsV2`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alerts
     * @name AlertsGetLastUpdateList
     * @request GET:/api/Alerts/GetLastUpdate
     */
    alertsGetLastUpdateList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Alerts/GetLastUpdate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bridges
     * @name BridgesGetHoodCanalBridgeDetailsList
     * @request GET:/api/Bridges/GetHoodCanalBridgeDetails
     */
    bridgesGetHoodCanalBridgeDetailsList: (params: RequestParams = {}) =>
      this.request<HoodCanalBridgeDetails, any>({
        path: `/api/Bridges/GetHoodCanalBridgeDetails`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Camera
     * @name CameraList
     * @request GET:/api/Camera
     */
    cameraList: (params: RequestParams = {}) =>
      this.request<CameraListModel[], any>({
        path: `/api/Camera`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Camera
     * @name CameraDetail
     * @request GET:/api/Camera/{CameraId}
     */
    cameraDetail: (cameraId: number, params: RequestParams = {}) =>
      this.request<CameraModel, any>({
        path: `/api/Camera/${cameraId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Camera
     * @name CameraGetLastUpdateList
     * @request GET:/api/Camera/GetLastUpdate
     */
    cameraGetLastUpdateList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Camera/GetLastUpdate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommercialVehicles
     * @name CommercialVehiclesList
     * @request GET:/api/CommercialVehicles
     */
    commercialVehiclesList: (params: RequestParams = {}) =>
      this.request<ActiveVehicleRestrictionsMapService[], any>({
        path: `/api/CommercialVehicles`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CommercialVehicles
     * @name CommercialVehiclesGetLastUpdateList
     * @request GET:/api/CommercialVehicles/GetLastUpdate
     */
    commercialVehiclesGetLastUpdateList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/CommercialVehicles/GetLastUpdate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Forecast
     * @name ForecastList
     * @request GET:/api/Forecast
     */
    forecastList: (params: RequestParams = {}) =>
      this.request<ForecastModel[], any>({
        path: `/api/Forecast`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Forecast
     * @name ForecastDetail
     * @request GET:/api/Forecast/{NWSZoneId}
     */
    forecastDetail: (nwsZoneId: string, params: RequestParams = {}) =>
      this.request<ForecastModel, any>({
        path: `/api/Forecast/${nwsZoneId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Forecast
     * @name ForecastDetail2
     * @request GET:/api/Forecast/{NWSZoneId}/{NextNDays}
     * @originalName forecastDetail
     * @duplicate
     */
    forecastDetail2: (nwsZoneId: string, nextNDays: number, params: RequestParams = {}) =>
      this.request<ForecastModel, any>({
        path: `/api/Forecast/${nwsZoneId}/${nextNDays}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Forecast
     * @name ForecastSummaryDetail
     * @request GET:/api/ForecastSummary/{NWSZoneId}
     */
    forecastSummaryDetail: (nwsZoneId: string, params: RequestParams = {}) =>
      this.request<ForecastSummaryModel[], any>({
        path: `/api/ForecastSummary/${nwsZoneId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Forecast
     * @name ForecastSummaryDetail2
     * @request GET:/api/ForecastSummary/{NWSZoneId}/{NextNDays}
     * @originalName forecastSummaryDetail
     * @duplicate
     */
    forecastSummaryDetail2: (nwsZoneId: string, nextNDays: number, params: RequestParams = {}) =>
      this.request<ForecastSummaryModel[], any>({
        path: `/api/ForecastSummary/${nwsZoneId}/${nextNDays}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MountainPass
     * @name MountainPassList
     * @request GET:/api/MountainPass
     */
    mountainPassList: (params: RequestParams = {}) =>
      this.request<MountainPassReportModel[], any>({
        path: `/api/MountainPass`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MountainPass
     * @name MountainPassDetailsDetail
     * @request GET:/api/MountainPass/Details/{MountainPassId}
     */
    mountainPassDetailsDetail: (mountainPassId: number, params: RequestParams = {}) =>
      this.request<MountainPassDetailsModel, any>({
        path: `/api/MountainPass/Details/${mountainPassId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MountainPass
     * @name MountainPassLastUpdateList
     * @request GET:/api/MountainPass/LastUpdate
     */
    mountainPassLastUpdateList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/MountainPass/LastUpdate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MountainPass
     * @name MountainPassSnowFallPassYearsList
     * @request GET:/api/MountainPass/SnowFallPassYears
     */
    mountainPassSnowFallPassYearsList: (params: RequestParams = {}) =>
      this.request<SnowfallPassYears[], any>({
        path: `/api/MountainPass/SnowFallPassYears`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MountainPass
     * @name MountainPassSnowFallDataList
     * @request GET:/api/MountainPass/SnowFallData
     */
    mountainPassSnowFallDataList: (query?: { MountainPassId?: number; Year?: number }, params: RequestParams = {}) =>
      this.request<SnowFallDataModel[], any>({
        path: `/api/MountainPass/SnowFallData`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PassAlert
     * @name PassAlertSmsRequestCreate
     * @request POST:/api/PassAlert/SMSRequest
     */
    passAlertSmsRequestCreate: (
      data: {
        MessageSid?: string;
        SmsSid?: string;
        Body?: string;
        MessageStatus?: string;
        OptOutType?: string;
        MessagingServiceSid?: string;
        NumMedia?: number;
        ReferralNumMedia?: number;
        AccountSid?: string;
        From?: string;
        To?: string;
        FromCity?: string;
        FromState?: string;
        FromZip?: string;
        FromCountry?: string;
        ToCity?: string;
        ToState?: string;
        ToZip?: string;
        ToCountry?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/PassAlert/SMSRequest`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PassAlert
     * @name PassAlertNotifyDeliveryStatusCreate
     * @request POST:/api/PassAlert/NotifyDeliveryStatus
     */
    passAlertNotifyDeliveryStatusCreate: (
      data: {
        AccountSid?: string;
        ApiVersion?: string;
        From?: string;
        MessageSid?: string;
        SmsSid?: string;
        SmsStatus?: string;
        To?: string;
        CreatedDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/PassAlert/NotifyDeliveryStatus`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RestArea
     * @name RestAreaList
     * @request GET:/api/RestArea
     */
    restAreaList: (params: RequestParams = {}) =>
      this.request<RestAreaModel[], any>({
        path: `/api/RestArea`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RestArea
     * @name RestAreaGetAmenitiesList
     * @request GET:/api/RestArea/GetAmenities
     */
    restAreaGetAmenitiesList: (params: RequestParams = {}) =>
      this.request<Amenities[], any>({
        path: `/api/RestArea/GetAmenities`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TollInfo
     * @name TollInfoGetCurrentTollRatesList
     * @request GET:/api/TollInfo/GetCurrentTollRates
     */
    tollInfoGetCurrentTollRatesList: (params: RequestParams = {}) =>
      this.request<VariableTollRateRoutes[], any>({
        path: `/api/TollInfo/GetCurrentTollRates`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TollInfo
     * @name TollInfoGetFixedTollRatesList
     * @request GET:/api/TollInfo/GetFixedTollRates
     */
    tollInfoGetFixedTollRatesList: (params: RequestParams = {}) =>
      this.request<FixedTollRateRoutes[], any>({
        path: `/api/TollInfo/GetFixedTollRates`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TollInfo
     * @name TollInfoGetTollRouteInfoList
     * @request GET:/api/TollInfo/GetTollRouteInfo
     */
    tollInfoGetTollRouteInfoList: (params: RequestParams = {}) =>
      this.request<TollRouteInfo[], any>({
        path: `/api/TollInfo/GetTollRouteInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TollInfo
     * @name TollInfoRouteHasTollLaneDetail
     * @request GET:/api/TollInfo/RouteHasTollLane/{stateRouteId}/{startMilePost}/{endMilePost}
     */
    tollInfoRouteHasTollLaneDetail: (
      stateRouteId: string,
      startMilePost: number,
      endMilePost: number,
      params: RequestParams = {},
    ) =>
      this.request<RouteTollStatus, any>({
        path: `/api/TollInfo/RouteHasTollLane/${stateRouteId}/${startMilePost}/${endMilePost}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetStateRoutesList
     * @request GET:/api/GetStateRoutes
     */
    getStateRoutesList: (params: RequestParams = {}) =>
      this.request<StateRouteModel[], any>({
        path: `/api/GetStateRoutes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetCitiesDetail
     * @request GET:/api/GetCities/{stateRouteId}
     */
    getCitiesDetail: (stateRouteId: string, params: RequestParams = {}) =>
      this.request<CityModel[], any>({
        path: `/api/GetCities/${stateRouteId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetTravelInfoList
     * @request GET:/api/GetTravelInfo
     */
    getTravelInfoList: (params: RequestParams = {}) =>
      this.request<TravelInfoSummary, any>({
        path: `/api/GetTravelInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetTravelInfoDetail
     * @request GET:/api/GetTravelInfo/{stateRouteId}/{startCity}/{endCity}
     */
    getTravelInfoDetail: (stateRouteId: string, startCity: string, endCity: string, params: RequestParams = {}) =>
      this.request<TravelInfoDetails, any>({
        path: `/api/GetTravelInfo/${stateRouteId}/${startCity}/${endCity}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetTravelInfoDetail2
     * @request GET:/api/GetTravelInfo/{stateRouteId}/{startMilePost}/{endMilePost}
     * @originalName getTravelInfoDetail
     * @duplicate
     */
    getTravelInfoDetail2: (
      stateRouteId: string,
      startMilePost: number,
      endMilePost: number,
      params: RequestParams = {},
    ) =>
      this.request<TravelInfoDetails, any>({
        path: `/api/GetTravelInfo/${stateRouteId}/${startMilePost}/${endMilePost}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetTravelTimeInfoList
     * @request GET:/api/GetTravelTimeInfo
     */
    getTravelTimeInfoList: (params: RequestParams = {}) =>
      this.request<TravelTimesModel[], any>({
        path: `/api/GetTravelTimeInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetFerryTerminalsList
     * @request GET:/api/GetFerryTerminals
     */
    getFerryTerminalsList: (params: RequestParams = {}) =>
      this.request<FerryTerminal[], any>({
        path: `/api/GetFerryTerminals`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetFerryTerminalAlertsList
     * @request GET:/api/GetFerryTerminalAlerts
     */
    getFerryTerminalAlertsList: (params: RequestParams = {}) =>
      this.request<FerryTerminalAlerts[], any>({
        path: `/api/GetFerryTerminalAlerts`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetFerryRoutesList
     * @request GET:/api/GetFerryRoutes
     */
    getFerryRoutesList: (params: RequestParams = {}) =>
      this.request<FerryRouteInfo[], any>({
        path: `/api/GetFerryRoutes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetFerryRouteAlertsList
     * @request GET:/api/GetFerryRouteAlerts
     */
    getFerryRouteAlertsList: (params: RequestParams = {}) =>
      this.request<FerryRouteAlert[], any>({
        path: `/api/GetFerryRouteAlerts`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetFerryAlertsLastUpdateList
     * @request GET:/api/GetFerryAlertsLastUpdate
     */
    getFerryAlertsLastUpdateList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/GetFerryAlertsLastUpdate`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetStateRouteExitsList
     * @request GET:/api/GetStateRouteExits
     */
    getStateRouteExitsList: (params: RequestParams = {}) =>
      this.request<StateRouteExit[], any>({
        path: `/api/GetStateRouteExits`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetStateRouteExitsDetail
     * @request GET:/api/GetStateRouteExits/{stateRouteId}
     */
    getStateRouteExitsDetail: (stateRouteId: string, params: RequestParams = {}) =>
      this.request<StateRouteExit[], any>({
        path: `/api/GetStateRouteExits/${stateRouteId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetBorderCrossingInfoList
     * @request GET:/api/GetBorderCrossingInfo
     */
    getBorderCrossingInfoList: (params: RequestParams = {}) =>
      this.request<BorderCrossingInfo[], any>({
        path: `/api/GetBorderCrossingInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetAdControlStatusList
     * @request GET:/api/GetAdControlStatus
     */
    getAdControlStatusList: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/GetAdControlStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TravelInfo
     * @name GetDrupalAlertEventStatusList
     * @request GET:/api/GetDrupalAlertEventStatus
     */
    getDrupalAlertEventStatusList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/GetDrupalAlertEventStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Warning
     * @name WarningList
     * @request GET:/api/Warning
     */
    warningList: (params: RequestParams = {}) =>
      this.request<WarningModel[], any>({
        path: `/api/Warning`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Warning
     * @name WarningDetail
     * @request GET:/api/Warning/{NWSZoneId}/{CountyId}
     */
    warningDetail: (nwsZoneId: string, countyId: number, params: RequestParams = {}) =>
      this.request<WarningModel[], any>({
        path: `/api/Warning/${nwsZoneId}/${countyId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Warning
     * @name WarningDetail2
     * @request GET:/api/Warning/{StationId}
     * @originalName warningDetail
     * @duplicate
     */
    warningDetail2: (stationId: number, params: RequestParams = {}) =>
      this.request<WarningModel[], any>({
        path: `/api/Warning/${stationId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weather
     * @name WeatherList
     * @request GET:/api/Weather
     */
    weatherList: (params: RequestParams = {}) =>
      this.request<WeatherStationModel[], any>({
        path: `/api/Weather`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weather
     * @name WeatherGetStationDetail
     * @request GET:/api/Weather/GetStation/{StationId}
     */
    weatherGetStationDetail: (stationId: number, params: RequestParams = {}) =>
      this.request<WeatherStationModel, any>({
        path: `/api/Weather/GetStation/${stationId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weather
     * @name WeatherGetWeatherStationForecastList
     * @request GET:/api/Weather/GetWeatherStationForecast
     */
    weatherGetWeatherStationForecastList: (params: RequestParams = {}) =>
      this.request<WeatherStationWithForecastModel[], any>({
        path: `/api/Weather/GetWeatherStationForecast`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weather
     * @name WeatherGetWeatherStationDetailsDetail
     * @request GET:/api/Weather/GetWeatherStationDetails/{StationId}
     */
    weatherGetWeatherStationDetailsDetail: (stationId: number, params: RequestParams = {}) =>
      this.request<WeatherStationDetailsModel, any>({
        path: `/api/Weather/GetWeatherStationDetails/${stationId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weather
     * @name WeatherGetRecentWeatherDataDetail
     * @request GET:/api/Weather/GetRecentWeatherData/{StationId}
     */
    weatherGetRecentWeatherDataDetail: (stationId: number, params: RequestParams = {}) =>
      this.request<RecentWeatherDataModel, any>({
        path: `/api/Weather/GetRecentWeatherData/${stationId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weather
     * @name WeatherGetWeatherMapAreaDataList
     * @request GET:/api/Weather/GetWeatherMapAreaData
     */
    weatherGetWeatherMapAreaDataList: (params: RequestParams = {}) =>
      this.request<WeatherMapAreaData[], any>({
        path: `/api/Weather/GetWeatherMapAreaData`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weather
     * @name WeatherGetAllCountiesList
     * @request GET:/api/Weather/GetAllCounties
     */
    weatherGetAllCountiesList: (params: RequestParams = {}) =>
      this.request<WSCounty[], any>({
        path: `/api/Weather/GetAllCounties`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Weather
     * @name WeatherGetAreaZonesList
     * @request GET:/api/Weather/GetAreaZones
     */
    weatherGetAreaZonesList: (params: RequestParams = {}) =>
      this.request<WSZone[], any>({
        path: `/api/Weather/GetAreaZones`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkZones
     * @name WorkZonesList
     * @request GET:/api/WorkZones
     */
    workZonesList: (params: RequestParams = {}) =>
      this.request<WorkZoneModel[], any>({
        path: `/api/WorkZones`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkZones
     * @name WorkZoneRegionsList
     * @request GET:/api/WorkZoneRegions
     */
    workZoneRegionsList: (params: RequestParams = {}) =>
      this.request<WorkZoneRegion[], any>({
        path: `/api/WorkZoneRegions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkZones
     * @name WorkZoneCountiesList
     * @request GET:/api/WorkZoneCounties
     */
    workZoneCountiesList: (params: RequestParams = {}) =>
      this.request<WorkZoneCounty[], any>({
        path: `/api/WorkZoneCounties`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkZones
     * @name WorkZoneStateRoutesList
     * @request GET:/api/WorkZoneStateRoutes
     */
    workZoneStateRoutesList: (params: RequestParams = {}) =>
      this.request<WorkZoneStateRoute[], any>({
        path: `/api/WorkZoneStateRoutes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkZones
     * @name WorkZoneTypesList
     * @request GET:/api/WorkZoneTypes
     */
    workZoneTypesList: (params: RequestParams = {}) =>
      this.request<WorkZoneType[], any>({
        path: `/api/WorkZoneTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkZones
     * @name GetWorkZonesDetail
     * @request GET:/api/GetWorkZones/{stateRouteId}/{startCity}/{endCity}/{fromDate}/{toDate}
     */
    getWorkZonesDetail: (
      stateRouteId: string,
      startCity: string,
      endCity: string,
      fromDate: string,
      toDate: string,
      params: RequestParams = {},
    ) =>
      this.request<WorkZoneModel[], any>({
        path: `/api/GetWorkZones/${stateRouteId}/${startCity}/${endCity}/${fromDate}/${toDate}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkZones
     * @name GetWorkZonesByLocationDetail
     * @request GET:/api/GetWorkZonesByLocation/{sourceLat}/{sourceLng}/{findRadius}/{fromDate}/{toDate}
     */
    getWorkZonesByLocationDetail: (
      sourceLat: number,
      sourceLng: number,
      findRadius: number,
      fromDate: string,
      toDate: string,
      params: RequestParams = {},
    ) =>
      this.request<WorkZoneModel[], any>({
        path: `/api/GetWorkZonesByLocation/${sourceLat}/${sourceLng}/${findRadius}/${fromDate}/${toDate}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WorkZones
     * @name GetWorkZoneGeometryList
     * @request GET:/api/GetWorkZoneGeometry
     */
    getWorkZoneGeometryList: (params: RequestParams = {}) =>
      this.request<WorkZoneGeometry[], any>({
        path: `/api/GetWorkZoneGeometry`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
