<template>
	<div class="card">
		<div class="card-tags-header" v-if="bannerText">
			<div class="card-banner">
				<div class="card-banner-text">{{ bannerText }}</div>
				<div class="card-banner-img">
					<v-icon :width="18" :height="18" :name="iconName" aria-hidden="true" />
				</div>
			</div>
			<div class="card-banner-priority" v-if="bannerTag">
				<div class="card-banner-priority-text">
					{{ bannerTag }}
				</div>
			</div>
		</div>
		<div class="card-content-wrapper">
			<slot></slot>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'
	import VIcon from '@/components/UI/VIcon.vue'

	export default defineComponent({
		components: {
			VIcon
		},
		props: {
			bannerText: {
				type: String,
				required: true
			},
			iconName: {
				type: String,
				required: true
			},
			bannerTag: {
				type: String,
				required: false
			}
		},
		name: 'GenericCard'
	})
</script>

<style scoped lang="scss">
	.card {
		flex-direction: column;
		align-items: flex-start;
		padding: 8px 0px;
		position: static;
		width: 626px;
		left: 0px;
		top: 0px;
		background: $White;
		border: 1px solid $Gray10;
		box-sizing: border-box;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
		border-radius: 4px;
		order: 0;
		flex-grow: 0;
		margin: 8px 0px;

		@include sm {
			margin: 8px 10px;
			box-sizing: border-box;
			width: calc(100% - 20px);
		}

		.card-tags-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 8px 0 0 0;
			position: static;
			width: 129px;
			height: 26px;
			left: 0px;
			top: 8px;
			flex: none;
			order: 0;
			flex-grow: 0;
			margin: 0;

			.card-banner {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 4px 8px;
				position: static;
				height: 26px;
				left: 0px;
				top: 0px;
				box-sizing: border-box;
				border-radius: 0px 4px 4px 0px;
				flex: none;
				order: 0;
				flex-grow: 0;
				margin: 0px;

				.card-banner-text {
					position: static;
					height: 16px;
					left: 28px;
					top: 4px;
					font-family: Lato;
					font-style: normal;
					font-weight: bold;
					font-size: 12.8px;
					line-height: 16px;
					color: $Gray100;
					display: flex;
					flex: none;
					order: 1;
					flex-grow: 0;
					margin: 0px 4px;
				}

				.card-banner-img {
					position: static;
					width: 16px;
					height: 16px;
					left: 8px;
					top: 4px;
					flex: none;
					order: 0;
					flex-grow: 0;
					line-height: 16px;
					margin: 0px 4px;
				}
			}

			.card-banner-priority {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 4px 6px;
				position: static;
				left: 124px;
				top: 0px;
				height: 26px;
				box-sizing: border-box;
				border-radius: 4px;
				flex: none;
				order: 1;
				flex-grow: 0;
				margin: 0px 0px 0px 8px;
				font-weight: 700;

				.card-banner-priority-text {
					position: static;
					left: 6px;
					top: 4px;
					font-size: 12.8px;
					line-height: 16px;
					color: $Gray100;
					flex: none;
					order: 0;
					flex-grow: 0;
					margin: 0px 0px;
				}
			}
		}

		.card-content-wrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 8px 16px;
			flex: none;
			order: 1;
			align-self: stretch;
			flex-grow: 0;
			margin: 0;
			position: static;
			top: 32px;
			left: 0px;

			@include sm {
				width: 100%;
			}
		}
	}

	// for showing weather alert on the weather station & details page
	.card.weather-alert {
		.card-tags-header {
			.card-banner {
				background-color: rgba($WeatherBlue, 0.2);
				border: 1px solid $WeatherBlue;

				.card-banner-text {
					position: static;
				}

				.card-banner-img {
					fill: $WeatherBlue;
				}
			}
		}
	}

</style>
