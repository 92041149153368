<template>
	<svg :width="width" :height="height" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M10.8293 7.5C10.4175 8.66519 9.30622 9.5 8 9.5C6.69378 9.5 5.58254 8.66519 5.17071 7.5H3V5.5H5.17071C5.58254 4.33481 6.69378 3.5 8 3.5C9.30622 3.5 10.4175 4.33481 10.8293 5.5H21V7.5H10.8293ZM7.17071 19.5H3V17.5H7.17071C7.58254 16.3348 8.69378 15.5 10 15.5C11.3062 15.5 12.4175 16.3348 12.8293 17.5H21V19.5H12.8293C12.4175 20.6652 11.3062 21.5 10 21.5C8.69378 21.5 7.58254 20.6652 7.17071 19.5ZM21 13.5H18.8293C18.4175 14.6652 17.3062 15.5 16 15.5C14.6938 15.5 13.5825 14.6652 13.1707 13.5H3V11.5H13.1707C13.5825 10.3348 14.6938 9.5 16 9.5C17.3062 9.5 18.4175 10.3348 18.8293 11.5H21V13.5Z" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'FilterIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
