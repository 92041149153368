<template>
	<svg :width="width" :height="height" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
		<path d="M18 9.12807C18 14.1694 13.9706 18.2561 9 18.2561C4.02944 18.2561 0 14.1694 0 9.12807C0 4.08678 4.02944 0 9 0C13.9706 0 18 4.08678 18 9.12807Z" />
		<circle cx="9" cy="9" r="7.75" stroke="white" stroke-width="0.5" />
		<path d="M12.7837 11.2118C12.3534 11.2118 12.0045 11.5609 12.0045 11.991C12.0045 12.4216 12.3534 12.7704 12.7837 12.7704C13.2142 12.7704 13.5633 12.4214 13.5633 11.991C13.5633 11.5607 13.2142 11.2118 12.7837 11.2118Z" fill="white" />
		<path d="M5.89831 11.2118C5.46798 11.2118 5.1189 11.5609 5.1189 11.991C5.1189 12.4216 5.46798 12.7704 5.89831 12.7704C6.32864 12.7704 6.67773 12.4214 6.67773 11.991C6.67773 11.5607 6.32864 11.2118 5.89831 11.2118Z" fill="white" />
		<path d="M10.4122 6.33333H3.9683C3.80252 6.33333 3.66667 6.48885 3.66667 6.67933V11.3851C3.66667 11.5755 3.80252 11.7311 3.9683 11.7311H4.44953C4.44241 11.7815 4.43739 11.8326 4.43571 11.8845C4.43488 11.9096 4.4443 11.9339 4.46167 11.9521C4.47904 11.9701 4.50312 11.9806 4.52823 11.9806H4.8171C4.86671 11.9806 4.90731 11.9416 4.90962 11.8922C4.91255 11.8292 4.92155 11.7679 4.93557 11.7091C5.05781 11.2827 5.44987 10.9706 5.91561 10.9706C6.122 10.9706 6.31416 11.0319 6.47491 11.1374C6.71103 11.3076 6.86906 11.5799 6.88351 11.8922C6.88602 11.9416 6.92663 11.9806 6.97603 11.9806H7.26489C7.2651 11.9806 7.26531 11.9806 7.26552 11.9806C7.3168 11.9806 7.35825 11.9389 7.35825 11.8879C7.35825 11.8826 7.35783 11.8776 7.35678 11.8728C7.35469 11.8248 7.35029 11.7775 7.34359 11.7311H10.412C10.578 11.7311 10.7137 11.5755 10.7137 11.3851V6.67933C10.7139 6.48885 10.5782 6.33333 10.4122 6.33333Z" fill="white" />
		<path d="M14.8796 9.50722L14.2876 9.29748C14.126 9.24034 13.9488 9.07769 13.8981 8.90312C13.865 8.78925 13.4545 7.57121 13.4545 7.57121C13.4181 7.38366 13.2477 7.25912 13.0531 7.26184C12.9737 7.26184 11.259 7.26498 11.259 7.26498C11.1849 7.26498 11.1158 7.28779 11.0589 7.32694C11.0476 7.33636 11.0281 7.3485 11.0191 7.35875C10.9477 7.42406 10.9138 7.51763 10.9138 7.62145V7.93187V11.0637V11.3744C10.9138 11.5705 11.0859 11.731 11.282 11.731H11.3392C11.3322 11.7815 11.3274 11.8326 11.3256 11.8845C11.3245 11.9096 11.3341 11.9339 11.3515 11.9521C11.3691 11.9701 11.3932 11.9805 11.4181 11.9805H11.7069C11.7563 11.9805 11.7974 11.9416 11.7995 11.8922C11.8062 11.7451 11.8453 11.6069 11.9089 11.4838C12.0848 11.1774 12.4149 10.9706 12.7937 10.9706C13.0709 10.9706 13.3214 11.0815 13.5052 11.2605C13.6618 11.4265 13.7618 11.6469 13.7731 11.8924C13.7756 11.9418 13.8163 11.9808 13.8659 11.9808H14.1545C14.2058 11.9808 14.2479 11.9391 14.2479 11.888C14.2479 11.8828 14.2475 11.8778 14.2464 11.873C14.2443 11.825 14.2399 11.7777 14.2332 11.7312C14.2667 11.7312 14.3004 11.7312 14.3339 11.7312C14.4409 11.7312 14.5443 11.7312 14.6261 11.7312H14.6393H14.8587C15.0548 11.7312 15.2151 11.5707 15.2151 11.3746V9.98321C15.2158 9.78687 15.0644 9.57294 14.8796 9.50722Z" fill="white" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'TruckIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
