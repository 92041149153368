<template>
	<base-button @click="onClick" :aria-label="buttonLabel">
		<template v-if="iconPosition === 'Left'">
			<v-icon class="icon icon-left" :name="iconName" :width="iconWidth" :height="iconHeight" />
		</template>
		<span>{{ buttonLabel }}</span>
		<template v-if="iconPosition === 'Right'">
			<v-icon class="icon icon-right" :name="iconName" :width="iconWidth" :height="iconHeight" />
		</template>

	</base-button>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'
	import BaseButton from '@/components/UI/BaseButton.vue'
	import VIcon from '@/components/UI/VIcon.vue'

	export default defineComponent({
		name: 'VButton',
		components: {
			BaseButton,
			VIcon
		},
		emits: ['click'],
		props: {
			iconName: {
				type: String,
				required: true
			},
			buttonLabel: {
				type: String,
				required: false
			},
			iconPosition: {
				type: String,
				required: false,
				default: 'Left'
			},
			iconWidth: {
				type: Number,
				required: false,
				default: 16
			},
			iconHeight: {
				type: Number,
				required: false,
				default: 16
			}
		},
		setup(_, { emit }) {
			const onClick = () => {
				emit('click')
			}

			return {
				onClick
			}
		}
	})
</script>

<style lang="scss">
	.icon {
		align-self: center;
	}
</style>
