<template>
	<div role="alert" v-if="canShowAlertBanner" class="wrapper-alert js-quickedit-main-content">
		<div class="row">
			<section class="container">
				<div class="region region-alert">
					<section id="block-alertbanner" class="block-alert block block-block-content block-block-contentf42e05b5-1307-4934-9f32-0c82e035fe5f clearfix">
						<div class="field field--name-body field--type-text-with-summary field--label-hidden field--item">
							<div class="WordSection1">
							<!-- Check our real-time map for the latest weather-related travel impacts. -->
							<h2><span>{{ alertBanner.bannerText }}</span></h2>
							</div>
							<!-- Freezing rain in Western Washington is making for very difficult travel conditions. We'll keep you updated via our -->
							<div v-html="alertBanner.details" />
						</div>
					</section>
				</div>
			</section>
		</div>
	</div>
	<header id="header">
		<div class="header-wrapper">
			<div class="logo-container">
				<div class="region region-header">
					<a class="logo navbar-btn pull-left" href="https://wsdot.wa.gov/" title="Home" rel="home"><img src="/img/wsdot-logo-white.svg" alt="Home"></a>
					<a class="name navbar-brand" href="https://wsdot.wa.gov/" title="Home" rel="home">WSDOT</a>
					<section id="block-externalsearchsearchformblock" class="contextual-region block block-wsdot-external-search-forms block-external-search-form-block clearfix">
						<form action="https://wsdot.wa.gov/search" method="GET" id="ext-search" class="form-inline external-search-form" data-drupal-form-fields="ext-search-input">
							<div class="form-group">
								<label class="sr-only">Search</label>
								<input type="text" name="q" class="form-control" placeholder="Search" id="ext-search-input">
								<button type="submit" class="btn search-form__submit"><span class="sr-only">Search</span></button>
							</div>
						</form>
					</section>
				</div>
			</div>
		</div>
	</header>
	<div class="nav-outer-wrapper">
		<div class="navbar navbar-default container" id="navbar" role="navigation">
			<div class="nav-tabs break-lg-control" aria-hidden="false">
				<nav aria-describedby="block-mainnavigation-8-menu">
					<h2 class="sr-only menu-heading" id="block-mainnavigation-8-menu">Main navigation</h2>
					<div class="container-fluid" id="drupal-menu">
						<ul class="menu menu--main nav">
							<li class="active active-trail first">
								<a href="https://wsdot.wa.gov/travel" class="active-trail is-active" data-drupal-link-system-path="node/403">Travel</a>
							</li>
							<li>
								<a href="https://wsdot.wa.gov/construction-planning" data-drupal-link-system-path="node/404">Construction &amp; planning</a>
							</li>
							<li>
								<a href="https://wsdot.wa.gov/business-wsdot" data-drupal-link-system-path="node/405">Business with WSDOT</a>
							</li>
							<li>
								<a href="https://wsdot.wa.gov/engineering-standards" data-drupal-link-system-path="node/406">Engineering &amp; standards</a>
							</li>
							<li class="last">
								<a href="https://wsdot.wa.gov/about" data-drupal-link-system-path="node/53">About</a>
							</li>
							<!-- <li>
								<a href="https://wsdot.wa.gov/about/seasonal-events-programs/give-em-brake" data-drupal-link-system-path="node/87">We&#039;re orange for safety</a>
							</li> -->
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
	<noscript>
		<strong>We're sorry but the WSDOT Travel Center doesn't work properly without JavaScript enabled. Please enable it to continue.</strong>
	</noscript>
	<div id="breadcrumb" class="region region-content-top">
		<Breadcrumbs></Breadcrumbs>
	</div>
	<div class="page-ads" v-if="adControlStatus">
		<AdSlot adUnit='/22447621233/WSDOT:driving-text:responsive' />
	</div>
	<router-view></router-view>
	<div class="footer-wrapper">
		<footer class="footer">
			<div class="container">
				<div class="region region-footer">
					<section id="block-footer1" class="col-lg-2 col-md-2 col-sm-6 col-xs-12 block block-block-content block-block-content056b448f-d00e-4781-b2d6-5903107de9ee clearfix">
						<div class="field field--name-body field--type-text-with-summary field--label-hidden field--item">
							<ul>
								<li><a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="922fd19c-f215-485e-a60b-d0c580ce13a4" href="https://wsdot.wa.gov/about/contacts" title="Contacts">Contact</a></li>
								<li><a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="8e6092c7-7d72-4caa-b088-5d20e7e47dec" href="https://wsdot.wa.gov/about/disability-access-concerns-ada" title="Disability access concerns (ADA)">Disability access concerns</a></li>
								<li><a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="974dfa0c-0e45-41f8-b995-dfada9fac536" href="https://wsdot.wa.gov/about/title-vilimited-english-proficiency" title="Title VI/Limited English Proficiency">Title VI</a></li>
								<li><a href="https://wsdot.wa.gov/sitemap">Site index</a></li>
							</ul>
						</div>
					</section>
					<section id="block-footer2" class="col-lg-4 col-md-4 col-sm-6 col-xs-12 block block-block-content block-block-contentd6f7d4a7-99f3-475f-85ec-c1f97dcc99e4 clearfix">
						<div class="field field--name-body field--type-text-with-summary field--label-hidden field--item">
							<p class="update">&nbsp;<span class="fontawesome-icon-inline"><span class="fas fa-envelope"></span>&nbsp;</span><strong><a href="https://public.govdelivery.com/accounts/WADOT/subscriber/new">Sign up for email updates</a></strong></p>
							<p>Subscribe to customized email alerts to help you know before you go. Receive current traffic conditions, mountain pass reports, construction updates and more.</p>
						</div>
					</section>
					<section id="block-footer3" class="col-lg-2 col-md-2 col-sm-5 col-xs-6 block block-block-content block-block-contentb863acea-75cf-4d12-90c4-5c82a36558bb clearfix">
						<div class="field field--name-body field--type-text-with-summary field--label-hidden field--item">
							<ul>
								<li><a href="/travel/travel-information-phone" class="ext" data-extlink="" rel="noreferrer">WSDOT Traffic App<span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://access.wa.gov/" class="ext" data-extlink="" rel="noreferrer">Access Washington<span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://www.governor.wa.gov/" class="ext" data-extlink="" rel="noreferrer">Office of the Governor<span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://wstc.wa.gov/" class="ext" data-extlink="" rel="noreferrer">Transportation Commission<span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://mygoodtogo.com/" class="ext" data-extlink="" rel="noreferrer">Good To Go! Tolling<span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
							</ul>
						</div>
					</section>
					<section id="block-footer4" class="col-lg-4 col-md-4 col-sm-5 col-xs-5 block block-block-content block-block-content80cdc4af-54f5-4f24-9819-27bf6eaf6232 clearfix">
						<div class="field field--name-body field--type-text-with-summary field--label-hidden field--item">
							<ul>
								<li><a href="https://www.facebook.com/wsdot" class="ext" data-extlink="" rel="noreferrer"><span class="fontawesome-icon-inline"><span class="fab fa-facebook-square"></span></span><span class="social-text">Facebook</span><span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://twitter.com/wsdot" class="ext" data-extlink="" rel="noreferrer"><span class="fontawesome-icon-inline"><span class="fab fa-twitter-square"></span></span><span class="social-text">Twitter</span><span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://www.instagram.com/wsdot/" class="ext" data-extlink="" rel="noreferrer"><span class="fontawesome-icon-inline"><span class="fab fa-instagram"></span></span><span class="social-text">Instagram</span><span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://www.linkedin.com/company/washington-state-department-of-transportation" class="ext" data-extlink="" rel="noreferrer"><span class="fontawesome-icon-inline"><span class="fab fa-linkedin"></span></span><span class="social-text">LinkedIn</span><span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://www.youtube.com/wsdot" class="ext" data-extlink="" rel="noreferrer"><span class="fontawesome-icon-inline"><span class="fab fa-youtube-square"></span></span><span class="social-text">YouTube</span><span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://wsdotblog.blogspot.com/" class="ext" data-extlink="" rel="noreferrer"><span class="fontawesome-icon-inline"><span class="fab fa-blogger"></span></span><span class="social-text">WSDOT Blog</span><span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://www.flickr.com/photos/wsdot/" class="ext" data-extlink="" rel="noreferrer"><span class="fontawesome-icon-inline"><span class="fab fa-flickr"></span></span><span class="social-text">WSDOT Flickr</span><span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
								<li><a href="https://wsdot.wa.gov/about/news-rss.xml" class="ext" data-extlink="" rel="noreferrer"><span class="fontawesome-icon-inline"><span class="fas fa-rss-square"></span></span><span class="social-text">RSS Feed</span><span class="fa-ext extlink"><span class="fa fa-external-link" aria-label="(link is external)"></span></span></a></li>
							</ul>
						</div>
					</section>
				</div>
			</div>
		</footer>
	</div>
	<div class="footer-bottom">
		<div class="container">
			<div class="region region-footer-bottom">
				<section id="block-copyright" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 block block-block-content block-block-content89f91154-e9a9-45d2-a31c-51ea97610cb2 clearfix">
					<div class="field field--name-body field--type-text-with-summary field--label-hidden field--item">
						<div class="align-left">
							<div class="field field--name-field-media-image field--type-image field--label-visually_hidden">
								<div class="field--label sr-only">Image</div>
								<div class="field--item" style="padding-top: 2px;">
									<img src="/img/wsdot-logo-white.svg" alt="WSDOT Logo" class="wsdot-logo-footer"/>
								</div>
							</div>
						</div>
						<ul>
							<li>Copyright WSDOT &copy; </li>
							<li><a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="695c71b2-1d46-4c46-8254-43c89acc6a97" href="/about/policies/web-privacy-notice" title="Web privacy notice ">Privacy policy</a></li>
							<li><a data-entity-substitution="canonical" data-entity-type="node" data-entity-uuid="d0413277-1a29-489f-85f6-fa469a4c511f" href="/about/policies/agency-rule-making" title="Agency rule making ">Agency rule making</a></li>
							<li><a href="https://wsdot.wa.gov/about/policies/travel-information-disclaimer" title="Travel Information Disclosure">Travel Information Disclosure</a></li>
						</ul>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent } from 'vue'
	import Breadcrumbs from '@/components/Breadcrumbs.vue'
	import AdSlot from '@/components/UI/AdSlot.vue'
	import useAdControlState from './store/adControlStatus'
	import userDrupalAlertBanner from './store/drupalAlertBannerStatus'

	export default defineComponent({
		name: 'App',
		components: {
			Breadcrumbs,
			AdSlot
		},
		setup() {
			const { adControlStatus } = useAdControlState()
			const { alertBanner } = userDrupalAlertBanner()
			const canShowAlertBanner = computed(() => {
				alertBanner.value.themeId = 2;
				return (new Date(alertBanner.value.endDate) > new Date());
			});
			return {
				adControlStatus,
				alertBanner,
				canShowAlertBanner
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
