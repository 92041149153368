import { reactive, toRefs, watch, Ref } from 'vue'
import { useRoute } from 'vue-router'
import { IBreadcrumb } from '@/interfaces/IBreadcrumb'

export interface INavigationBreadcrumb {
	title: string,
	routeName: string
}

export interface INavigation {
	documentTitle: string,
	pageTitle: string,
	breadcrumbs: INavigationBreadcrumb[],
	description: string,
	keywords: string
}

const state = reactive({
	documentTitle: '',
	pageTitle: '',
	breadcrumbs: [] as INavigationBreadcrumb[],
	description: '',
	keywords: ''
} as INavigation)

export function useNavigation(): {
	documentTitle: Ref<string>
	pageTitle: Ref<string>
	breadcrumbs: Ref<INavigationBreadcrumb[]>
	description: Ref<string>
	keywords: Ref<string>
} {
	const DEFAULT_TITLE = 'Real-time travel data'
	const route = useRoute()

	const setFromRoute = () => {
		state.pageTitle = route.meta.title as string
		state.documentTitle = route.meta.title as string
		state.description = route.meta.description as string
		state.keywords = route.meta.keywords as string
		if (route.meta.breadcrumb) {
			state.breadcrumbs = (route.meta.breadcrumb as IBreadcrumb[])
			.map((v) => {
				return {
					title: v.name,
					routeName: v.link
				}
			})
		}
	}

	watch(() => route.fullPath, (oldPath, newPath) => {
		if (oldPath !== newPath) {
			setFromRoute()
		}
	})

	watch(() => state.documentTitle, (newValue, oldValue) => {
		if (newValue !== oldValue) {
			if (newValue) {
				document.title = `${newValue} | WSDOT`
			} else {
				document.title = `${DEFAULT_TITLE} | WSDOT`
			}
		}
	})

	watch(() => state.description, (newValue, oldValue) => {
		if (newValue !== oldValue) {
			if (newValue) {
				$('meta[name="description').remove()
				$('title').after(`<meta name="description" content="${newValue}">`)
			} else {
				$('meta[name="description').remove()
			}
		}
	})

	watch(() => state.keywords, (newValue, oldValue) => {
		if (newValue !== oldValue) {
			if (newValue) {
				$('meta[name="keywords').remove()
				$('title').after(`<meta name="keywords" content="${newValue}">`)
			} else {
				$('meta[name="keywords').remove()
			}
		}
	})

	setFromRoute()

	return {
		...toRefs(state)
	}
}