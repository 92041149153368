<template>
	<generic-card v-if="!flat" :bannerText="cameraCount && cameraCount > 1 ? `Camera (${cameraCount})` : 'Camera'" iconName="camera" class="camera">
		<div class="card-info">
			<div role="heading" aria-level="4" class="card-title">{{ camera.cameraLabel }}</div>
			<div class="card-location" v-if="mapLinkText">
				<v-map-link :linkText="mapLinkText" :linkUrl="mapLinkUrl"></v-map-link>
			</div>
			<div class="card-details">
				<span v-if="camera.direction !== 'Both directions'"><strong>Camera Direction: </strong>{{ camera.direction }}<br /></span>
				<span><strong>Refresh rate: </strong> Every 2 minutes</span><br />
			</div>
			<div class="card-image">
				<img class="card-image-img" :src="`${camera.cameraUrl}?a=${dateNow}`" :alt="camera.cameraLabel" />
			</div>
		</div>
		<div class="card-image">
			<img class="card-image-img" :src="`${camera.cameraUrl}?a=${dateNow}`" :alt="camera.cameraLabel" />
		</div>
	</generic-card>
	<div v-if="flat" class="flat-camera">
		<div class="flat-camera-image">
			<img :src="`${camera.cameraUrl}?a=${dateNow}`" :alt="camera.cameraLabel" />
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, PropType, inject, computed } from 'vue'
	import { CameraListModel } from '@/api/travelServiceApi'
	import GenericCard from '@/components/GenericCard.vue'
	import VButton from '@/components/UI/VButton.vue'
	import VMapLink from '@/components/UI/VMapLink.vue'
	import { isNumber } from 'vue-composable'

	export default defineComponent({
		name: 'Cameras',
		components: {
			GenericCard,
			VButton,
			VMapLink
		},
		props: {
			camera: {
				type: Object as PropType<CameraListModel>,
				required: true
			},
			cameraCount: {
				type: Number,
				required: false
			},
			flat: {
				type: Boolean,
				required: false
			}
		},
		setup(props) {
			const MAP_URL = inject('$MAP_URL') as string

			const mapLinkText = computed(() => {
				const cam = props.camera
				if (isNumber(cam.stateRouteId)) {
					return `${cam.stateRoute} milepost ${cam.milePost}`
				}
				else {
					return cam.cameraLabel
				}
			})

			const dateNow = Date.now()

			const mapLinkUrl = computed(() => {
				return `${MAP_URL}?featuretype=camera&featureid=${(props.camera).cameraId}`
			})

			return {
				mapLinkText,
				mapLinkUrl,
				dateNow
			}
		}
	})
</script>

<style lang="scss">
	.btn-pad-left {
		left: 8px;
	}

	.flat-camera {
		.flat-card-location {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 8px;
		}

		.flat-camera-image {
			height: 408px;
			display: table-cell;
			vertical-align: bottom;

			@include sm {
				height: 100%;
			}

			img {
				width: 460px;

				@include sm {
					width: 100%;
				}
			}
		}

		.flat-camera-details {
			margin-left: 16px;
		}
	}

	.card.camera {
		.card-tags-header {
			.card-banner {
				background-color: rgba($PrimaryBrand, 0.2);
				border: 1px solid $PrimaryBrand;

				.card-banner-img {
					fill: $PrimaryBrand;
				}
			}
		}

		.card-content-wrapper {
			flex-flow: row nowrap;
			align-self: stretch;

			@include sm {
				flex-direction: column;
			}

			.card-info {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 0px;
				position: static;
				width: 259px;
				order: 0;
				margin: 8px 0;
				box-sizing: border-box;
				flex-grow: 0;
				padding-right: 8px;

				@include sm {
					width: 100%;
					margin: 0 0 10px 0;
				}

				.card-title {
					font-family: Lato;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 20px;
					color: $Gray100;
					position: static;
					order: 0;
					align-self: stretch;
					margin: 8px 0px;

					@include sm {
						width: 100%;
					}
				}

				.card-location {
					display: flex;
					flex-flow: wrap;
					flex-grow: 1;

					@include sm {
						width: 100%;
						order: 2;
					}
				}

				.card-details {
					position: static;
					left: 0;
					font-size: 14.4px;
					font-weight: normal;
					line-height: 18px;
					color: $Gray100;
					order: 2;
					margin: 8px 0px;
				}

				.card-tags {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					padding: 0px;
					position: static;
					height: 20px;
					left: 0px;
					flex: none;
					order: 3;
					flex-grow: 0;
					margin: 8px 0px;

					.card-tag {
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						position: static;
						height: 20px;
						left: 0px;
						top: 0px;
						background: $Warning;
						border-radius: 4px;
						flex: none;
						order: 0;
						flex-grow: 0;
						margin: 0px 16px 0px 0px;

						.card-tag-img {
							position: static;
							width: 16px;
							height: 16px;
							left: 2px;
							top: 2px;
							flex: none;
							order: 0;
							align-self: flex-start;
							flex-grow: 0;
							margin: 0px 4px 0px 0px;
						}

						.card-tag-text {
							position: static;
							left: 22px;
							height: 16px;
							top: 2px;
							font-size: 12.8px;
							line-height: 16px;
							color: $Gray100;
							flex: none;
							order: 1;
							align-self: center;
							flex-grow: 0;
							margin: 0px 4px;
						}
					}
				}

				.card-tag-buttons {
					display: flex;
					flex-direction: row;
					align-items: flex-end;
					padding: 0px;
					position: static;
					height: 40px;
					left: 0px;
					flex: none;
					order: 4;
					flex-grow: 0;
					margin: 4px 0px;
				}

				.card-image {
					display: none;

					@include sm {
						display: flex;
						order: 1;
					}
				}
			}
		}
	}

	.card-image {
		display: flex;
		position: static;
		flex-direction: column;
		width: 335px;
		right: 16px;
		top: 16px;
		flex: none;
		order: 3;
		flex-grow: 0;
		box-sizing: border-box;
		margin: 16px 0;

		@include sm {
			display: none;
		}

		.card-image-img {
			max-width: 100%;
			max-height: 100%;
		}
	}
</style>
