import 'core-js'
import 'regenerator-runtime/runtime'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { createApp } from 'vue'
import { router } from './router'
import { IConfig } from '@/interfaces/IConfig'
import App from '@/App.vue'

const getConfig = async () => {
	const configResult = await fetch(import.meta.env.BASE_URL + 'config.json')
	const { TRAFFIC_API, MAP_URL }: IConfig = await configResult.json()

	const appInsights = new ApplicationInsights({ config: {
		instrumentationKey: '3f639dd2-45dd-4de8-afc2-3e6bc599e381',
		enableAutoRouteTracking: true,
		enableUnhandledPromiseRejectionTracking: true,
		autoTrackPageVisitTime: true,
		excludeRequestFromAutoTrackingPatterns: ['googlesyndication','doubleclick','google-analytics']
	} })
	appInsights.loadAppInsights()
	appInsights.trackPageView()

	const app = createApp(App)
	app.provide('$TRAFFIC_API', TRAFFIC_API)
	app.provide('$MAP_URL', MAP_URL)
	app.provide('$appInsights', appInsights)
	app.use(router)
	app.mount('#index')
}

getConfig().catch()
