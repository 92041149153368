<template>
	<svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M9.41457 1.41391C8.63352 0.632863 7.36719 0.632862 6.58614 1.41391L1.41457 6.58549C1.3062 6.69385 1.21287 6.81156 1.13457 6.93602C2.42261 6.91332 3.15109 6.56536 3.54623 6.2673C3.66542 6.17739 3.75804 6.08923 3.82892 6.01093V5.33303C3.82892 4.90698 4.1743 4.5616 4.60035 4.5616C5.0264 4.5616 5.37178 4.90698 5.37178 5.33303V6.13842C5.57681 6.37126 5.83782 6.57902 6.14826 6.74718C6.65266 7.02039 7.25461 7.17053 7.87727 7.17053C8.49993 7.17053 9.10188 7.02039 9.60628 6.74718C9.94051 6.56613 10.2174 6.33918 10.4289 6.08444V5.33303C10.4289 4.90698 10.7743 4.5616 11.2003 4.5616C11.6264 4.5616 11.9718 4.90698 11.9718 5.33303V6.02487C12.0458 6.09305 12.1397 6.16824 12.2578 6.24534C12.6916 6.5285 13.4801 6.85529 14.8586 6.92408C14.7818 6.80403 14.691 6.69038 14.5861 6.58549L9.41457 1.41391ZM1.24843 9.22581C0.976403 8.8761 0.836627 8.45684 0.829102 8.03565C0.883099 8.05289 0.94064 8.0622 1.00035 8.0622C2.28627 8.0622 3.19543 7.78352 3.82892 7.42403V8.53303H2.20035C1.75525 8.53303 1.37807 8.82383 1.24843 9.22581ZM5.37178 8.53303V7.59565C5.45 7.64493 5.53027 7.69187 5.61244 7.73638C6.15282 8.02908 6.76261 8.21028 7.39213 8.27188L7.38938 8.53303H5.37178ZM8.51444 8.53303H10.4289V7.56615C10.3362 7.62627 10.2405 7.68307 10.1421 7.73638C9.64618 8.005 9.09181 8.17971 8.51738 8.25425L8.51444 8.53303ZM11.9718 8.53303V7.37975C12.6627 7.7415 13.6807 8.03295 15.171 8.06013C15.1625 8.34448 15.0936 8.62736 14.9645 8.88794C14.781 8.67087 14.5068 8.53303 14.2003 8.53303L11.9718 8.53303ZM13.467 10.533H11.9718V12.0283L13.467 10.533ZM10.4289 10.533H5.37178V13.3711L6.58614 14.5855C7.36719 15.3665 8.63352 15.3665 9.41457 14.5855L10.4289 13.5711V10.533ZM3.82892 10.533H2.53368L3.82892 11.8283V10.533Z" fill="#1D252D" />
		<rect x="8" y="0.707107" width="10.3137" height="10.3137" rx="1.5" transform="rotate(45 8 0.707107)" stroke="#1D252D" />
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'BridgeRestrictionIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
