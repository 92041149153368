<template>
	<generic-card bannerText="Truck restriction" iconName="truck" :bannerTag="restriction.ttype === 'B' ? 'Bridge' : 'Road'">
		<div role="heading" aria-level="4" class="card-title">{{ restriction.locationDescription }}</div>
		<div class="card-location" v-if="mapLinkText">
			<v-map-link :linkText="mapLinkText" :linkUrl="mapLink"></v-map-link>
		</div>
		<div class="card-body">
			<div v-if="restriction.ttype === 'B'">
				<div><strong>Bridge name: </strong>{{ restriction.bridgeName }}</div>
				<div><strong>Bridge number: </strong>{{ restriction.bridgeNr }}</div>
			</div>
			<div class="card-body-section">{{ restriction.restrictionComment }}</div>
			<div class="card-body-section">
				<div v-if="restriction.detourAvailableYn"><strong>Detour availability: </strong>{{ restriction.detourAvailableYn }}</div>
				<div v-if="restriction.permanentRestrictionYn"><strong>Permanent restriction: </strong>{{ restriction.permanentRestrictionYn }}</div>
			</div>
			<div class="card-body-section">
				<div v-if="restriction.restrictionHeight"><strong>Height: </strong>
					{{ Math.floor((restriction.restrictionHeight ?? 0) / 3048) }} ft
					{{ Math.round((((restriction.restrictionHeight ?? 0) / 3048) - Math.floor((restriction.restrictionHeight ?? 0) / 3048)) * 12) }} in
				</div>
				<div v-if="restriction.restrictionLength"><strong>Length: </strong>
					{{ Math.floor((restriction.restrictionLength ?? 0) / 3048) }} ft
					{{ Math.round((((restriction.restrictionLength ?? 0) / 3048) - Math.floor((restriction.restrictionLength ?? 0) / 3048)) * 12) }} in
				</div>
				<div v-if="restriction.restrictionWidth"><strong>Width: </strong>
					{{ Math.floor((restriction.restrictionWidth ?? 0) / 3048) }} ft
					{{ Math.round((((restriction.restrictionWidth ?? 0) / 3048) - Math.floor((restriction.restrictionWidth ?? 0) / 3048)) * 12) }} in
				</div>
			</div>
			<div class="card-body-section">
				<div v-if="restriction.maxGvw ?? 0 > 0"><strong>Max GVW: </strong>{{ restriction.maxGvw?.toLocaleString() }} lb</div>
				<div v-if="restriction.blMaxAxle ?? 0 > 0"><strong>BL: </strong>{{ restriction.blMaxAxle?.toLocaleString() }} lbs per axle</div>
				<div v-if="restriction.cl8MaxAxle ?? 0 > 0"><strong>CL-8: </strong>{{ restriction.cl8MaxAxle?.toLocaleString() }} lbs per axle</div>
				<div v-if="restriction.saMaxAxle ?? 0 > 0"><strong>SA: </strong>{{ restriction.saMaxAxle?.toLocaleString() }} lbs per axle</div>
			</div>
			<div class="card-body-section">
				<div>
					<strong>Date effective: </strong>{{ formatDate(restriction.dateEffective ?? '') }}
				</div>
				<div v-if="restriction.permanentRestrictionYn !== 'Y' && restriction.dateExpires && restriction.dateExpires != '2075-12-31T00:00:00'"><strong>Date expires: </strong>{{ formatDate(restriction.dateExpires) }}</div>
				<div><strong>Date posted: </strong>{{ formatDateTime(restriction.datePosted) }}</div>
				<div v-if="restriction.recordUpdateDate"><strong>Date updated: </strong>{{ formatDateTime(restriction.recordUpdateDate) }}</div>
			</div>
		</div>
	</generic-card>
</template>

<script lang="ts">
	import { defineComponent, PropType, inject, computed } from 'vue'
	import { ActiveVehicleRestrictionsMapService } from '@/api/travelServiceApi'
	import GenericCard from '@/components/GenericCard.vue'
	import VMapLink from '@/components/UI/VMapLink.vue'
	import { useHelpers } from '@/composables/helpers'

	export default defineComponent({
		name: 'Restriction',
		components: {
			GenericCard,
			VMapLink
		},
		props: {
			restriction: {
				type: Object as PropType<ActiveVehicleRestrictionsMapService>,
				required: true
			}
		},
		setup(props) {
			const { formatDate, formatDateTime } = useHelpers()
			const MAP_URL = inject('$MAP_URL') as string

			const mapLinkText = computed(() => {
				return props.restriction.locationName ?? ''
			})

			const mapLink = computed(() => {
				return `${MAP_URL}?featuretype=restriction&featureid=${(props.restriction.uniqueId ?? '')}`
			})

			return {
				mapLinkText,
				mapLink,
				formatDate,
				formatDateTime
			}
		}
	})
</script>

<style lang="scss">
	.card {
		.card-tags-header {
			.card-banner {
				background-color: rgba($TruckPink, 0.3);
				border: 1px solid $TruckPink;

				.card-banner-img {
					fill: $TruckPink;
				}
			}

			.card-banner-priority {
				border: 1px solid $TruckPink;
				background-color: rgba($TruckPink, 0.3);
			}
		}

		.card-content-wrapper {
			.card-title {
				font-family: Lato;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				color: $Gray100;
				position: static;
				left: 16px;
				top: 16px;
				flex: none;
				order: 1;
				align-self: stretch;
				flex-grow: 0;
				margin: 4px 0px;
			}

			.card-location {
				flex-direction: row;
				align-items: flex-start;
				padding: 0px;
				position: static;
				left: 16px;
				top: 44px;
				order: 2;
				flex: none;
				flex-grow: 0;
				margin: 4px 0px;
			}

			.card-body {
				position: static;
				left: 16px;
				font-size: 14.4px;
				line-height: 18px;
				color: $Gray100;
				flex: none;
				order: 3;
				align-self: stretch;
				flex-grow: 0;
				margin: 4px 0px;

				.card-body-section {
					margin: 4px 0;
				}

				span {
					p {
						font-size: 14.4px;
						line-height: 18px;
					}
				}
			}
		}
	}
</style>
