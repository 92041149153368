<template>
	<svg :width="width" :height="height" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
		<path d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"/>
		<path d="M16.7505 8.86734C16.7505 13.079 13.2842 16.4996 9.00049 16.4996C4.71677 16.4996 1.25049 13.079 1.25049 8.86734C1.25049 4.65566 4.71677 1.23511 9.00049 1.23511C13.2842 1.23511 16.7505 4.65566 16.7505 8.86734Z" stroke="white" stroke-width="0.5"/>
		<line x1="6.5" y1="3.00024" x2="6.5" y2="14.2502" stroke="white"/>
		<line x1="8.175" y1="3.3" x2="8.175" y2="13.95" stroke="white" stroke-width="0.6" stroke-linecap="round" stroke-dasharray="3 3"/>
		<line x1="10.25" y1="3" x2="10.25" y2="14.25" stroke="white"/>
		<circle cx="11.626" cy="9.25" r="2.1" stroke="white" stroke-width="0.8"/>
		<path d="M11.626 8V9.28289L12.501 10" stroke="white" stroke-width="0.8" stroke-linecap="round"/>
	</svg>
</template>

<script lang="ts">
	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'TravelTimeIcon',
		props: {
			width: {
				type: Number,
				required: false
			},
			height: {
				type: Number,
				required: false
			}
		}
	})
</script>

<style scoped lang="scss">
</style>
